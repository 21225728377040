import { Coords } from "../maps/coords";

export class CommandRecruitment {
    id: string;
    commandId: string;
    owner: string;
    coords: Coords;
    recruitmentType: string;
    recruitmentTypeName: string;
    recruitmentQuantity: number;    
    recruitmentImageUrl: string;
    recruitmentTime: number;
    recruitmentStart: Date;
    recruitmentTimeLeft: number;
    isActive: boolean;
}