import { TroopSlot } from "./troopSlot";

export class TroopSlots {
    slot1: TroopSlot;
    slot2: TroopSlot;
    slot3: TroopSlot;
    slot4: TroopSlot;
    slot5: TroopSlot;
    slot6: TroopSlot;
    slot7: TroopSlot;
}