import { TroopDetail } from "./troopDetail";

export class Troops {
    explorer: TroopDetail;
    slingers: TroopDetail;
    axemen: TroopDetail;
    bludgeonWarrior: TroopDetail;
    cataphract: TroopDetail;
    lancer: TroopDetail;
    scout: TroopDetail;
    catapult: TroopDetail;
    archer: TroopDetail;
    crossbowman: TroopDetail;
    pikemen: TroopDetail;
    swordsman: TroopDetail;
    knight: TroopDetail;
    hussars: TroopDetail;
    ballista: TroopDetail;
    cannon: TroopDetail;
    scythe: TroopDetail;
    grenadier: TroopDetail;
    dragons: TroopDetail;
    wingedHussars: TroopDetail;
    trebuchet: TroopDetail;
    gun: TroopDetail;
    marksman: TroopDetail;
    musketeer: TroopDetail;
    rajtar: TroopDetail;
    balloons: TroopDetail;
    mitrailleuse: TroopDetail;
    baron: TroopDetail;
    settler: TroopDetail;
}