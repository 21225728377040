import { Coords } from "../maps/coords";
import { DetailCoords } from "../maps/detailCoords";
import { TroopSlot } from "../village/troopSlot";

export class SendTroops {
    id: string;
    owner: string;
    coordsTo: DetailCoords;
    coordsFrom: Coords;
    sendTroopsType: string;
    troopSlots: TroopSlot[];
    isCommingBack: boolean;
}