import { SourcesCanIncrease } from "../townHall/sourcesCanIncrease";

export class TechnologyCanIncreaseLevel1 {
    slingers: SourcesCanIncrease;
    explorer: SourcesCanIncrease;
    axemen: SourcesCanIncrease;
    bludgeonWarrior: SourcesCanIncrease;
    cataphract: SourcesCanIncrease;
    lancer: SourcesCanIncrease;
    scout: SourcesCanIncrease;
    catapult: SourcesCanIncrease;
    path: SourcesCanIncrease;
    wheatFarm: SourcesCanIncrease;
    clayMine: SourcesCanIncrease;
    sawmill: SourcesCanIncrease;
    quarry: SourcesCanIncrease;
    findingFarmsLevel1: SourcesCanIncrease;
    findingMinesLevel1: SourcesCanIncrease;
    finding2Fields: SourcesCanIncrease;
    fortress: SourcesCanIncrease;
    atakOnWorkers: SourcesCanIncrease;
    chaseAwayWorkers: SourcesCanIncrease;
    irrigation: SourcesCanIncrease;
    trolleys: SourcesCanIncrease;
    unlockLevel: boolean;
}