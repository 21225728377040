import { SourcesCanIncrease } from "../townHall/sourcesCanIncrease";

export class TechnologyCanIncreaseLevel3 {
    cannon: SourcesCanIncrease;
    scythe: SourcesCanIncrease;
    grenadier: SourcesCanIncrease;
    dragons: SourcesCanIncrease;
    wingedHussars: SourcesCanIncrease;
    trebuchet: SourcesCanIncrease;
    riceFarm: SourcesCanIncrease;
    ironMine: SourcesCanIncrease;
    silverMine: SourcesCanIncrease;
    findingFarmsLevel3: SourcesCanIncrease;
    findingMinesLevel3: SourcesCanIncrease;
    finding9Fieilds: SourcesCanIncrease;
    railway: SourcesCanIncrease;
    tunel: SourcesCanIncrease;
    miningShaft: SourcesCanIncrease;
    horses: SourcesCanIncrease;
}