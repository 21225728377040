import { Component , Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({ templateUrl: 'statute.component.html', encapsulation: ViewEncapsulation.None })
export class StatuteComponent implements OnInit {

    constructor() {

    }

    ngOnInit() {

    }
}