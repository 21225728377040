import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '@environments/environment';
import { AddRecruitment, AddRecruitmentResponse, RecruitmentDetail, RecruitmentSchema, CancelRecruitment } from '@app/_models';
import { Router } from '@angular/router';
import { SourcesHelper } from '@app/_helpers/sources.helper';


@Injectable({ providedIn: 'root' })
export class RecruitmentService {
    constructor(
        private router: Router,
        private http: HttpClient,
        private sourcesHelper: SourcesHelper
    ) {

    }

    addRecruitment(addRecruitment: AddRecruitment) : Promise<AddRecruitmentResponse> {
      return new Promise(resolve=>{
        this.http.post<AddRecruitmentResponse>(`${environment.dataStorageUrl}/recruitment/addRecruitment`, addRecruitment)
         .subscribe(
            (data:any) => {
                this.sourcesHelper.updateMainbarDetailSources(data.mainbarDetail);
                resolve(data);
         })
      })
    }

    addManyRecruitments(addRecruitmentList: AddRecruitment[]) : Promise<AddRecruitmentResponse>  {
      return new Promise(resolve=>{
        this.http.post<AddRecruitmentResponse>(`${environment.dataStorageUrl}/recruitment/addManyRecruitments`, addRecruitmentList)
         .subscribe(
            (data:any) => {
                this.sourcesHelper.updateMainbarDetailSources(data.mainbarDetail);
                resolve(data);
         })
      })
    }

    getRecruitmentDetail() {     
      return this.http.get<RecruitmentDetail>(`${environment.dataStorageUrl}/recruitment/getRecruitmentDetail`);
    }

    getRecruitmentSchema() {     
      return this.http.get<RecruitmentSchema>(`${environment.dataStorageUrl}/recruitment/getRecruitmentSchema`);
    }

    cancelAddRecruitment(cancelRecruitment: CancelRecruitment) : Promise<AddRecruitmentResponse> 
    {
      return new Promise(resolve=>{
        this.http.post<AddRecruitmentResponse>(`${environment.dataStorageUrl}/recruitment/cancelRecruitment`, cancelRecruitment )
         .subscribe(
            (data:any) => {
                this.sourcesHelper.updateMainbarDetailSources(data.mainbarDetail);
                resolve(data);
         })
      })
    }
}