import { Component, OnInit, Input, ViewChild, AfterViewInit, ElementRef } from '@angular/core';

import { ModalDismissReasons, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Subscription, timer } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';
import { VillageDetail } from '@app/_models/village/villageDetail';
import { MapService, ProfileService } from '@app/_services';
import { VillageService } from '@app/_services/village.service';

import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import { RecruitmentImages } from '@app/_models/images/recruitmentImages';
import { MapSchema } from '@app/_models/maps/mapSchema';
import { CollectSources } from '@app/_models/maps/collectSources';
import { FieldDetail } from '@app/_models/maps/fieldDetail';
import { ImprovementLevel } from '@app/_models/maps/improvementLevel';

@Component({
  selector: 'modal-field-details',
  templateUrl: './modal-field-details.component.html',
  styleUrls: ['./modal-field-details.component.css']
})
export class ModalFieldDetailsComponent implements OnInit  {
  @ViewChild('inputNameRef') inputNameRef: ElementRef;
  @Input() target_coordx;
  @Input() target_coordy;
  closeResult = '';
  villageDetail: VillageDetail;
  images: RecruitmentImages;
  subscription: Subscription;
  collectSources: CollectSources;
  mapSchema: MapSchema;
  fieldDetail: FieldDetail;
  collectTime: string;
  highlightSendWorkers: string = '';
  defaultWorkers: number;
  activeModal: NgbModalRef;

  constructor(public modal: NgbModal,
    private mapService: MapService,
    private villageService: VillageService,
    private profileService: ProfileService,
    private elementRef: ElementRef,
    private router: Router) {

    }

  ngOnInit() {
    this.villageService.getVillageDetails().subscribe(result => this.villageDetail = result);
    this.mapService.getMapSchema().subscribe(result => this.mapSchema = result);
    this.mapService.getFieldDetail(Math.floor(this.target_coordx*10)/10, Math.floor(this.target_coordy*10)/10).subscribe(result => this.fieldDetail = result);

    this.images = new RecruitmentImages();


    this.profileService.getProfileDetails().subscribe(result => {
      if (result.tutorialDetail.inProgress == false && result.tutorialDetail.type == "MapZoom" && result.tutorialDetail.name == "SendWorkers")
      {
          this.highlightSendWorkers = 'highlight';
      } else
      {
          this.highlightSendWorkers = '';
      }
    });

    // this.subscription = timer(500, 1000).pipe(
    //   switchMap(() => this.mapService.checkCollectSourceTime(this.villageDetail, this.mapSchema, Math.floor(this.target_coordx*10)/10, Math.floor(this.target_coordy*10)/10))
    // ).subscribe(result => this.collectSources = result);
  }

  getCompletedImprovements() {
    if (!this.fieldDetail || !this.fieldDetail.fieldImprovements) {
      return [];
    }

    return Object.entries(this.fieldDetail?.fieldImprovements)
      .filter(([key, value]) => value.isCompleted)
      .map(([key, value]) => ({ name: key, ...value }));
  }

  getResourcesFromExplorerReport(): { type: string, amount: number }[] {
    const resources = this.fieldDetail.explorerReport?.explorerReport?.fieldSources;
    if (!resources) {
      return [];
    }
    return Object.entries(resources)
      .filter(([key, value]) => value > 0)  // Only include resources with amount > 0
      .map(([key, value]) => ({ type: key, amount: value })); // Convert to an array of objects with type and amount
  }

  public submit() {
    //this.modal.close(/* your result */);
  }

  open(content) {
		this.modal.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then(
			(result) => {
				this.closeResult = `Closed with: ${result}`;
			},
			(reason) => {
				this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			},
		);
	}

  dismiss()
  {
    this.modal.dismissAll();
  }

  canBuildImprovements()
  {

  }

  buildImprovement()
  {

  }

  removeImprovement(improvement: any)
  {

  }

  
  canRemoveImprovement(improvement: any)
  {
    
  }

  // sendWorkers(sourceType: string, workers: number)
  // {      
  //      var coords = new Coords();
  //      coords.x = this.villageDetail?.coords.x;
  //      coords.y = this.villageDetail?.coords.y;
  //      var detailCoords = new Coords();
  //      detailCoords.x = Math.floor(this.target_coordx*10)/10;
  //      detailCoords.y = Math.floor(this.target_coordy*10)/10;
  //      var sendWorkersRequest = new SendWorkers();
  //      sendWorkersRequest.id = '0';
  //      sendWorkersRequest.detailCoords = detailCoords;
  //      sendWorkersRequest.villageCoords = coords;
  //      sendWorkersRequest.sourceType = sourceType;
  //      sendWorkersRequest.workers = +workers;
  //      this.mapService.sendWorkers(sendWorkersRequest)
  //          .pipe(first())
  //          .subscribe({
  //              next: (result) => {
  //                  this.villageDetail = result.villageDetail;
  //                  this.modal.dismissAll();
  //                  this.alertService.success('Dodano robotników do zbierania surowców');
  //                  this.router.navigate(["/village/details", this.villageDetail?.coords.x, this.villageDetail?.coords.y]);
  //                  //this.loading = false;
  //              },
  //              error: error => {
  //                 this.alertService.error(error);
  //                 //  this.loading = false;
  //              }
  //          });
  // }

  // onValueChange(e, sourceType: string)
  // {       
  //     if (sourceType == "wood")
  //     {
  //         this.model.wood.workers = <number>e.target.value;
  //     }
  //     if (sourceType == "bricks")
  //     {
  //         this.model.bricks.workers = <number>e.target.value;
  //     }
  //     if (sourceType == "stone")
  //     {
  //         this.model.stone.workers = <number>e.target.value;
  //     }
  //     if (sourceType == "grain")
  //     {
  //         this.model.grain.workers = <number>e.target.value;
  //     }
  //     this.collectTime = this.mapService.getCollectTime(<number>e.target.value);
  // }

	private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return 'by pressing ESC';
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return 'by clicking on a backdrop';
		} else {
			return `with: ${reason}`;
		}
	}

  ngOnDestroy() {
    // this.subscription.unsubscribe();
  }
}