import { Component, OnInit, Input } from '@angular/core';
import { Coords, DetailCoords, SendTroops, TroopSlots } from '@app/_models';
import { RecruitmentImages } from '@app/_models/images/recruitmentImages';
import { VillageTroopDetail } from '@app/_models/village/villageTroopDetail';
import { AlertService } from '@app/_services';
import { CommandService } from '@app/_services/command.service';
import { VillageService } from '@app/_services/village.service';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription, timer } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';
@Component({
  selector: 'modal-send-troops',
  templateUrl: './modal-send-troops.component.html',
  styleUrls: ['./modal-send-troops.component.css']
})
export class ModalSendTroopsComponent implements OnInit {

  images: RecruitmentImages;

  phaserGame: Phaser.Game;
  placeTroopsConfig: Phaser.Types.Core.GameConfig;
  villageTroopDetail: VillageTroopDetail;
  loading = false;
  submitted = false;
  subscriptionCommands: Subscription;
  placeTroopSlots: TroopSlots;
  coordx: string = "0";
  coordy: string = "0";
  
  @Input() target_coordx;
  @Input() target_coordy;
  closeResult = '';

  constructor(public modal: NgbModal,
    private villageService: VillageService,
        private alertService: AlertService,
        private commandService: CommandService) {
    this.placeTroopsConfig = {
      type: Phaser.CANVAS,
      height: 300,
      width: 760,
    //   backgroundColor: 'A3A69B',
      transparent: true, 
      scene: [ SendTroopsScene ],
      parent: 'sendTroopsContainer',
      dom: {
          createContainer: true
      },
      physics: {
        default: 'arcade',
        arcade: {
          gravity: { y: 100 }
        }
      }
    };
  }

  ngOnInit() {
    this.images = new RecruitmentImages();

    this.villageService.getVillageTroopDetails(this.target_coordx, this.target_coordy).subscribe(result => {
        this.villageTroopDetail = result;
        villageTroopDetailShared = result;
        this.createPhaserGame();
    });


    eventsCenterModal.on('update-place-troops-list', this.updatePlaceTroopsList, this);
    //eventsCenterModal.on('update-troop-list-modal', this.updateTroopList, this);

      this.subscriptionCommands = timer(100, 500).pipe(
        switchMap(() => this.commandService.countTroopsCommandTime(this.villageTroopDetail))
      ).subscribe(result => this.villageTroopDetail = result);


  }

  public submit() {
    //this.activeModal.close(/* your result */);
  }

  open(content) {
		this.modal.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then(
			(result) => {
				this.closeResult = `Closed with: ${result}`;
			},
			(reason) => {
				this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			},
		);
	}

  dismiss()
  {
    this.modal.dismissAll();
  }

	private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return 'by pressing ESC';
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return 'by clicking on a backdrop';
		} else {
			return `with: ${reason}`;
		}
	}

    // updateTroopList()
    // {
    //     setTimeout(() => {this.setTroopList();},100);
    // }

    createPhaserGame()
    {
        this.phaserGame = new Phaser.Game(this.placeTroopsConfig);
    }

//   setTroopList()
//   {
//       console.log('setTroopList method');
//       eventsCenterModal.emit('set-troop-list-modal', this.villageTroopDetail);
//   }

  updatePlaceTroopsList(troopList: any)
  {
      this.placeTroopSlots = troopList;
  }

  sendTroops(sendTroopsType: string) {
    this.submitted = true;

    // reset alerts on submit
    this.alertService.clear();

    this.loading = true;
    var coordsTo = new DetailCoords();
    coordsTo.x = Number(this.target_coordx);
    coordsTo.y = Number(this.target_coordy);
    var coordsFrom = new Coords();
    coordsFrom.x = this.villageTroopDetail?.coords.x;
    coordsFrom.y = this.villageTroopDetail?.coords.y;

    var request = this.setSendTroopsRequest(coordsTo, coordsFrom, sendTroopsType, this.placeTroopSlots);
    this.villageService.sendTroops(request)
        .pipe(first())
        .subscribe({
            next: (result) => {
                this.villageTroopDetail = result.villageTroopDetail
                this.alertService.success('Wysłano wojska');
                this.loading = false;
            },
            error: error => {
                this.alertService.error(error);
                this.loading = false;
            }
        });
}


setSendTroopsRequest(coordsTo: DetailCoords, coordsFrom: Coords, sendTroopsType: string, troopSlots: any)
{
    var sendTroops = new SendTroops();
    sendTroops.id = '0';
    sendTroops.coordsTo = coordsTo;
    sendTroops.coordsFrom = coordsFrom;
    sendTroops.sendTroopsType = sendTroopsType;
    sendTroops.troopSlots = troopSlots;
    return sendTroops;
}

  ngOnDestroy() {
      this.subscriptionCommands.unsubscribe();
      this.phaserGame.destroy(true);
  }
}

var eventsCenterModal = new Phaser.Events.EventEmitter();
var villageTroopDetailShared: VillageTroopDetail;

class SendTroopsScene extends Phaser.Scene {
    constructor() {
      super({ key: 'placeTroops' });
    }

    castle: any; castleImg: any; textCastle: any;
    knight1: any; knight1Img: any; textKnight1: any;
    style: any; troopsNumberStyle: any;
    troopsList: any;
    troopsImgList: any;
    troopsNameList: any;
    troopsQuantityList: any;
    troopsNumberList: any;
    knight1TroopsList: any;
    troopsKnightImgList: any;
    troopsKnightNameList: any;
    troopsKnightQuantityList: any;
    troopsKnightNumberList: any;
    isSceneLoaded: any;

    preload() {
      this.load.image('Castle', 'assets/troops/Castle.png');
      this.load.image('Slingers', 'assets/troops/Slingers.png');
      this.load.image('Axemen', 'assets/troops/Axemen.png');
      this.load.image('BludgeonWarrior', 'assets/troops/BludgeonWarrior.png');
      this.load.image('Cataphract', 'assets/troops/Cataphract.png');
      this.load.image('Lancer', 'assets/troops/Lancer.png');
      this.load.image('Catapult', 'assets/troops/Catapult.png');
      this.load.image('Archer', 'assets/troops/Archer.png');
      this.load.image('Crossbowman', 'assets/troops/Crossbowman.png');
      this.load.image('Pikemen', 'assets/troops/Pikemen.png');
      this.load.image('Swordsman', 'assets/troops/Swordsman.png');
      this.load.image('Knight', 'assets/troops/Knight.png');
      //this.load.image('Hussars', 'assets/troops/Hussars.png');
      this.load.image('Ballista', 'assets/troops/Ballista.png');
      this.load.image('Cannon', 'assets/troops/Cannon.png');
      this.load.image('Scythe', 'assets/troops/Scythe.png');
      this.load.image('Grenadier', 'assets/troops/Grenadier.png');
      this.load.image('Dragons', 'assets/troops/Dragons.png');
      this.load.image('WingedHussars', 'assets/troops/WingedHussars.png');
      this.load.image('Trebuchet', 'assets/troops/Trebuchet.png');
      this.load.image('Gun', 'assets/troops/Gun.png');
      this.load.image('Marksman', 'assets/troops/Marksman.png');
      this.load.image('Musketeer', 'assets/troops/Musketeer.png');
      this.load.image('Rajtar', 'assets/troops/Rajtar.png');
      this.load.image('Balloons', 'assets/troops/Balloons.png');
      this.load.image('Mitrailleuse', 'assets/troops/Mitrailleuse.png');
      //this.load.image('Baron', 'assets/troops/Baron.png');
      //this.load.image('Settler', 'assets/troops/Settler.png');
      this.style = { font: "13px Arial", fill: "#212529", align: "top", backgroundColor: "#ffffff" };
      this.troopsNumberStyle = { font: "11px Arial", fill: "#212529", align: "top", backgroundColor: "#ffffff" };
      this.troopsList = [];
      this.troopsImgList = [];
      this.troopsNameList = [];
      this.troopsQuantityList = [];
      this.troopsNumberList = [];
      this.knight1TroopsList = [];
      this.troopsKnightImgList = [];
      this.troopsKnightNameList = [];
      this.troopsKnightQuantityList = [];
      this.troopsKnightNumberList = [];
      this.isSceneLoaded = false;
    }

    create() {
        // if (this.isSceneLoaded == false)
        // {
        //     eventsCenterModal.emit('update-troop-list-modal', null);
        // }
        // eventsCenterModal.on('set-troop-list-modal', this.setTroopList, this);

        //this.setTroopView(this.troopList);
        // this.setLevel1Rectangles();
        // var troopCount = 0;
        // var troop = {
        // troopsInUse:
        // 0,
        // troopsQuantity:
        // 1,
        // troopsSpace:
        // 1,
        // troopsType:
        // 'Slingers'
        // }
        //       this.SetTroopImage(troop, troopCount);
        //       troopCount++;
        // this.setKnightRectangle();

        this.setTroopList(villageTroopDetailShared);


      var cam = this.cameras.main;
      cam.zoom = 1;

    this.input.on('drag', (pointer, gameObject, dragX, dragY) => {
        gameObject.x = dragX;
        gameObject.y = dragY;
    })

    this.input.on('dragstart', (pointer, gameObject) => {
        gameObject.setTint(0xff69b4);
        gameObject.depth = 100;
    })

    this.input.on('dragend', (pointer, gameObject, dropped) => {
        gameObject.setTint();
        gameObject.depth = 0;
        if (!dropped) {
            gameObject.x = gameObject.input.dragStartX;
            gameObject.y = gameObject.input.dragStartY;
        }
    })

    this.input.on('drop', (pointer, gameObject, dropZone) => {
        if (dropZone.x == gameObject.input.dragStartX && dropZone.y == gameObject.input.dragStartY)
        {
            gameObject.x = gameObject.input.dragStartX;
            gameObject.y = gameObject.input.dragStartY;
        }
        else
        {
            var dropZoneType = dropZone.y==53?'castle':'knight';
            var dropZoneStart = gameObject.input.dragStartY==53?'castle':'knight';
            var dropZoneSlot = (dropZone.x - 48)/90;
            var dropZoneStartSlot = (gameObject.input.dragStartX - 48)/90;
            if (dropZoneType === 'knight')
            {          
                if (dropZoneStart === 'knight')
                {
                    var troopsNumber = this.troopsKnightNumberList.find(x => x.name == gameObject.name).value;
                    var troopsQuantityId = "KnightSlot" + dropZoneStartSlot + "Quantity";
                    var troopsQuantity = Number((document.getElementById(troopsQuantityId) as HTMLInputElement).value);
                    if (troopsQuantity > troopsNumber || troopsQuantity < 0 || isNaN(+troopsQuantity))
                    {
                        gameObject.x = gameObject.input.dragStartX;
                        gameObject.y = gameObject.input.dragStartY;
                    }
                    else
                    {
                        let troop = {
                            troopsType: gameObject.troopsType,
                            troopsQuantity: troopsQuantity,
                        };
                        gameObject.x = dropZone.x;
                        gameObject.y = dropZone.y;
                        this.updateKnightSlot(dropZoneSlot, dropZoneStartSlot, gameObject, troop, troopsNumber, troopsQuantityId)
                    }
                }    
                else
                {  
                    var troopsNumber = this.troopsNumberList.find(x => x.name == gameObject.name).value;
                    var troopsQuantityId = "CastleSlot" + dropZoneStartSlot + "Quantity";
                    var troopsQuantity = Number((document.getElementById(troopsQuantityId) as HTMLInputElement).value);
                    if (troopsQuantity > troopsNumber || troopsQuantity < 0 || isNaN(+troopsQuantity))
                    {
                        gameObject.x = gameObject.input.dragStartX;
                        gameObject.y = gameObject.input.dragStartY;
                    }
                    else
                    {
                        let troop = {
                            troopsType: gameObject.troopsType,
                            troopsQuantity: troopsQuantity,
                        };

                        var existingTroopsQuantityId = "KnightSlot" + dropZoneSlot + "Quantity";
                        var existingGameObjectName = "KnightSlot" + dropZoneSlot;
                        var existingSlot = this.troopsKnightImgList.find(x => x.name == existingGameObjectName);
                        var newGameObjectName = "CastleSlot" + dropZoneStartSlot;
                        var newSlot = this.troopsImgList.find(x => x.name == newGameObjectName);
                        
                        if (troopsQuantity === troopsNumber)
                        { 
                            if (existingSlot != undefined)
                            {
                                if (existingSlot.troopsType === newSlot.troopsType)
                                {
                                    this.increaseKnightExistingTroop(existingGameObjectName, existingTroopsQuantityId, troop.troopsQuantity, existingSlot);
                                    this.removeTroopImage(gameObject);
                                }
                                else
                                {
                                    var castleTroopsType = newSlot.troopsType;
                                    var knightTroopsType = existingSlot.troopsType;
                                    var castleTroopsNumber = this.troopsNumberList.find(x => x.name == newSlot.name).value;
                                    var knightTroopsNumber = this.troopsKnightNumberList.find(x => x.name == existingSlot.name).value;
                                    this.updateTroopImg(dropZoneStartSlot, knightTroopsNumber, knightTroopsType);
                                    this.updateTroopKnightImg(dropZoneSlot, castleTroopsNumber, castleTroopsType);
                                    gameObject.x = gameObject.input.dragStartX;
                                    gameObject.y = gameObject.input.dragStartY; 
                                }
                            }
                            else
                            {
                                this.SetTroopKnightImage(troop, dropZoneSlot - 1);
                                this.removeTroopImage(gameObject);
                            }    
                        }
                        else
                        {
                            if (existingSlot != undefined)
                            {
                                this.increaseKnightExistingTroop(existingGameObjectName, existingTroopsQuantityId, troop.troopsQuantity, existingSlot);
                            }
                            else
                            {
                                this.SetTroopKnightImage(troop, dropZoneSlot - 1);
                            }

                            (document.getElementById(troopsQuantityId) as HTMLInputElement).value = (troopsNumber - troopsQuantity).toString();
                            this.troopsNumberList.find(x => x.name == gameObject.name).value = (troopsNumber - troopsQuantity);
                            this.troopsNumberList.find(x => x.name == gameObject.name).setText("/" + (troopsNumber - troopsQuantity));
                            gameObject.x = gameObject.input.dragStartX;
                            gameObject.y = gameObject.input.dragStartY;
                        }
                    }
                }
            }
            if (dropZoneType === 'castle')
            {   
                if (dropZoneStart === 'knight')
                {
                    var troopsNumber = this.troopsKnightNumberList.find(x => x.name == gameObject.name).value;
                    var troopsQuantityId = "KnightSlot" + dropZoneStartSlot + "Quantity";
                    var troopsQuantity = Number((document.getElementById(troopsQuantityId) as HTMLInputElement).value);
                    if (troopsQuantity > troopsNumber || troopsQuantity < 0 || isNaN(+troopsQuantity))
                    {
                        gameObject.x = gameObject.input.dragStartX;
                        gameObject.y = gameObject.input.dragStartY;
                    }
                    else
                    {
                        let troop = {
                            troopsType: gameObject.troopsType,
                            troopsQuantity: troopsQuantity,
                        };
                        
                        var existingTroopsQuantityId = "CastleSlot" + dropZoneSlot + "Quantity";
                        var existingGameObjectName = "CastleSlot" + dropZoneSlot;
                        var existingSlot = this.troopsImgList.find(x => x.name == existingGameObjectName);
                        var newGameObjectName = "KnightSlot" + dropZoneStartSlot;
                        var newSlot = this.troopsKnightImgList.find(x => x.name == newGameObjectName);
                        if (troopsQuantity === troopsNumber)
                        { 
                            if (existingSlot != undefined)
                            {
                                if (existingSlot.troopsType === newSlot.troopsType)
                                {
                                    this.increaseExistingTroop(existingGameObjectName, existingTroopsQuantityId, troop.troopsQuantity, existingSlot);
                                    this.removeTroopKnightImage(gameObject);
                                }
                                else
                                {
                                    var castleTroopsType = existingSlot.troopsType;
                                    var knightTroopsType = newSlot.troopsType;
                                    var castleTroopsNumber = this.troopsNumberList.find(x => x.name == existingSlot.name).value;
                                    var knightTroopsNumber = this.troopsKnightNumberList.find(x => x.name == newSlot.name).value;
                                    this.updateTroopKnightImg(dropZoneStartSlot, castleTroopsNumber, castleTroopsType);
                                    this.updateTroopImg(dropZoneSlot, knightTroopsNumber, knightTroopsType);
                                    gameObject.x = gameObject.input.dragStartX;
                                    gameObject.y = gameObject.input.dragStartY; 
                                }
                            }
                            else
                            {
                                this.SetTroopImage(troop, dropZoneSlot - 1);
                                this.removeTroopKnightImage(gameObject);
                            }                           
                        }
                        else
                        {
                            if (existingSlot != undefined)
                            {
                                this.increaseExistingTroop(existingGameObjectName, existingTroopsQuantityId, troop.troopsQuantity, existingSlot);
                            }
                            else
                            {
                                this.SetTroopImage(troop, dropZoneSlot - 1);
                            }

                            (document.getElementById(troopsQuantityId) as HTMLInputElement).value = (troopsNumber - troopsQuantity).toString();
                            this.troopsKnightNumberList.find(x => x.name == gameObject.name).value = (troopsNumber - troopsQuantity);
                            this.troopsKnightNumberList.find(x => x.name == gameObject.name).setText("/" + (troopsNumber - troopsQuantity));
                            gameObject.x = gameObject.input.dragStartX;
                            gameObject.y = gameObject.input.dragStartY;
                        }

                    }
                }
                else
                {
                    gameObject.x = gameObject.input.dragStartX;
                    gameObject.y = gameObject.input.dragStartY;
                }
            }
            this.updatePlaceTroopsList();
        }
    })


      this.input.on('gameobjectover', function (pointer, gameObject) {

      });


      this.input.on('pointerout', function () {

      });

      
    }

    updatePlaceTroopsList()
    {
        var placeTroopsList : { troopType: string; troopQuantity: number }[] = [];
        for (let i = 1; i < 8; i++) {
            var gameObjectName = "KnightSlot" + i;
            if (this.troopsKnightImgList.find(x => x.name == gameObjectName) != undefined)
            {
                placeTroopsList.push({troopType : this.troopsKnightImgList.find(x => x.name == gameObjectName).troopsType, troopQuantity : this.troopsKnightNumberList.find(x => x.name == gameObjectName).value})
            }
            else
            {
                placeTroopsList.push( {troopType : '', troopQuantity : 0})
            }
            //placeTroopsList.push({troopType : this.troopsKnightImgList[i].troopsType, troopQuantity : this.troopsKnightNumberList[i].value})
        }
        eventsCenterModal.emit('update-place-troops-list', placeTroopsList);
    }

    setTroopList(troopList)
    {
      this.isSceneLoaded = true;
      this.setLevel1Rectangles();
      var troopCount = 0;
      if (troopList != undefined && troopList.availableTroops != undefined)
      {
        var availableTroops = troopList.availableTroops;
        availableTroops.forEach((troop) => {
            if (troop.troopsQuantity > 0) 
            {
                this.SetTroopImage(troop, troopCount);
                troopCount++;
            }
        }); 
        }
      this.setKnightRectangle();
    }

    SetTroopImage(troop: any, troopCount: number)
    {
        var listCount = this.troopsImgList.length;
        this.troopsImgList[listCount] = this.add.sprite(48 + ((troopCount+1)*90), 53, troop.troopsType).setInteractive();
        this.troopsImgList[listCount].name = "CastleSlot" + (troopCount+1);
        this.troopsImgList[listCount].troopsType = troop.troopsType;
        var troopTypeName = this.getTroopTypeName(troop.troopsType);
        this.troopsNameList[listCount] = this.add.text(50, 50, troopTypeName, this.style);
        this.troopsNameList[listCount].name = "CastleSlot" + (troopCount+1);
        this.troopsNameList[listCount].depth = 100;
        this.input.setDraggable(this.troopsImgList[listCount]);
        var troopQuantity = this.SetInputElement(troop.troopsQuantity, "CastleSlot" + (troopCount+1));
        this.troopsQuantityList[listCount] = this.add.dom(150, 100, troopQuantity);
        this.troopsQuantityList[listCount].depth = 100;
        this.troopsQuantityList[listCount].name = "CastleSlot" + (troopCount+1);
        this.troopsQuantityList[listCount].value = troop.troopsQuantity;
        this.troopsNumberList[listCount] = this.add.text(50, 50, "/" + troop.troopsQuantity, this.troopsNumberStyle);
        this.troopsNumberList[listCount].depth = 100;
        this.troopsNumberList[listCount].name = "CastleSlot" + (troopCount+1);
        this.troopsNumberList[listCount].value = troop.troopsQuantity;
    }

    SetTroopKnightImage(troop: any, troopCount: number)
    {
        var listCount = this.troopsKnightImgList.length;
        this.troopsKnightImgList[listCount] = this.add.sprite(48 + ((troopCount+1)*90), 213, troop.troopsType).setInteractive();
        this.troopsKnightImgList[listCount].name = "KnightSlot" + (troopCount+1);
        this.troopsKnightImgList[listCount].troopsType = troop.troopsType;
        var troopTypeName = this.getTroopTypeName(troop.troopsType);
        this.troopsKnightNameList[listCount] = this.add.text(50, 50, troopTypeName, this.style);
        this.troopsKnightNameList[listCount].name = "KnightSlot" + (troopCount+1);
        this.troopsKnightNameList[listCount].depth = 100;
        this.input.setDraggable(this.troopsKnightImgList[listCount]);
        var troopQuantity = this.SetInputElement(troop.troopsQuantity, "KnightSlot" + (troopCount+1));
        this.troopsKnightQuantityList[listCount] = this.add.dom(150, 100, troopQuantity);
        this.troopsKnightQuantityList[listCount].depth = 100;
        this.troopsKnightQuantityList[listCount].name = "KnightSlot" + (troopCount+1);
        this.troopsKnightQuantityList[listCount].value = troop.troopsQuantity;
        this.troopsKnightNumberList[listCount] = this.add.text(50, 50, "/" + troop.troopsQuantity, this.troopsNumberStyle);
        this.troopsKnightNumberList[listCount].depth = 100;
        this.troopsKnightNumberList[listCount].name = "KnightSlot" + (troopCount+1);
        this.troopsKnightNumberList[listCount].value = troop.troopsQuantity;
    }

    SetInputElement(quantity: number, troopType: string) {
        let element = document.createElement('input');
        element.style.backgroundColor = "#ffffff"
        element.style.width = '35px';
        element.style.height = '15px';
        element.style.font = "11px Arial"
        element.style.textAlign = "right";
        element.disabled = false;
        element.value = quantity.toString();
        element.id = troopType + "Quantity";
        element.setAttribute("type", "number");
        element.classList.add("removeUpDownArrows")
        return element;
    }


    updateKnightSlot(dropZoneSlot: number, dropZoneStartSlot: number, gameObject: any, troop: any, troopsNumber: number, troopsQuantityId: string)
    {
        troop.troopsType = gameObject.troopsType;
        var existingTroopsQuantityId = "KnightSlot" + dropZoneSlot + "Quantity";
        var existingGameObjectName = "KnightSlot" + dropZoneSlot;
        var existingSlot = this.troopsKnightImgList.find(x => x.name == existingGameObjectName);
        var newGameObjectName = "KnightSlot" + dropZoneStartSlot;
        var newSlot = this.troopsKnightImgList.find(x => x.name == newGameObjectName);
        if (troop.troopsQuantity === troopsNumber)
        {
            if (existingSlot!=undefined)
            {
                if (existingSlot.troopsType === newSlot.troopsType)
                {
                    this.increaseKnightExistingTroop(existingGameObjectName, existingTroopsQuantityId, troop.troopsQuantity, existingSlot);
                    this.removeTroopKnightImage(gameObject);
                }
                else
                {
                    existingSlot.x = gameObject.input.dragStartX;
                    existingSlot.y = gameObject.input.dragStartY;
                    this.updateKnightExistingListName(newGameObjectName, existingGameObjectName, dropZoneStartSlot, dropZoneSlot);
                }
            }
            else
            {
                this.updateKnightListName(newGameObjectName, existingGameObjectName, dropZoneStartSlot, dropZoneSlot);
            }
        }
        else
        {
            if (existingSlot!=undefined)
            {
                if (existingSlot.troopsType === newSlot.troopsType)
                {
                    this.increaseKnightExistingTroop(existingGameObjectName, existingTroopsQuantityId, troop.troopsQuantity, existingSlot);
                    newSlot.x = gameObject.input.dragStartX;
                    newSlot.y = gameObject.input.dragStartY;
                    this.reduceKnightExistingTroop(newGameObjectName, troopsQuantityId, troop.troopsQuantity, newSlot)
                }
                else
                {
                    gameObject.x = gameObject.input.dragStartX;
                    gameObject.y = gameObject.input.dragStartY;
                }
            }
            else
            {
                this.SetTroopKnightImage(troop, dropZoneSlot - 1);
                (document.getElementById(troopsQuantityId) as HTMLInputElement).value = (troopsNumber - troop.troopsQuantity).toString();
                this.troopsKnightNumberList.find(x => x.name == gameObject.name).setText("/" + (troopsNumber - troop.troopsQuantity));
                newSlot.troopNumber = troopsNumber - troop.troopsQuantity;
                this.troopsKnightQuantityList.find(x => x.name == gameObject.name).value = troopsNumber - troop.troopsQuantity;
                this.troopsKnightNumberList.find(x => x.name == gameObject.name).value = troopsNumber - troop.troopsQuantity;
                newSlot.x = gameObject.input.dragStartX;
                newSlot.y = gameObject.input.dragStartY;
            }
        }
    }

    updateKnightListName(newGameObjectName: string, existingGameObjectName: string, dropZoneStartSlot: number, dropZoneSlot: number)
    {
        this.troopsKnightImgList.find(x => x.name == newGameObjectName).name = existingGameObjectName;
        this.troopsKnightQuantityList.find(x => x.name == newGameObjectName).name = existingGameObjectName;
        this.troopsKnightNumberList.find(x => x.name == newGameObjectName).name = existingGameObjectName;
        this.troopsKnightNameList.find(x => x.name == newGameObjectName).name = existingGameObjectName;
        this.updateKnightTroopElement(dropZoneStartSlot, dropZoneSlot);
    }

    updateKnightExistingListName(newGameObjectName: string, existingGameObjectName: string, dropZoneStartSlot: number, dropZoneSlot: number)
    {
        var newTroopsKnightImg = this.troopsKnightImgList.find(x => x.name == newGameObjectName);
        var newTroopsKnightQuantity = this.troopsKnightQuantityList.find(x => x.name == newGameObjectName);
        var newTroopsKnightNumber = this.troopsKnightNumberList.find(x => x.name == newGameObjectName);
        var newTroopsKnightName = this.troopsKnightNameList.find(x => x.name == newGameObjectName);
        this.troopsKnightImgList.find(x => x.name == existingGameObjectName).name = newGameObjectName;
        this.troopsKnightQuantityList.find(x => x.name == existingGameObjectName).name = newGameObjectName;
        this.troopsKnightNumberList.find(x => x.name == existingGameObjectName).name = newGameObjectName;
        this.troopsKnightNameList.find(x => x.name == existingGameObjectName).name = newGameObjectName;
        newTroopsKnightImg.name = existingGameObjectName;
        newTroopsKnightQuantity.name = existingGameObjectName;
        newTroopsKnightNumber.name = existingGameObjectName;
        newTroopsKnightName.name = existingGameObjectName;
        this.updateKnightExistingTroopElement(dropZoneStartSlot, dropZoneSlot);
    }

    updateKnightTroopElement(dropZoneStartSlot: number, dropZoneSlot: number)
    {
        var troopsQuantityId = "KnightSlot" + dropZoneStartSlot + "Quantity";
        var troopElement = (document.getElementById(troopsQuantityId) as HTMLInputElement);
        troopElement.id = "KnightSlot" + dropZoneSlot + "Quantity";
    }

    updateKnightExistingTroopElement(dropZoneStartSlot: number, dropZoneSlot: number)
    {
        var troopsQuantityId = "KnightSlot" + dropZoneStartSlot + "Quantity";
        var existingTroopsQuantityId = "KnightSlot" + dropZoneSlot + "Quantity";
        var troopElement = (document.getElementById(troopsQuantityId) as HTMLInputElement);
        var existingTroopElement = (document.getElementById(existingTroopsQuantityId) as HTMLInputElement);
        troopElement.id = "KnightSlot" + dropZoneSlot + "Quantity";
        existingTroopElement.id = "KnightSlot" + dropZoneStartSlot + "Quantity";
    }

    updateTroopImg(dropZoneSlot: number, troopsQuantity: number, troopsType: string)
    {
        var existingGameObjectName = "CastleSlot" + dropZoneSlot;
        var existingSlot = this.troopsImgList.find(x => x.name == existingGameObjectName);
        var existingTroopsQuantityId = "CastleSlot" + dropZoneSlot + "Quantity";
        (document.getElementById(existingTroopsQuantityId) as HTMLInputElement).value = (troopsQuantity).toString();
        this.troopsNumberList.find(x => x.name == existingSlot.name).setText("/" + (troopsQuantity));
        existingSlot.troopNumber = troopsQuantity;
        this.troopsQuantityList.find(x => x.name == existingGameObjectName).value = troopsQuantity;
        this.troopsNumberList.find(x => x.name == existingGameObjectName).value = troopsQuantity;
        this.troopsImgList.find(x => x.name == existingGameObjectName).setTexture(troopsType);
        this.troopsImgList.find(x => x.name == existingGameObjectName).troopsType = troopsType;
        var troopTypeName = this.getTroopTypeName(troopsType);
        this.troopsNameList.find(x => x.name == existingGameObjectName).setText(troopTypeName);
    }

    updateTroopKnightImg(dropZoneStartSlot: number, troopsQuantity: number, troopsType: string)
    {
        var existingGameObjectName = "KnightSlot" + dropZoneStartSlot;
        var existingSlot = this.troopsKnightImgList.find(x => x.name == existingGameObjectName);
        var existingTroopsQuantityId = "KnightSlot" + dropZoneStartSlot + "Quantity";
        (document.getElementById(existingTroopsQuantityId) as HTMLInputElement).value = (troopsQuantity).toString();
        this.troopsKnightNumberList.find(x => x.name == existingSlot.name).setText("/" + (troopsQuantity));
        existingSlot.troopNumber = troopsQuantity;
        this.troopsKnightQuantityList.find(x => x.name == existingGameObjectName).value = troopsQuantity;
        this.troopsKnightNumberList.find(x => x.name == existingGameObjectName).value = troopsQuantity;
        this.troopsKnightImgList.find(x => x.name == existingGameObjectName).setTexture(troopsType);
        this.troopsKnightImgList.find(x => x.name == existingGameObjectName).troopsType = troopsType;
        var troopTypeName = this.getTroopTypeName(troopsType);
        this.troopsKnightNameList.find(x => x.name == existingGameObjectName).setText(troopTypeName);
    }

    increaseKnightExistingTroop(existingGameObjectName: string, existingTroopsQuantityId: string, troopsQuantity: number,  existingSlot: any)
    {
        var newSlotTroopsNumber = this.troopsKnightNumberList.find(x => x.name == existingGameObjectName).value;
        (document.getElementById(existingTroopsQuantityId) as HTMLInputElement).value = (newSlotTroopsNumber + troopsQuantity).toString();
        this.troopsKnightNumberList.find(x => x.name == existingSlot.name).setText("/" + (newSlotTroopsNumber + troopsQuantity));
        existingSlot.troopNumber = newSlotTroopsNumber + troopsQuantity;
        this.troopsKnightQuantityList.find(x => x.name == existingGameObjectName).value = newSlotTroopsNumber + troopsQuantity;
        this.troopsKnightNumberList.find(x => x.name == existingGameObjectName).value = newSlotTroopsNumber + troopsQuantity;
    }

    increaseExistingTroop(existingGameObjectName: string, existingTroopsQuantityId: string, troopsQuantity: number,  existingSlot: any)
    {
        var newSlotTroopsNumber = this.troopsNumberList.find(x => x.name == existingGameObjectName).value;
        (document.getElementById(existingTroopsQuantityId) as HTMLInputElement).value = (newSlotTroopsNumber + troopsQuantity).toString();
        this.troopsNumberList.find(x => x.name == existingSlot.name).setText("/" + (newSlotTroopsNumber + troopsQuantity));
        existingSlot.troopNumber = newSlotTroopsNumber + troopsQuantity;
        this.troopsQuantityList.find(x => x.name == existingGameObjectName).value = newSlotTroopsNumber + troopsQuantity;
        this.troopsNumberList.find(x => x.name == existingGameObjectName).value = newSlotTroopsNumber + troopsQuantity;
    }

    reduceKnightExistingTroop(newGameObjectName: string, troopsQuantityId: string, troopsQuantity: number,  newSlot: any)
    {
        var existingSlotTroopsNumber = this.troopsKnightNumberList.find(x => x.name == newGameObjectName).value;
        (document.getElementById(troopsQuantityId) as HTMLInputElement).value = (existingSlotTroopsNumber - troopsQuantity).toString();
        this.troopsKnightNumberList.find(x => x.name == newSlot.name).setText("/" + (existingSlotTroopsNumber - troopsQuantity));
        newSlot.troopNumber = existingSlotTroopsNumber - troopsQuantity;
        this.troopsKnightQuantityList.find(x => x.name == newGameObjectName).value = existingSlotTroopsNumber - troopsQuantity;
        this.troopsKnightNumberList.find(x => x.name == newGameObjectName).value = existingSlotTroopsNumber - troopsQuantity;
    }

    removeTroopImage(gameObject: any)
    {
        this.troopsImgList = this.troopsImgList.filter(obj => obj.name !== gameObject.name);
        this.troopsQuantityList = this.troopsQuantityList.filter(obj => obj.name !== gameObject.name);
        this.troopsNumberList = this.troopsNumberList.filter(obj => obj.name !== gameObject.name);
        this.troopsNameList = this.troopsNameList.filter(obj => obj.name !== gameObject.name);
        gameObject.destroy();
        const troopText = this.children.getByName(gameObject.name);
        troopText.destroy();
        const troopQuantity = this.children.getByName(gameObject.name);
        troopQuantity.destroy();
        const troopNumber = this.children.getByName(gameObject.name);
        troopNumber.destroy();
    }

    removeTroopKnightImage(gameObject: any)
    {
        this.troopsKnightImgList = this.troopsKnightImgList.filter(obj => obj.name !== gameObject.name);
        this.troopsKnightQuantityList = this.troopsKnightQuantityList.filter(obj => obj.name !== gameObject.name);
        this.troopsKnightNumberList = this.troopsKnightNumberList.filter(obj => obj.name !== gameObject.name);
        this.troopsKnightNameList = this.troopsKnightNameList.filter(obj => obj.name !== gameObject.name);
        gameObject.destroy();
        const troopText = this.children.getByName(gameObject.name);
        troopText.destroy();
        const troopQuantity = this.children.getByName(gameObject.name);
        troopQuantity.destroy();
        const troopNumber = this.children.getByName(gameObject.name);
        if (troopNumber != undefined)
            troopNumber.destroy();
        const element = this.children.getByName(gameObject.name);
        if (element != undefined)
            element.destroy();
    }

    setLevel1Rectangles()
    {
        this.castle = this.add.rectangle(48, 53, 80, 100);
        this.castle.setStrokeStyle(4, 0x716E63);
        this.children.bringToTop(this.castle);
        this.castleImg = this.add.sprite(48, 53, 'Castle');
        this.castleImg.Name = 'textCastle';
        this.textCastle = this.add.text(0, 0, "Zamek", this.style);
        this.textCastle.depth = 100;
        for (let i = 1; i < 8; i++) {
            this.troopsList[i - 1] = this.add.rectangle(48 + (i*90), 53, 80, 100);
            this.troopsList[i - 1].setStrokeStyle(4, 0x716E63);
            this.add.zone(48 + (i*90), 53, 80, 100).setRectangleDropZone(80, 100);
        }
    }

    setKnightRectangle()
    {
        this.knight1 = this.add.rectangle(48, 213, 80, 100);
        this.knight1.setStrokeStyle(4, 0x716E63);
        this.knight1Img = this.add.sprite(48, 213, 'Castle');
        this.textKnight1 = this.add.text(0, 0, "Kapitan", this.style);
        this.textCastle.depth = 100;
        for (let i = 1; i < 8; i++) {
            this.knight1TroopsList[i - 1] = this.add.rectangle(48 + (i*90), 213, 80, 100);
            this.knight1TroopsList[i - 1].setStrokeStyle(4, 0x716E63);
            this.add.zone(48 + (i*90), 213, 80, 100).setRectangleDropZone(80, 100);
        }
    }

    getTroopTypeName(troopType)
    {
        if (troopType == 'Slingers')
            return 'Procarze';  
        if (troopType == 'Axemen')
            return 'Topornicy';  
        if (troopType == 'BludgeonWarrior')
            return 'Wojownik';  
        if (troopType == 'Cataphract')
            return 'Katafrakci';  
        if (troopType == 'Lancer')
            return 'Lansjerzy';  
        if (troopType == 'Scout')
            return 'Zwiadowcy';  
        if (troopType == 'Catapult')
            return 'Katapulta';  
        if (troopType == 'Archer')
            return 'Łucznik';  
        if (troopType == 'Crossbowman')
            return 'Kusznik';  
        if (troopType == 'Pikemen')
            return 'Pikinier';  
        if (troopType == 'Swordsman')
            return 'Miecznik';  
        if (troopType == 'Knight')
            return 'Rycerz';  
        if (troopType == 'Hussars')
            return 'Huzarzy';  
        if (troopType == 'Ballista')
            return 'Balista';  
        if (troopType == 'Cannon')
            return 'Armata';  
        if (troopType == 'Scythe')
            return 'Kosynier';  
        if (troopType == 'Grenadier')
            return 'Grenadier'; 
        if (troopType == 'Dragons')
            return 'Dragoni';  
        if (troopType == 'WingedHussars')
            return 'Husaria'; 
        if (troopType == 'Trebuchet')
            return 'Trebusz'; 
        if (troopType == 'Gun')
            return 'Działo'; 
        if (troopType == 'Marksman')
            return 'Arkebuzer'; 
        if (troopType == 'Musketeer')
            return 'Muszkieter'; 
        if (troopType == 'Rajtar')
            return 'Rajtarzy'; 
        if (troopType == 'Balloons')
            return 'Balony'; 
        if (troopType == 'Mitrailleuse')
            return 'Kartaczownica'; 
        if (troopType == 'Baron')
            return 'Baron'; 
        if (troopType == 'Settler')
            return 'Osadnik'; 
    }

    update() {

        if (this.textCastle != undefined)
        {
            this.textCastle.x = Math.floor((this.castleImg.x - 100 + this.castleImg.width));
            this.textCastle.y = Math.floor((this.castleImg.y - 135 + this.castleImg.height));
        }
        if (this.textKnight1 != undefined)
        {
            this.textKnight1.x = Math.floor((this.knight1Img.x - 100 + this.knight1Img.width));
            this.textKnight1.y = Math.floor((this.knight1Img.y - 135 + this.knight1Img.height));
        }
        var troopsNameCount = 0;
        this.troopsNameList.forEach((troopName) => {
            if (troopName != undefined)
            {
                this.troopsNameList[troopsNameCount].x = Math.floor(this.troopsImgList[troopsNameCount].x -100 + this.troopsImgList[troopsNameCount].width);
                this.troopsNameList[troopsNameCount].y = Math.floor(this.troopsImgList[troopsNameCount].y -135 + this.troopsImgList[troopsNameCount].height);
            }
            troopsNameCount++;
        });
        var troopsQuantityCount = 0;
        this.troopsQuantityList.forEach((troopQuantity) => {
            if (troopQuantity != undefined)
            {
                this.troopsQuantityList[troopsQuantityCount].x = Math.floor(this.troopsImgList[troopsQuantityCount].x -90 + this.troopsImgList[troopsQuantityCount].width);
                this.troopsQuantityList[troopsQuantityCount].y = Math.floor(this.troopsImgList[troopsQuantityCount].y -55 + this.troopsImgList[troopsQuantityCount].height);
            }
            troopsQuantityCount++;
        });
        var troopsNumberCount = 0;
        this.troopsNumberList.forEach((troopNumber) => {
            if (troopNumber != undefined)
            {
                this.troopsNumberList[troopsNumberCount].x = Math.floor(this.troopsImgList[troopsNumberCount].x -68 + this.troopsImgList[troopsNumberCount].width);
                this.troopsNumberList[troopsNumberCount].y = Math.floor(this.troopsImgList[troopsNumberCount].y -60 + this.troopsImgList[troopsNumberCount].height);
            }
            troopsNumberCount++;
        });
        var troopsKnightNameCount = 0;
        this.troopsKnightNameList.forEach((troopName) => {
            if (troopName != undefined)
            {
                this.troopsKnightNameList[troopsKnightNameCount].x = Math.floor(this.troopsKnightImgList[troopsKnightNameCount].x -100 + this.troopsKnightImgList[troopsKnightNameCount].width);
                this.troopsKnightNameList[troopsKnightNameCount].y = Math.floor(this.troopsKnightImgList[troopsKnightNameCount].y -135 + this.troopsKnightImgList[troopsKnightNameCount].height);
            }
            troopsKnightNameCount++;
        });
        var troopsKnightQuantityCount = 0;
        this.troopsKnightQuantityList.forEach((troopQuantity) => {
            if (troopQuantity != undefined)
            {
                this.troopsKnightQuantityList[troopsKnightQuantityCount].x = Math.floor(this.troopsKnightImgList[troopsKnightQuantityCount].x -90 + this.troopsKnightImgList[troopsKnightQuantityCount].width);
                this.troopsKnightQuantityList[troopsKnightQuantityCount].y = Math.floor(this.troopsKnightImgList[troopsKnightQuantityCount].y -55 + this.troopsKnightImgList[troopsKnightQuantityCount].height);
            }
            troopsKnightQuantityCount++;
        });
        var troopsKnightNumberCount = 0;
        this.troopsKnightNumberList.forEach((troopNumber) => {
            if (troopNumber != undefined)
            {
                this.troopsKnightNumberList[troopsKnightNumberCount].x = Math.floor(this.troopsKnightImgList[troopsKnightNumberCount].x -68 + this.troopsKnightImgList[troopsKnightNumberCount].width);
                this.troopsKnightNumberList[troopsKnightNumberCount].y = Math.floor(this.troopsKnightImgList[troopsKnightNumberCount].y -60 + this.troopsKnightImgList[troopsKnightNumberCount].height);
            }
            troopsKnightNumberCount++;
        });
    }

}
