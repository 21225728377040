import { Coords } from "../maps/coords";
import { SourcesDemand } from "./sourcesDemand";
import { TroopSlots } from "./troopSlots";

export class Captain {
    name: string;
    level: number;
    sources: SourcesDemand;
    defence: number;
    attack: number;
    speed: number;
    shooting: number;
    status: string;
    coords: Coords;
    troopSlots: TroopSlots
}