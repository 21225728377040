import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { ProfileService } from '@app/_services';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'show-field-modal-send-troops',
  templateUrl: './show-field-modal-send-troops.component.html',
  styleUrls: ['./show-field-modal-send-troops.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ShowFieldModalSendTroopsComponent implements OnInit {

  constructor(public modal: NgbModal,
    private router: Router,
    private profileService: ProfileService) {}

  @Input() my_modal_position_x = 100;
  closeResult = '';

  ngOnInit() {

  }

  public submit() {
    //this.activeModal.close(/* your result */);
  }

  public redirect(x:any)
  {
    this.router.navigate(['/maps/field', 50, 50]);
  }

  open(content, options) {
		this.modal.open(content, options).result.then(
			(result) => {
				this.closeResult = `Closed with: ${result}`;
			},
			(reason) => {
				this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			},
		);
	}

  dismiss()
  {
    this.modal.dismissAll();
  }

	private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return 'by pressing ESC';
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return 'by clicking on a backdrop';
		} else {
			return `with: ${reason}`;
		}
	}
}