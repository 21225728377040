import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TownHallComponent } from '@app/buildings/townhall.component';
import { ImagesHelper } from '@app/_helpers/images.helper';
import { ProfileDetail, User, VillagesList } from '@app/_models';
import { MainbarImages } from '@app/_models/images/mainbarImages';
import { MainbarDetail } from '@app/_models/village/mainbarDetail';
import { AccountService, ProfileService } from '@app/_services';
import { VillageService } from '@app/_services/village.service';
import { Subscription, timer } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Component({ selector: 'app-mainbar',
    styleUrls: ['./mainbar.component.css'],
    templateUrl: 'mainbar.component.html' })
export class MainbarComponent implements OnInit {
    images: MainbarImages;
    user: User;
    villagesList: VillagesList;
    profileDetail: ProfileDetail;
    mainbarDetail: MainbarDetail;
    subscription: Subscription;
    subscriptionTutorial: Subscription;
    redirectUrl: string;

    constructor(private villageService: VillageService,
        private router: Router,
        private accountService: AccountService,
        private profileService: ProfileService) 
        {
            this.villageService.getMainbarDetailsToPromise().then(result => {
                this.mainbarDetail = result;
                if (result.coords != undefined)
                {
                    this.redirectUrl = '/village/details/' + result.coords.x + '/' + result.coords.y;
                }
            });
    }
    ngOnInit() {

        this.images = new MainbarImages();
        this.user = this.accountService.userValue;
        //this.villagesList = JSON.parse(localStorage.getItem('villages'));
        //var isUserVillage = this.villagesList.villages.find(x=>x.owner == this.user.username && x.coords.x == this.mainbarDetail.coords.x && x.coords.y == this.mainbarDetail.coords.y);
        // if (!isUserVillage)
        // {
        //    this.router.navigate(['/home']);
        // }

        this.profileService.getProfileDetails().subscribe(result => this.profileDetail = result);

        if (this.mainbarDetail?.coords != undefined)
        {
            this.redirectUrl = '/village/details/' + this.mainbarDetail?.coords?.x + '/' + this.mainbarDetail?.coords?.y;
        }

        this.subscription = timer(100, 1000).pipe(
            switchMap(() => this.villageService.checkMainbarDetailSources(this.router.url))
        ).subscribe(result => {
            this.mainbarDetail = result
            if (result?.coords != undefined)
            {
                this.redirectUrl = '/village/details/' + result.coords.x + '/' + result.coords.y;
            }
        });
        this.subscriptionTutorial = timer(100, 1000).pipe(
            switchMap(() => this.villageService.checkTutorialDetail(this.profileDetail))
        ).subscribe(result => {
            if (this.profileDetail != undefined)
            {
                this.profileDetail.tutorialDetail = result;
            }
            else
            {
                this.profileDetail = new ProfileDetail();
                this.profileDetail.tutorialDetail = result;
            }
        });
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
        this.subscriptionTutorial.unsubscribe();
     }
}