import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { VillageDetail } from '@app/_models/village/villageDetail';
import { MainbarDetail } from '@app/_models/village/mainbarDetail';
import { IncreaseBuilding, BuildingCanIncrease, SourcesAreMax, IncreaseBuildingResponse, RecruitmentDetail, CancelBuildingIncrease, SendTroops, SendTroopsResponse, CancelSendTroops, ProfileDetail } from '@app/_models';
import { environment } from '@environments/environment';
import { SourcesDemand } from '@app/_models/village/sourcesDemand';
import { SourcesCanIncrease } from '@app/_models/townHall/sourcesCanIncrease';
import { LevelSources } from '@app/_models/village/levelSources';
import { Router } from '@angular/router';
import { VillageTroopDetail } from '@app/_models/village/villageTroopDetail';
import { VillageHelper } from '@app/_helpers/village.helper';
import { VillageList } from '@app/_models/info/villageList';
import { IncreaseVillageSourcesModelRequest } from '@app/_models/village/increaseVillageSourcesModelRequest';
import { IncreaseVillageSourcesResponse } from '@app/_models/village/increaseVillageSourcesResponse';
import { SourcesHelper } from '@app/_helpers/sources.helper';
import { InstantFinishBuilding } from '@app/_models/townHall/instantFinishBuilding';
import { CaptainDetail } from '@app/_models/village/captainDetail';
import { CreateCaptain } from '@app/_models/village/createCaptain';
import { CaptainResponse } from '@app/_models/village/captainResponse';
import { TrainCaptain } from '@app/_models/village/trainCaptain';


@Injectable({ providedIn: 'root' })
export class VillageService {
    villages: VillageDetail[];
    mainbarDetail : MainbarDetail;

    constructor(
        private router: Router,
        private http: HttpClient,
        private villageHelper: VillageHelper,
        private sourcesHelper: SourcesHelper
    ) {

    }

    getVillageDetails() {     
      
      const params = new HttpParams().append('x', '50').append('y', '50');  
      return this.http.get<VillageDetail>(`${environment.dataStorageUrl}/village/GetVillageDetailByCoords`, { params});
    }

    getVillageDetailsToPromise() : Promise<VillageDetail> {     
      const params = new HttpParams().append('x', '50').append('y', '50');  
      return new Promise(resolve=>{
        this.http.get<MainbarDetail>(`${environment.dataStorageUrl}/village/GetVillageDetailByCoords`, { params })
         .subscribe(
            (data:any) => {
                resolve(data);
         })
      })
    }

    getVillageTroopDetails(target_coordx, target_coordy) {     
      const params = new HttpParams().append('x', '50').append('y', '50').append('targetCoordX', target_coordx).append('targetCoordY', target_coordy);  
      return this.http.get<VillageTroopDetail>(`${environment.dataStorageUrl}/village/GetVillageTroopDetailByCoords`, { params});
    }

    getMainbarDetails()
    {
      const params = new HttpParams().append('x', '50').append('y', '50');
      return this.http.get<MainbarDetail>(`${environment.dataStorageUrl}/village/GetMainbarDetailByCoords`, { params})
    }

    getCaptainDetails() {     
      const params = new HttpParams().append('x', '50').append('y', '50');  
      return this.http.get<CaptainDetail>(`${environment.dataStorageUrl}/village/GetCaptainDetailByCoords`, { params});
    }

    getMainbarDetailsToPromise() : Promise<MainbarDetail> { 
      const params = new HttpParams().append('x', '50').append('y', '50');
      return new Promise(resolve=>{
        this.http.get<MainbarDetail>(`${environment.dataStorageUrl}/village/GetMainbarDetailByCoords`, { params })
         .subscribe(
            (data:any) => {
                this.sourcesHelper.updateMainbarDetailSources(data);
                this.mainbarDetail = data;
                resolve(data);
         })
      })
    }

    increaseBuildingLevel(increaseBuilding: IncreaseBuilding) : Promise<IncreaseBuildingResponse> {
      return new Promise(resolve=>{
        this.http.post<IncreaseBuildingResponse>(`${environment.dataStorageUrl}/village/increaseBuildingLevel`, increaseBuilding)
         .subscribe(
            (data:any) => {
                this.sourcesHelper.updateMainbarDetailSources(data.mainbarDetail);
                this.sourcesHelper.updateTutorialDetail(data.profileDetail.tutorialDetail);
                this.mainbarDetail = data.mainbarDetail;
                resolve(data);
         })
      })
    }

    increaseVillageSources(increaseVillageSources: IncreaseVillageSourcesModelRequest) : Promise<IncreaseVillageSourcesResponse>  {
      return new Promise(resolve=>{
        this.http.post<IncreaseVillageSourcesResponse>(`${environment.dataStorageUrl}/village/increaseVillageSources`, increaseVillageSources)
         .subscribe(
            (data:any) => {
                this.sourcesHelper.updateMainbarDetailSources(data.mainbarDetail);
                this.mainbarDetail = data.mainbarDetail;
                resolve(data);
         })
      })
    }

    cancelBuildingIncrease(cancelBuildingIncrease: CancelBuildingIncrease) : Promise<IncreaseBuildingResponse> 
    {
      return new Promise(resolve=>{
        this.http.post<IncreaseBuildingResponse>(`${environment.dataStorageUrl}/village/cancelBuildingIncrease`, cancelBuildingIncrease )
         .subscribe(
            (data:any) => {
                this.sourcesHelper.updateMainbarDetailSources(data.mainbarDetail);
                this.mainbarDetail = data.mainbarDetail;
                resolve(data);
         })
      })
    }

    instantFinishBuilding(instantFinishBuilding: InstantFinishBuilding) : Promise<IncreaseBuildingResponse> 
    {
      return new Promise(resolve=>{
        this.http.post<IncreaseBuildingResponse>(`${environment.dataStorageUrl}/village/instantFinishBuilding`, instantFinishBuilding )
         .subscribe(
            (data:any) => {
                this.sourcesHelper.updateMainbarDetailSources(data.mainbarDetail);
                this.mainbarDetail = data.mainbarDetail;
                resolve(data);
         })
      })
    }

    sendTroops(sendTroops: SendTroops) {
      return this.http.post<SendTroopsResponse>(`${environment.dataStorageUrl}/village/sendTroops`, sendTroops);
    }

    cancelSendTroops(cancelSendTroops: CancelSendTroops)
    {
      return this.http.post<SendTroopsResponse>(`${environment.dataStorageUrl}/village/cancelSendTroops`, cancelSendTroops );
    }

    updateBuildingWorkers(villageDetail: VillageDetail, buildingType: string, nrOfWorkers: number)
    {
      if (villageDetail != undefined)
      {
          var building = villageDetail.buildings[buildingType] as LevelSources;
          building.nrOfWorkers = nrOfWorkers;    
      }  
    }

    createCaptain(createCaptain: CreateCaptain) : Promise<CaptainResponse> {
      return new Promise(resolve=>{
        this.http.post<CaptainResponse>(`${environment.dataStorageUrl}/village/createCaptain`, createCaptain)
         .subscribe(
            (data:any) => {
                this.sourcesHelper.updateMainbarDetailSources(data.mainbarDetail);
                this.mainbarDetail = data.mainbarDetail;
                resolve(data);
         })
      })
    }

    trainCaptain(trainCaptain: TrainCaptain) : Promise<CaptainResponse> {
      return new Promise(resolve=>{
        this.http.post<CaptainResponse>(`${environment.dataStorageUrl}/village/trainCaptain`, trainCaptain)
         .subscribe(
            (data:any) => {
                this.sourcesHelper.updateMainbarDetailSources(data.mainbarDetail);
                this.mainbarDetail = data.mainbarDetail;
                resolve(data);
         })
      })
    }

    async checkMenuIconStatus(currentUrl: string)
    {
      return this.sourcesHelper.checkMenuIconStatus(currentUrl)
    }

    async checkBuildingCanIncrease(mainbarDetail: MainbarDetail, villageDetail: VillageDetail)
    {
        var buildingCanIncrease = new BuildingCanIncrease();
        if (villageDetail != undefined && mainbarDetail?.villageSources != undefined)
        {
          var actualMainbarDetail = this.sourcesHelper.getActualMainbarSourcesDemand(mainbarDetail);
          var availableWorkers = villageDetail.workers - villageDetail.workersInUse;
          buildingCanIncrease.forest = this.canSourceIncrease(actualMainbarDetail.villageSources.sourcesDisplayed, villageDetail.buildings.forest.sources, availableWorkers, villageDetail.buildings.forest.nrOfWorkers);  
          buildingCanIncrease.brickYard = this.canSourceIncrease(actualMainbarDetail.villageSources.sourcesDisplayed, villageDetail.buildings.brickYard.sources, availableWorkers, villageDetail.buildings.brickYard.nrOfWorkers); 
          buildingCanIncrease.quarry = this.canSourceIncrease(actualMainbarDetail.villageSources.sourcesDisplayed, villageDetail.buildings.quarry.sources, availableWorkers, villageDetail.buildings.quarry.minWorkers); 
          buildingCanIncrease.field = this.canSourceIncrease(actualMainbarDetail.villageSources.sourcesDisplayed, villageDetail.buildings.field.sources, availableWorkers, villageDetail.buildings.field.minWorkers); 
          buildingCanIncrease.castle = this.canSourceIncrease(actualMainbarDetail.villageSources.sourcesDisplayed, villageDetail.buildings.castle.sources, availableWorkers, villageDetail.buildings.castle.minWorkers); 
          buildingCanIncrease.wallSector1 = this.canSourceIncrease(actualMainbarDetail.villageSources.sourcesDisplayed, villageDetail.buildings.wallSector1.sources, availableWorkers, villageDetail.buildings.wallSector1.minWorkers); 
          buildingCanIncrease.wallSector2 = this.canSourceIncrease(actualMainbarDetail.villageSources.sourcesDisplayed, villageDetail.buildings.wallSector2.sources, availableWorkers, villageDetail.buildings.wallSector2.minWorkers); 
          buildingCanIncrease.townHall = this.canSourceIncrease(actualMainbarDetail.villageSources.sourcesDisplayed, villageDetail.buildings.townHall.sources, availableWorkers, villageDetail.buildings.townHall.nrOfWorkers);
          buildingCanIncrease.granary = this.canSourceIncrease(actualMainbarDetail.villageSources.sourcesDisplayed, villageDetail.buildings.granary.sources, availableWorkers, villageDetail.buildings.granary.minWorkers);   
          buildingCanIncrease.storage = this.canSourceIncrease(actualMainbarDetail.villageSources.sourcesDisplayed, villageDetail.buildings.storage.sources, availableWorkers, villageDetail.buildings.storage.minWorkers);   
          buildingCanIncrease.heavyStorage = this.canSourceIncrease(actualMainbarDetail.villageSources.sourcesDisplayed, villageDetail.buildings.heavyStorage.sources, availableWorkers, villageDetail.buildings.heavyStorage.minWorkers);   
          buildingCanIncrease.barracks = this.canSourceIncrease(actualMainbarDetail.villageSources.sourcesDisplayed, villageDetail.buildings.barracks.sources, availableWorkers, villageDetail.buildings.barracks.minWorkers);   
          buildingCanIncrease.stable = this.canSourceIncrease(actualMainbarDetail.villageSources.sourcesDisplayed, villageDetail.buildings.stable.sources, availableWorkers, villageDetail.buildings.stable.minWorkers);   
          buildingCanIncrease.archeryTower = this.canSourceIncrease(actualMainbarDetail.villageSources.sourcesDisplayed, villageDetail.buildings.archeryTower.sources, availableWorkers, villageDetail.buildings.archeryTower.minWorkers);   
          buildingCanIncrease.soldiersHouse = this.canSourceIncrease(actualMainbarDetail.villageSources.sourcesDisplayed, villageDetail.buildings.soldiersHouse.sources, availableWorkers, villageDetail.buildings.soldiersHouse.minWorkers);   
          buildingCanIncrease.houses = this.canSourceIncrease(actualMainbarDetail.villageSources.sourcesDisplayed, villageDetail.buildings.houses.sources, availableWorkers, villageDetail.buildings.houses.minWorkers);   
          buildingCanIncrease.workshop = this.canSourceIncrease(actualMainbarDetail.villageSources.sourcesDisplayed, villageDetail.buildings.workshop.sources, availableWorkers, villageDetail.buildings.workshop.minWorkers);   
          buildingCanIncrease.place = this.canSourceIncrease(actualMainbarDetail.villageSources.sourcesDisplayed, villageDetail.buildings.place.sources, availableWorkers, villageDetail.buildings.place.minWorkers);   
          buildingCanIncrease.marketplace = this.canSourceIncrease(actualMainbarDetail.villageSources.sourcesDisplayed, villageDetail.buildings.marketplace.sources, availableWorkers, villageDetail.buildings.marketplace.minWorkers);   
          buildingCanIncrease.catapultTower = this.canSourceIncrease(actualMainbarDetail.villageSources.sourcesDisplayed, villageDetail.buildings.catapultTower.sources, availableWorkers, villageDetail.buildings.catapultTower.minWorkers);   
          buildingCanIncrease.alchemist = this.canSourceIncrease(actualMainbarDetail.villageSources.sourcesDisplayed, villageDetail.buildings.alchemist.sources, availableWorkers, villageDetail.buildings.alchemist.minWorkers);        
        }
        return buildingCanIncrease;
    }

    async checkCanRecruitWorkers(recruitmentDetail: RecruitmentDetail, profileDetail: ProfileDetail, mainbarDetail: MainbarDetail)
    {
      if (recruitmentDetail != undefined)
      {
        recruitmentDetail.workers.sourcesCanRecruit.max = 1;
        recruitmentDetail.workers.sourcesDisplayed = this.villageHelper.setRecruitmentSourcesDisplayed(recruitmentDetail.workers.sources, recruitmentDetail.workers.quantity);
        recruitmentDetail.explorer.sourcesCanRecruit.max = 1;
        recruitmentDetail.explorer.sourcesDisplayed = this.villageHelper.setRecruitmentSourcesDisplayed(recruitmentDetail.explorer.sources, recruitmentDetail.explorer.quantity);
        var sourcesSummary = this.villageHelper.setSourcesSummaryHouses(recruitmentDetail, profileDetail);
        if (mainbarDetail?.villageSources != undefined)
        {
            var actualMainbarDetail = this.sourcesHelper.getActualMainbarSourcesDemand(mainbarDetail);
            recruitmentDetail.workers.sourcesCanRecruit = this.villageHelper.canRecruitmentIncrease(actualMainbarDetail.villageSources.sourcesDisplayed, sourcesSummary, recruitmentDetail.workers.sources, recruitmentDetail.workers.sourcesDisplayed)
            recruitmentDetail.explorer.sourcesCanRecruit = this.villageHelper.canRecruitmentIncrease(actualMainbarDetail.villageSources.sourcesDisplayed, sourcesSummary, recruitmentDetail.explorer.sources, recruitmentDetail.explorer.sourcesDisplayed)
        }
      }
      return recruitmentDetail;
    }

    async checkCanRecruitBarracks(recruitmentDetail: RecruitmentDetail, profileDetail: ProfileDetail, mainbarDetail: MainbarDetail)
    {
      if (recruitmentDetail != undefined)
      {
        recruitmentDetail.axemen.sourcesCanRecruit.max = 1;
        recruitmentDetail.axemen.sourcesDisplayed = this.villageHelper.setRecruitmentSourcesDisplayed(recruitmentDetail.axemen.sources, recruitmentDetail.axemen.quantity);
        recruitmentDetail.bludgeonWarrior.sourcesCanRecruit.max = 1;
        recruitmentDetail.bludgeonWarrior.sourcesDisplayed = this.villageHelper.setRecruitmentSourcesDisplayed(recruitmentDetail.bludgeonWarrior.sources, recruitmentDetail.bludgeonWarrior.quantity);
        recruitmentDetail.pikemen.sourcesCanRecruit.max = 1;
        recruitmentDetail.pikemen.sourcesDisplayed = this.villageHelper.setRecruitmentSourcesDisplayed(recruitmentDetail.pikemen.sources, recruitmentDetail.pikemen.quantity);
        recruitmentDetail.swordsman.sourcesCanRecruit.max = 1;
        recruitmentDetail.swordsman.sourcesDisplayed = this.villageHelper.setRecruitmentSourcesDisplayed(recruitmentDetail.swordsman.sources, recruitmentDetail.swordsman.quantity);
        recruitmentDetail.scythe.sourcesCanRecruit.max = 1;
        recruitmentDetail.scythe.sourcesDisplayed = this.villageHelper.setRecruitmentSourcesDisplayed(recruitmentDetail.scythe.sources, recruitmentDetail.scythe.quantity);
        recruitmentDetail.musketeer.sourcesCanRecruit.max = 1;
        recruitmentDetail.musketeer.sourcesDisplayed = this.villageHelper.setRecruitmentSourcesDisplayed(recruitmentDetail.musketeer.sources, recruitmentDetail.musketeer.quantity);
        var sourcesSummary = this.villageHelper.setSourcesSummaryBarracks(recruitmentDetail, profileDetail);
        if (mainbarDetail?.villageSources != undefined)
        {
            var actualMainbarDetail = this.sourcesHelper.getActualMainbarSourcesDemand(mainbarDetail);
            recruitmentDetail.axemen.sourcesCanRecruit = this.villageHelper.canRecruitmentIncrease(actualMainbarDetail.villageSources.sourcesDisplayed, sourcesSummary, recruitmentDetail.axemen.sources, recruitmentDetail.axemen.sourcesDisplayed)
            recruitmentDetail.bludgeonWarrior.sourcesCanRecruit = this.villageHelper.canRecruitmentIncrease(actualMainbarDetail.villageSources.sourcesDisplayed, sourcesSummary, recruitmentDetail.bludgeonWarrior.sources, recruitmentDetail.bludgeonWarrior.sourcesDisplayed)
            recruitmentDetail.pikemen.sourcesCanRecruit = this.villageHelper.canRecruitmentIncrease(actualMainbarDetail.villageSources.sourcesDisplayed, sourcesSummary, recruitmentDetail.pikemen.sources, recruitmentDetail.pikemen.sourcesDisplayed)
            recruitmentDetail.swordsman.sourcesCanRecruit = this.villageHelper.canRecruitmentIncrease(actualMainbarDetail.villageSources.sourcesDisplayed, sourcesSummary, recruitmentDetail.swordsman.sources, recruitmentDetail.swordsman.sourcesDisplayed)
            recruitmentDetail.scythe.sourcesCanRecruit = this.villageHelper.canRecruitmentIncrease(actualMainbarDetail.villageSources.sourcesDisplayed, sourcesSummary, recruitmentDetail.scythe.sources, recruitmentDetail.scythe.sourcesDisplayed)
            recruitmentDetail.musketeer.sourcesCanRecruit = this.villageHelper.canRecruitmentIncrease(actualMainbarDetail.villageSources.sourcesDisplayed, sourcesSummary, recruitmentDetail.musketeer.sources, recruitmentDetail.musketeer.sourcesDisplayed)
        }
      }
      return recruitmentDetail;
    }

    async checkCanRecruitStable(recruitmentDetail: RecruitmentDetail, profileDetail: ProfileDetail, mainbarDetail: MainbarDetail)
    {
      if (recruitmentDetail != undefined)
      {
        recruitmentDetail.cataphract.sourcesCanRecruit.max = 1;
        recruitmentDetail.cataphract.sourcesDisplayed = this.villageHelper.setRecruitmentSourcesDisplayed(recruitmentDetail.cataphract.sources, recruitmentDetail.cataphract.quantity);
        recruitmentDetail.lancer.sourcesCanRecruit.max = 1;
        recruitmentDetail.lancer.sourcesDisplayed = this.villageHelper.setRecruitmentSourcesDisplayed(recruitmentDetail.lancer.sources, recruitmentDetail.lancer.quantity);
        recruitmentDetail.scout.sourcesCanRecruit.max = 1;
        recruitmentDetail.scout.sourcesDisplayed = this.villageHelper.setRecruitmentSourcesDisplayed(recruitmentDetail.scout.sources, recruitmentDetail.scout.quantity);
        recruitmentDetail.knight.sourcesCanRecruit.max = 1;
        recruitmentDetail.knight.sourcesDisplayed = this.villageHelper.setRecruitmentSourcesDisplayed(recruitmentDetail.knight.sources, recruitmentDetail.knight.quantity);
        recruitmentDetail.hussars.sourcesCanRecruit.max = 1;
        recruitmentDetail.hussars.sourcesDisplayed = this.villageHelper.setRecruitmentSourcesDisplayed(recruitmentDetail.hussars.sources, recruitmentDetail.hussars.quantity);
        recruitmentDetail.dragons.sourcesCanRecruit.max = 1;
        recruitmentDetail.dragons.sourcesDisplayed = this.villageHelper.setRecruitmentSourcesDisplayed(recruitmentDetail.dragons.sources, recruitmentDetail.dragons.quantity);
        recruitmentDetail.wingedHussars.sourcesCanRecruit.max = 1;
        recruitmentDetail.wingedHussars.sourcesDisplayed = this.villageHelper.setRecruitmentSourcesDisplayed(recruitmentDetail.wingedHussars.sources, recruitmentDetail.wingedHussars.quantity);
        recruitmentDetail.rajtar.sourcesCanRecruit.max = 1;
        recruitmentDetail.rajtar.sourcesDisplayed = this.villageHelper.setRecruitmentSourcesDisplayed(recruitmentDetail.rajtar.sources, recruitmentDetail.rajtar.quantity);
        recruitmentDetail.balloons.sourcesCanRecruit.max = 1;
        recruitmentDetail.balloons.sourcesDisplayed = this.villageHelper.setRecruitmentSourcesDisplayed(recruitmentDetail.balloons.sources, recruitmentDetail.balloons.quantity);
        var sourcesSummary = this.villageHelper.setSourcesSummaryStable(recruitmentDetail, profileDetail);
        if (mainbarDetail?.villageSources != undefined)
        {
            var actualMainbarDetail = this.sourcesHelper.getActualMainbarSourcesDemand(mainbarDetail);
            recruitmentDetail.cataphract.sourcesCanRecruit = this.villageHelper.canRecruitmentIncrease(actualMainbarDetail.villageSources.sourcesDisplayed, sourcesSummary, recruitmentDetail.cataphract.sources, recruitmentDetail.cataphract.sourcesDisplayed)
            recruitmentDetail.lancer.sourcesCanRecruit = this.villageHelper.canRecruitmentIncrease(actualMainbarDetail.villageSources.sourcesDisplayed, sourcesSummary, recruitmentDetail.lancer.sources, recruitmentDetail.lancer.sourcesDisplayed)
            recruitmentDetail.scout.sourcesCanRecruit = this.villageHelper.canRecruitmentIncrease(actualMainbarDetail.villageSources.sourcesDisplayed, sourcesSummary, recruitmentDetail.scout.sources, recruitmentDetail.scout.sourcesDisplayed)
            recruitmentDetail.knight.sourcesCanRecruit = this.villageHelper.canRecruitmentIncrease(actualMainbarDetail.villageSources.sourcesDisplayed, sourcesSummary, recruitmentDetail.knight.sources, recruitmentDetail.knight.sourcesDisplayed)
            recruitmentDetail.hussars.sourcesCanRecruit = this.villageHelper.canRecruitmentIncrease(actualMainbarDetail.villageSources.sourcesDisplayed, sourcesSummary, recruitmentDetail.hussars.sources, recruitmentDetail.hussars.sourcesDisplayed)
            recruitmentDetail.dragons.sourcesCanRecruit = this.villageHelper.canRecruitmentIncrease(actualMainbarDetail.villageSources.sourcesDisplayed, sourcesSummary, recruitmentDetail.dragons.sources, recruitmentDetail.dragons.sourcesDisplayed)
            recruitmentDetail.wingedHussars.sourcesCanRecruit = this.villageHelper.canRecruitmentIncrease(actualMainbarDetail.villageSources.sourcesDisplayed, sourcesSummary, recruitmentDetail.wingedHussars.sources, recruitmentDetail.wingedHussars.sourcesDisplayed)
            recruitmentDetail.rajtar.sourcesCanRecruit = this.villageHelper.canRecruitmentIncrease(actualMainbarDetail.villageSources.sourcesDisplayed, sourcesSummary, recruitmentDetail.rajtar.sources, recruitmentDetail.rajtar.sourcesDisplayed)
            recruitmentDetail.balloons.sourcesCanRecruit = this.villageHelper.canRecruitmentIncrease(actualMainbarDetail.villageSources.sourcesDisplayed, sourcesSummary, recruitmentDetail.balloons.sources, recruitmentDetail.balloons.sourcesDisplayed)
        }
      }
      return recruitmentDetail;
    }

    async checkCanRecruitArcheryTower(recruitmentDetail: RecruitmentDetail, profileDetail: ProfileDetail, mainbarDetail: MainbarDetail)
    {
      if (recruitmentDetail != undefined)
      {
        recruitmentDetail.slingers.sourcesCanRecruit.max = 1;
        recruitmentDetail.slingers.sourcesDisplayed = this.villageHelper.setRecruitmentSourcesDisplayed(recruitmentDetail.slingers.sources, recruitmentDetail.slingers.quantity);
        recruitmentDetail.archer.sourcesCanRecruit.max = 1;
        recruitmentDetail.archer.sourcesDisplayed = this.villageHelper.setRecruitmentSourcesDisplayed(recruitmentDetail.archer.sources, recruitmentDetail.archer.quantity);
        recruitmentDetail.crossbowman.sourcesCanRecruit.max = 1;
        recruitmentDetail.crossbowman.sourcesDisplayed = this.villageHelper.setRecruitmentSourcesDisplayed(recruitmentDetail.crossbowman.sources, recruitmentDetail.crossbowman.quantity);
        recruitmentDetail.grenadier.sourcesCanRecruit.max = 1;
        recruitmentDetail.grenadier.sourcesDisplayed = this.villageHelper.setRecruitmentSourcesDisplayed(recruitmentDetail.grenadier.sources, recruitmentDetail.grenadier.quantity);
        recruitmentDetail.marksman.sourcesCanRecruit.max = 1;
        recruitmentDetail.marksman.sourcesDisplayed = this.villageHelper.setRecruitmentSourcesDisplayed(recruitmentDetail.marksman.sources, recruitmentDetail.marksman.quantity);
        var sourcesSummary = this.villageHelper.setSourcesSummaryArcheryTower(recruitmentDetail, profileDetail);
        if (mainbarDetail?.villageSources != undefined)
        {
            var actualMainbarDetail = this.sourcesHelper.getActualMainbarSourcesDemand(mainbarDetail);
            recruitmentDetail.slingers.sourcesCanRecruit = this.villageHelper.canRecruitmentIncrease(actualMainbarDetail.villageSources.sourcesDisplayed, sourcesSummary, recruitmentDetail.slingers.sources, recruitmentDetail.slingers.sourcesDisplayed)
            recruitmentDetail.archer.sourcesCanRecruit = this.villageHelper.canRecruitmentIncrease(actualMainbarDetail.villageSources.sourcesDisplayed, sourcesSummary, recruitmentDetail.archer.sources, recruitmentDetail.archer.sourcesDisplayed)
            recruitmentDetail.crossbowman.sourcesCanRecruit = this.villageHelper.canRecruitmentIncrease(actualMainbarDetail.villageSources.sourcesDisplayed, sourcesSummary, recruitmentDetail.crossbowman.sources, recruitmentDetail.crossbowman.sourcesDisplayed)
            recruitmentDetail.grenadier.sourcesCanRecruit = this.villageHelper.canRecruitmentIncrease(actualMainbarDetail.villageSources.sourcesDisplayed, sourcesSummary, recruitmentDetail.grenadier.sources, recruitmentDetail.grenadier.sourcesDisplayed)
            recruitmentDetail.marksman.sourcesCanRecruit = this.villageHelper.canRecruitmentIncrease(actualMainbarDetail.villageSources.sourcesDisplayed, sourcesSummary, recruitmentDetail.marksman.sources, recruitmentDetail.marksman.sourcesDisplayed)
        }
      }
      return recruitmentDetail;
    }

    async checkCanRecruitCatapultTower(recruitmentDetail: RecruitmentDetail, profileDetail: ProfileDetail, mainbarDetail: MainbarDetail)
    {
      if (recruitmentDetail != undefined)
      {
        recruitmentDetail.catapult.sourcesCanRecruit.max = 1;
        recruitmentDetail.catapult.sourcesDisplayed = this.villageHelper.setRecruitmentSourcesDisplayed(recruitmentDetail.catapult.sources, recruitmentDetail.catapult.quantity);
        recruitmentDetail.ballista.sourcesCanRecruit.max = 1;
        recruitmentDetail.ballista.sourcesDisplayed = this.villageHelper.setRecruitmentSourcesDisplayed(recruitmentDetail.ballista.sources, recruitmentDetail.ballista.quantity);
        recruitmentDetail.cannon.sourcesCanRecruit.max = 1;
        recruitmentDetail.cannon.sourcesDisplayed = this.villageHelper.setRecruitmentSourcesDisplayed(recruitmentDetail.cannon.sources, recruitmentDetail.cannon.quantity);
        recruitmentDetail.trebuchet.sourcesCanRecruit.max = 1;
        recruitmentDetail.trebuchet.sourcesDisplayed = this.villageHelper.setRecruitmentSourcesDisplayed(recruitmentDetail.trebuchet.sources, recruitmentDetail.trebuchet.quantity);
        recruitmentDetail.gun.sourcesCanRecruit.max = 1;
        recruitmentDetail.gun.sourcesDisplayed = this.villageHelper.setRecruitmentSourcesDisplayed(recruitmentDetail.gun.sources, recruitmentDetail.gun.quantity);
        recruitmentDetail.mitrailleuse.sourcesCanRecruit.max = 1;
        recruitmentDetail.mitrailleuse.sourcesDisplayed = this.villageHelper.setRecruitmentSourcesDisplayed(recruitmentDetail.mitrailleuse.sources, recruitmentDetail.mitrailleuse.quantity);
        var sourcesSummary = this.villageHelper.setSourcesSummaryCatapultTower(recruitmentDetail, profileDetail);
        if (mainbarDetail?.villageSources != undefined)
        {
            var actualMainbarDetail = this.sourcesHelper.getActualMainbarSourcesDemand(mainbarDetail);
            recruitmentDetail.catapult.sourcesCanRecruit = this.villageHelper.canRecruitmentIncrease(actualMainbarDetail.villageSources.sourcesDisplayed, sourcesSummary, recruitmentDetail.catapult.sources, recruitmentDetail.catapult.sourcesDisplayed)
            recruitmentDetail.ballista.sourcesCanRecruit = this.villageHelper.canRecruitmentIncrease(actualMainbarDetail.villageSources.sourcesDisplayed, sourcesSummary, recruitmentDetail.ballista.sources, recruitmentDetail.ballista.sourcesDisplayed)
            recruitmentDetail.cannon.sourcesCanRecruit = this.villageHelper.canRecruitmentIncrease(actualMainbarDetail.villageSources.sourcesDisplayed, sourcesSummary, recruitmentDetail.cannon.sources, recruitmentDetail.cannon.sourcesDisplayed)
            recruitmentDetail.trebuchet.sourcesCanRecruit = this.villageHelper.canRecruitmentIncrease(actualMainbarDetail.villageSources.sourcesDisplayed, sourcesSummary, recruitmentDetail.trebuchet.sources, recruitmentDetail.trebuchet.sourcesDisplayed)
            recruitmentDetail.gun.sourcesCanRecruit = this.villageHelper.canRecruitmentIncrease(actualMainbarDetail.villageSources.sourcesDisplayed, sourcesSummary, recruitmentDetail.gun.sources, recruitmentDetail.gun.sourcesDisplayed)
            recruitmentDetail.mitrailleuse.sourcesCanRecruit = this.villageHelper.canRecruitmentIncrease(actualMainbarDetail.villageSources.sourcesDisplayed, sourcesSummary, recruitmentDetail.mitrailleuse.sources, recruitmentDetail.mitrailleuse.sourcesDisplayed)
        }
      }
      return recruitmentDetail;
    }

    async checkCanTrainCaptain(captianDetail: CaptainDetail, villageDetail: VillageDetail, mainbarDetail: MainbarDetail)
    {
      if (captianDetail != undefined)
      {
        var actualMainbarDetail = this.sourcesHelper.getActualMainbarSourcesDemand(mainbarDetail);
        var captian1Sources = captianDetail.captainSchema.captainList.filter(x=>x.level == villageDetail.captains.captain1.level)[0];
        captianDetail.captain1CanRecruit = this.villageHelper.canTrainCaptain(actualMainbarDetail.villageSources.sourcesDisplayed, captian1Sources.sources);
        var captian2Sources = captianDetail.captainSchema.captainList.filter(x=>x.level == villageDetail.captains.captain2.level)[0];
        captianDetail.captain2CanRecruit = this.villageHelper.canTrainCaptain(actualMainbarDetail.villageSources.sourcesDisplayed, captian2Sources.sources);
        var captian3Sources = captianDetail.captainSchema.captainList.filter(x=>x.level == villageDetail.captains.captain3.level)[0];
        captianDetail.captain3CanRecruit = this.villageHelper.canTrainCaptain(actualMainbarDetail.villageSources.sourcesDisplayed, captian3Sources.sources);
      }
      return captianDetail;
    }

    async checkVillageListDetailSources(villageList: VillageList, mainbarDetail: MainbarDetail)
    {
      if (villageList != undefined && mainbarDetail != undefined)
      {
        if (mainbarDetail?.villageSources != undefined)
        {
          mainbarDetail = this.getActualMainbarSourcesDemand(mainbarDetail);
          mainbarDetail = this.getActualSpace(mainbarDetail);
        }
      }
      villageList.items[0] = mainbarDetail;
      return villageList;
    }

    async checkMainbarDetailSources(currentUrl: string)
    {
      return this.sourcesHelper.checkMainbarDetailSources(currentUrl);
    }

    async checkTutorialDetail(profileDetail: ProfileDetail)
    {
      return this.sourcesHelper.checkTutorialDetail(profileDetail);
    }

    getActualMainbarSourcesDemand(mainbarDetail: MainbarDetail)
    {
        var timeSpan = this.getTimeSpan(mainbarDetail.villageSources.lastUpdateDate);
        mainbarDetail = this.getActuallSource(mainbarDetail, timeSpan);
        return mainbarDetail;
    }

    getActuallSource(mainbarDetail: MainbarDetail, timeSpan: number)
    {
      mainbarDetail.sourcesAreMax = new SourcesAreMax();
      var woodSources = mainbarDetail.villageSources.sources.wood + Math.floor(timeSpan * mainbarDetail.buildingProduction.forest.production/3600);
      if (woodSources > mainbarDetail.maxSources)
      {
        mainbarDetail.villageSources.sourcesDisplayed.wood = mainbarDetail.maxSources;
        mainbarDetail.sourcesAreMax.wood = true;
      }
      else
      {
        mainbarDetail.villageSources.sourcesDisplayed.wood = woodSources;
        mainbarDetail.sourcesAreMax.wood = false;
      }

      var bricksSources = mainbarDetail.villageSources.sources.bricks + Math.floor(timeSpan * mainbarDetail.buildingProduction.brickYard.production/3600);
      if (bricksSources > mainbarDetail.maxSources)
      {
        mainbarDetail.villageSources.sourcesDisplayed.bricks = mainbarDetail.maxSources;
        mainbarDetail.sourcesAreMax.bricks = true;
      }
      else
      {
        mainbarDetail.villageSources.sourcesDisplayed.bricks = bricksSources;
        mainbarDetail.sourcesAreMax.bricks = false;
      }

      var stoneSources = mainbarDetail.villageSources.sources.stone + Math.floor(timeSpan * mainbarDetail.buildingProduction.quarry.production/3600);
      if (stoneSources > mainbarDetail.maxSources)
      {
        mainbarDetail.villageSources.sourcesDisplayed.stone = mainbarDetail.maxSources;
        mainbarDetail.sourcesAreMax.stone = true;
      }
      else
      {
        mainbarDetail.villageSources.sourcesDisplayed.stone = stoneSources;
        mainbarDetail.sourcesAreMax.stone = false;
      }

      var grainSources = mainbarDetail.villageSources.sources.grain + Math.floor(timeSpan * mainbarDetail.buildingProduction.field.production/3600);
      if (grainSources > mainbarDetail.maxSources)
      {
        mainbarDetail.villageSources.sourcesDisplayed.grain = mainbarDetail.maxSources;
        mainbarDetail.sourcesAreMax.grain = true;
      }
      else
      {
        mainbarDetail.villageSources.sourcesDisplayed.grain = grainSources;
        mainbarDetail.sourcesAreMax.grain = false;
      }

      mainbarDetail.villageSources.sourcesDisplayed.corn = mainbarDetail.villageSources.sources.corn;
      mainbarDetail.villageSources.sourcesDisplayed.cows = mainbarDetail.villageSources.sources.cows;
      mainbarDetail.villageSources.sourcesDisplayed.rice = mainbarDetail.villageSources.sources.rice;
      mainbarDetail.villageSources.sourcesDisplayed.sheeps = mainbarDetail.villageSources.sources.sheeps;
      mainbarDetail.villageSources.sourcesDisplayed.coal = mainbarDetail.villageSources.sources.coal;
      mainbarDetail.villageSources.sourcesDisplayed.iron = mainbarDetail.villageSources.sources.iron;
      mainbarDetail.villageSources.sourcesDisplayed.silver = mainbarDetail.villageSources.sources.silver;
      mainbarDetail.villageSources.sourcesDisplayed.gold = mainbarDetail.villageSources.sources.gold;

       return mainbarDetail;
    }

    getActualSpace(mainbarDetail: MainbarDetail)
    {
       var soldiersHousesSpace = mainbarDetail.soldiersHousesSpace.space;
       if (soldiersHousesSpace > mainbarDetail.soldiersHousesSpace.maxSpace)
       {
        mainbarDetail.soldiersHousesSpace.space = mainbarDetail.soldiersHousesSpace.maxSpace
        mainbarDetail.soldiersHousesSpace.spaceIsMax = true;
       }
       else mainbarDetail.soldiersHousesSpace.spaceIsMax = false;

       var housesSpace = mainbarDetail.housesSpace.space;
       if (housesSpace > mainbarDetail.housesSpace.maxSpace)
       {
        mainbarDetail.housesSpace.space = mainbarDetail.housesSpace.maxSpace
        mainbarDetail.housesSpace.spaceIsMax = true;
       }
       else mainbarDetail.housesSpace.spaceIsMax = false;
       return mainbarDetail;
    }

    getActualHousesSpace(mainbarDetail: MainbarDetail)
    {
       var space = mainbarDetail.housesSpace.space;
       if (space > mainbarDetail.housesSpace.maxSpace)
       {
        space = mainbarDetail.housesSpace.maxSpace
       }
       return space;
    }

    // getTimeSpan(lastUpdateDate: Date)
    // {
    //   var currentDate = new Date();
    //   var currentDateUtc = new Date(currentDate.getUTCFullYear(),currentDate.getUTCMonth(),currentDate.getUTCDate(),currentDate.getUTCHours(),currentDate.getUTCMinutes(),currentDate.getUTCSeconds());
    //   var dateTimeNow: number = currentDateUtc.valueOf()/1000;
    //   const formattedDate = new Date(Date.parse(lastUpdateDate.toString()));
    //   var lastUpdate: number = formattedDate.valueOf()/1000;
    //   var timeSpan = dateTimeNow - lastUpdate;
    //   return timeSpan;
    // }

    getTimeSpan(lastUpdateDate: Date)
    {
      return this.sourcesHelper.getTimeSpan(lastUpdateDate);
      // if (this.mainbarDetail != undefined)
      // {
      //   //var currentDate = new Date();
      //   //var currentDateUtc = new Date(currentDate.getUTCFullYear(),currentDate.getUTCMonth(),currentDate.getUTCDate(),currentDate.getUTCHours(),currentDate.getUTCMinutes(),currentDate.getUTCSeconds());
      //   if (this.mainbarDetail.frontendDate == undefined) this.mainbarDetail.frontendDate = this.mainbarDetail.serverDate;
      //   const result = new Date(this.mainbarDetail.frontendDate);
      //   result.setSeconds(1 + result.getSeconds());
      //   var dateTimeNow: number =  this.mainbarDetail?.frontendDate.valueOf()/1000;
      //   const formattedDate = new Date(Date.parse(lastUpdateDate.toString()));
      //   var lastUpdate: number = formattedDate.valueOf()/1000;
      //   var timeSpan = dateTimeNow - lastUpdate;
      //   return timeSpan;
      // }
    }

    canSourceIncrease(sourcesDisplayed: SourcesDemand, sources: SourcesDemand, workers: number, neededWorkers: number)
    {
      var sourcesCanIncrease = new SourcesCanIncrease();
      sourcesCanIncrease.wood = sourcesDisplayed.wood > sources.wood;
      sourcesCanIncrease.bricks = sourcesDisplayed.bricks > sources.bricks;
      sourcesCanIncrease.stone = sourcesDisplayed.stone > sources.stone;
      sourcesCanIncrease.grain = sourcesDisplayed.grain > sources.grain;
      sourcesCanIncrease.workers = workers >= neededWorkers;
      if (sourcesCanIncrease.wood && sourcesCanIncrease.bricks && sourcesCanIncrease.stone && sourcesCanIncrease.grain && sourcesCanIncrease.workers)
      {
        sourcesCanIncrease.nextLevel = true;
      }
      return sourcesCanIncrease;
    }
}