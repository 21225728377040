import { Component, OnInit } from '@angular/core';
//import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
// import { first } from 'rxjs/operators';

// import { AccountService, AlertService } from '@app/_services';

@Component({ templateUrl: 'login.component.html',
             styleUrls: ['login.component.css'] })
export class LoginComponent implements OnInit {
    form: FormGroup;
    loading = false;
    submitted = false;

    constructor(
        private formBuilder: FormBuilder,
        private translate: TranslateService
        //private route: ActivatedRoute,
        // private router: Router,
        // private accountService: AccountService,
        // private alertService: AlertService
    ) {
        this.translate.setDefaultLang('pl');
     }

    ngOnInit() {
        this.form = this.formBuilder.group({
            username: ['', Validators.required],
            password: ['', Validators.required]
        });
    }

    // // convenience getter for easy access to form fields
    // get f() { return this.form.controls; }

    // onSubmit() {
    //     this.submitted = true;

    //     // reset alerts on submit
    //     this.alertService.clear();

    //     // stop here if form is invalid
    //     if (this.form.invalid) {
    //         return;
    //     }

    //     this.loading = true;
    //     this.accountService.login(this.f.username.value, this.f.password.value)
    //         .pipe(first())
    //         .subscribe({
    //             next: () => {
    //                 // get return url from query parameters or default to home page
    //                 //const returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/maps/map';
    //                 //this.router.navigateByUrl(returnUrl);
    //             },
    //             error: error => {
    //                 this.alertService.error(error);
    //                 this.loading = false;
    //             }
    //         });
    // }
}