import { TechnologyCanIncreaseLevel1 } from "../profile/technologyCanIncreaseLevel1";
import { TechnologyCanIncreaseLevel2 } from "../profile/technologyCanIncreaseLevel2";
import { TechnologyCanIncreaseLevel3 } from "../profile/technologyCanIncreaseLevel3";
import { TechnologyCanIncreaseLevel4 } from "../profile/technologyCanIncreaseLevel4";

export class TechnologyCanIncrease {
    level1: TechnologyCanIncreaseLevel1;
    level2: TechnologyCanIncreaseLevel2;
    level3: TechnologyCanIncreaseLevel3;
    level4: TechnologyCanIncreaseLevel4;
}