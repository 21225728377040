import { Coords } from "../maps/coords";
import { SourcesDetail } from "./sourcesDetail";

export class AddSourcesModel {
    wood: SourcesDetail;
    bricks: SourcesDetail; 
    stone: SourcesDetail; 
    grain: SourcesDetail; 
    corn: SourcesDetail; 
    cows: SourcesDetail; 
    coal: SourcesDetail; 
    rice: SourcesDetail; 
    iron: SourcesDetail; 
    silver: SourcesDetail; 
    sheeps: SourcesDetail;
    gold: SourcesDetail; 
}