import { Coords } from "../maps/coords";

export class CommandVillage {
    id: string;
    commandId: string;
    owner: string;
    coords: Coords;
    buildingType: string;
    buildingTypeName: string;
    buildingLevel: number;
    buildingImageUrl: string;
    constructionTime: number;
    constructionStart: Date;
    constructionTimeLeft: number;
    nrOfWorkers: number;
    isActive: boolean;
    canBeFinished: boolean;
}