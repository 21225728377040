export class SourcesDemand {
    wood: number; //wood
    bricks: number; //bricks
    stone: number; //stone
    grain: number; //grain
    corn: number; //corn
    cows: number; //cows
    rice: number; //rice
    sheeps: number; //sheeps
    coal: number; //coal
    iron: number; //iron
    silver: number; //silver
    gold: number; //gold
}