import { Component, Injectable } from '@angular/core';
import { TechnologyLevel } from '@app/_models';
import { TechnologyLevel1 } from '@app/_models/profile/technologyLevel1';
import { TechnologyLevel2 } from '@app/_models/profile/technologyLevel2';
import { TechnologyLevel3 } from '@app/_models/profile/technologyLevel3';
import { TechnologyLevel4 } from '@app/_models/profile/technologyLevel4';
import { SourcesCanIncrease } from '@app/_models/townHall/sourcesCanIncrease';
import { SourcesDemand } from '@app/_models/village/sourcesDemand';
import { TechnologyCanIncreaseLevel1 } from '../_models/profile/technologyCanIncreaseLevel1';
import { TechnologyCanIncreaseLevel2 } from '../_models/profile/technologyCanIncreaseLevel2';
import { TechnologyCanIncreaseLevel3 } from '../_models/profile/technologyCanIncreaseLevel3';
import { TechnologyCanIncreaseLevel4 } from '../_models/profile/technologyCanIncreaseLevel4';
import { TechnologySchema } from '../_models/profile/technologySchema';
import { MainbarDetail } from '../_models/village/mainbarDetail';


@Injectable({ providedIn: 'root' })
export class TechnologyHelper {
    constructor(
        
    ) {}

    async checkTechnologyCanIncreaseLevel1(actualMainbarDetail: MainbarDetail, technologySchema: TechnologySchema, availableWorkers: number, technologyLevel1: TechnologyLevel1)
    {
        var technologyCanIncrease = new TechnologyCanIncreaseLevel1();
        if (actualMainbarDetail.villageSources != undefined)
        {
          technologyCanIncrease.slingers = this.canSourceIncrease(actualMainbarDetail.villageSources.sourcesDisplayed, technologySchema.level1.slingers.sources, availableWorkers, technologySchema.level1.slingers.workers, technologyLevel1.slingers);  
          technologyCanIncrease.explorer = this.canSourceIncrease(actualMainbarDetail.villageSources.sourcesDisplayed, technologySchema.level1.explorer.sources, availableWorkers, technologySchema.level1.explorer.workers, technologyLevel1.explorer); 
          technologyCanIncrease.axemen = this.canSourceIncrease(actualMainbarDetail.villageSources.sourcesDisplayed, technologySchema.level1.axemen.sources, availableWorkers, technologySchema.level1.axemen.workers, technologyLevel1.axemen); 
          technologyCanIncrease.bludgeonWarrior = this.canSourceIncrease(actualMainbarDetail.villageSources.sourcesDisplayed, technologySchema.level1.bludgeonWarrior.sources, availableWorkers, technologySchema.level1.bludgeonWarrior.workers, technologyLevel1.bludgeonWarrior); 
          technologyCanIncrease.cataphract = this.canSourceIncrease(actualMainbarDetail.villageSources.sourcesDisplayed, technologySchema.level1.cataphract.sources, availableWorkers, technologySchema.level1.cataphract.workers, technologyLevel1.cataphract); 
          technologyCanIncrease.lancer = this.canSourceIncrease(actualMainbarDetail.villageSources.sourcesDisplayed, technologySchema.level1.lancer.sources, availableWorkers, technologySchema.level1.lancer.workers, technologyLevel1.lancer); 
          technologyCanIncrease.scout = this.canSourceIncrease(actualMainbarDetail.villageSources.sourcesDisplayed, technologySchema.level1.scout.sources, availableWorkers, technologySchema.level1.scout.workers, technologyLevel1.scout); 
          technologyCanIncrease.catapult = this.canSourceIncrease(actualMainbarDetail.villageSources.sourcesDisplayed, technologySchema.level1.catapult.sources, availableWorkers, technologySchema.level1.catapult.workers, technologyLevel1.catapult);
          technologyCanIncrease.path = this.canSourceIncrease(actualMainbarDetail.villageSources.sourcesDisplayed, technologySchema.level1.path.sources, availableWorkers, technologySchema.level1.path.workers, technologyLevel1.path);   
          technologyCanIncrease.wheatFarm = this.canSourceIncrease(actualMainbarDetail.villageSources.sourcesDisplayed, technologySchema.level1.wheatFarm.sources, availableWorkers, technologySchema.level1.wheatFarm.workers, technologyLevel1.wheatFarm);   
          technologyCanIncrease.clayMine = this.canSourceIncrease(actualMainbarDetail.villageSources.sourcesDisplayed, technologySchema.level1.clayMine.sources, availableWorkers, technologySchema.level1.clayMine.workers, technologyLevel1.clayMine);   
          technologyCanIncrease.sawmill = this.canSourceIncrease(actualMainbarDetail.villageSources.sourcesDisplayed, technologySchema.level1.sawmill.sources, availableWorkers, technologySchema.level1.sawmill.workers, technologyLevel1.sawmill);   
          technologyCanIncrease.quarry = this.canSourceIncrease(actualMainbarDetail.villageSources.sourcesDisplayed, technologySchema.level1.quarry.sources, availableWorkers, technologySchema.level1.quarry.workers, technologyLevel1.quarry);   
          technologyCanIncrease.findingFarmsLevel1 = this.canSourceIncrease(actualMainbarDetail.villageSources.sourcesDisplayed, technologySchema.level1.findingFarmsLevel1.sources, availableWorkers, technologySchema.level1.findingFarmsLevel1.workers, technologyLevel1.findingFarmsLevel1);   
          technologyCanIncrease.findingMinesLevel1 = this.canSourceIncrease(actualMainbarDetail.villageSources.sourcesDisplayed, technologySchema.level1.findingMinesLevel1.sources, availableWorkers, technologySchema.level1.findingMinesLevel1.workers, technologyLevel1.findingMinesLevel1);   
          technologyCanIncrease.finding2Fields = this.canSourceIncrease(actualMainbarDetail.villageSources.sourcesDisplayed, technologySchema.level1.finding2Fields.sources, availableWorkers, technologySchema.level1.finding2Fields.workers, technologyLevel1.finding2Fields);   
          technologyCanIncrease.fortress = this.canSourceIncrease(actualMainbarDetail.villageSources.sourcesDisplayed, technologySchema.level1.fortress.sources, availableWorkers, technologySchema.level1.fortress.workers, technologyLevel1.fortress);   
          technologyCanIncrease.atakOnWorkers = this.canSourceIncrease(actualMainbarDetail.villageSources.sourcesDisplayed, technologySchema.level1.atakOnWorkers.sources, availableWorkers, technologySchema.level1.atakOnWorkers.workers, technologyLevel1.atakOnWorkers);   
          technologyCanIncrease.chaseAwayWorkers = this.canSourceIncrease(actualMainbarDetail.villageSources.sourcesDisplayed, technologySchema.level1.chaseAwayWorkers.sources, availableWorkers, technologySchema.level1.chaseAwayWorkers.workers, technologyLevel1.chaseAwayWorkers);   
          technologyCanIncrease.irrigation = this.canSourceIncrease(actualMainbarDetail.villageSources.sourcesDisplayed, technologySchema.level1.irrigation.sources, availableWorkers, technologySchema.level1.irrigation.workers, technologyLevel1.irrigation);   
          technologyCanIncrease.trolleys = this.canSourceIncrease(actualMainbarDetail.villageSources.sourcesDisplayed, technologySchema.level1.trolleys.sources, availableWorkers, technologySchema.level1.trolleys.workers, technologyLevel1.trolleys);     
        }
        return technologyCanIncrease;
    }

    async checkTechnologyCanIncreaseLevel2(actualMainbarDetail: MainbarDetail, technologySchema: TechnologySchema, availableWorkers: number, technologyLevel2: TechnologyLevel2)
    {
        var technologyCanIncrease = new TechnologyCanIncreaseLevel2();
        if (actualMainbarDetail.villageSources != undefined)
        {
          technologyCanIncrease.archer = this.canSourceIncrease(actualMainbarDetail.villageSources.sourcesDisplayed, technologySchema.level2.archer.sources, availableWorkers, technologySchema.level2.archer.workers, technologyLevel2.archer);  
          technologyCanIncrease.crossbowman = this.canSourceIncrease(actualMainbarDetail.villageSources.sourcesDisplayed, technologySchema.level2.crossbowman.sources, availableWorkers, technologySchema.level2.crossbowman.workers, technologyLevel2.crossbowman); 
          technologyCanIncrease.pikemen = this.canSourceIncrease(actualMainbarDetail.villageSources.sourcesDisplayed, technologySchema.level2.pikemen.sources, availableWorkers, technologySchema.level2.pikemen.workers, technologyLevel2.pikemen); 
          technologyCanIncrease.swordsman = this.canSourceIncrease(actualMainbarDetail.villageSources.sourcesDisplayed, technologySchema.level2.swordsman.sources, availableWorkers, technologySchema.level2.swordsman.workers, technologyLevel2.swordsman); 
          technologyCanIncrease.knight = this.canSourceIncrease(actualMainbarDetail.villageSources.sourcesDisplayed, technologySchema.level2.knight.sources, availableWorkers, technologySchema.level2.knight.workers, technologyLevel2.knight); 
          technologyCanIncrease.hussars = this.canSourceIncrease(actualMainbarDetail.villageSources.sourcesDisplayed, technologySchema.level2.hussars.sources, availableWorkers, technologySchema.level2.hussars.workers, technologyLevel2.hussars); 
          technologyCanIncrease.ballista = this.canSourceIncrease(actualMainbarDetail.villageSources.sourcesDisplayed, technologySchema.level2.ballista.sources, availableWorkers, technologySchema.level2.ballista.workers, technologyLevel2.ballista); 
          technologyCanIncrease.findingFarmsLevel2 = this.canSourceIncrease(actualMainbarDetail.villageSources.sourcesDisplayed, technologySchema.level2.findingFarmsLevel2.sources, availableWorkers, technologySchema.level2.findingFarmsLevel2.workers, technologyLevel2.findingFarmsLevel2);
          technologyCanIncrease.findingMinesLevel2 = this.canSourceIncrease(actualMainbarDetail.villageSources.sourcesDisplayed, technologySchema.level2.findingMinesLevel2.sources, availableWorkers, technologySchema.level2.findingMinesLevel2.workers, technologyLevel2.findingMinesLevel2);   
          technologyCanIncrease.finding4Fields = this.canSourceIncrease(actualMainbarDetail.villageSources.sourcesDisplayed, technologySchema.level2.finding4Fields.sources, availableWorkers, technologySchema.level2.finding4Fields.workers, technologyLevel2.finding4Fields);   
          technologyCanIncrease.road = this.canSourceIncrease(actualMainbarDetail.villageSources.sourcesDisplayed, technologySchema.level2.road.sources, availableWorkers, technologySchema.level2.road.workers, technologyLevel2.road);   
          technologyCanIncrease.bridge = this.canSourceIncrease(actualMainbarDetail.villageSources.sourcesDisplayed, technologySchema.level2.bridge.sources, availableWorkers, technologySchema.level2.bridge.workers, technologyLevel2.bridge);   
          technologyCanIncrease.cornFarm = this.canSourceIncrease(actualMainbarDetail.villageSources.sourcesDisplayed, technologySchema.level2.cornFarm.sources, availableWorkers, technologySchema.level2.cornFarm.workers, technologyLevel2.cornFarm);   
          technologyCanIncrease.cowsFarm = this.canSourceIncrease(actualMainbarDetail.villageSources.sourcesDisplayed, technologySchema.level2.cowsFarm.sources, availableWorkers, technologySchema.level2.cowsFarm.workers, technologyLevel2.cowsFarm);   
          technologyCanIncrease.coalMine = this.canSourceIncrease(actualMainbarDetail.villageSources.sourcesDisplayed, technologySchema.level2.coalMine.sources, availableWorkers, technologySchema.level2.coalMine.workers, technologyLevel2.coalMine);   
          technologyCanIncrease.shepherdsHut = this.canSourceIncrease(actualMainbarDetail.villageSources.sourcesDisplayed, technologySchema.level2.shepherdsHut.sources, availableWorkers, technologySchema.level2.shepherdsHut.workers, technologyLevel2.shepherdsHut);   
          technologyCanIncrease.mules = this.canSourceIncrease(actualMainbarDetail.villageSources.sourcesDisplayed, technologySchema.level2.mules.sources, availableWorkers, technologySchema.level2.mules.workers, technologyLevel2.mules);          
        }
        return technologyCanIncrease;
    }

    async checkTechnologyCanIncreaseLevel3(actualMainbarDetail: MainbarDetail, technologySchema: TechnologySchema, availableWorkers: number, technologyLevel3: TechnologyLevel3)
    {
        var technologyCanIncrease = new TechnologyCanIncreaseLevel3();
        if (actualMainbarDetail.villageSources != undefined)
        {
          technologyCanIncrease.cannon = this.canSourceIncrease(actualMainbarDetail.villageSources.sourcesDisplayed, technologySchema.level3.cannon.sources, availableWorkers, technologySchema.level3.cannon.workers, technologyLevel3.cannon);  
          technologyCanIncrease.scythe = this.canSourceIncrease(actualMainbarDetail.villageSources.sourcesDisplayed, technologySchema.level3.scythe.sources, availableWorkers, technologySchema.level3.scythe.workers, technologyLevel3.scythe); 
          technologyCanIncrease.grenadier = this.canSourceIncrease(actualMainbarDetail.villageSources.sourcesDisplayed, technologySchema.level3.grenadier.sources, availableWorkers, technologySchema.level3.grenadier.workers, technologyLevel3.grenadier); 
          technologyCanIncrease.dragons = this.canSourceIncrease(actualMainbarDetail.villageSources.sourcesDisplayed, technologySchema.level3.dragons.sources, availableWorkers, technologySchema.level3.dragons.workers, technologyLevel3.dragons); 
          technologyCanIncrease.wingedHussars = this.canSourceIncrease(actualMainbarDetail.villageSources.sourcesDisplayed, technologySchema.level3.wingedHussars.sources, availableWorkers, technologySchema.level3.wingedHussars.workers, technologyLevel3.wingedHussars); 
          technologyCanIncrease.trebuchet = this.canSourceIncrease(actualMainbarDetail.villageSources.sourcesDisplayed, technologySchema.level3.trebuchet.sources, availableWorkers, technologySchema.level3.trebuchet.workers, technologyLevel3.trebuchet); 
          technologyCanIncrease.riceFarm = this.canSourceIncrease(actualMainbarDetail.villageSources.sourcesDisplayed, technologySchema.level3.riceFarm.sources, availableWorkers, technologySchema.level3.riceFarm.workers, technologyLevel3.riceFarm); 
          technologyCanIncrease.ironMine = this.canSourceIncrease(actualMainbarDetail.villageSources.sourcesDisplayed, technologySchema.level3.ironMine.sources, availableWorkers, technologySchema.level3.ironMine.workers, technologyLevel3.ironMine);
          technologyCanIncrease.silverMine = this.canSourceIncrease(actualMainbarDetail.villageSources.sourcesDisplayed, technologySchema.level3.silverMine.sources, availableWorkers, technologySchema.level3.silverMine.workers, technologyLevel3.silverMine);   
          technologyCanIncrease.findingFarmsLevel3 = this.canSourceIncrease(actualMainbarDetail.villageSources.sourcesDisplayed, technologySchema.level3.findingFarmsLevel3.sources, availableWorkers, technologySchema.level3.findingFarmsLevel3.workers, technologyLevel3.findingFarmsLevel3);   
          technologyCanIncrease.findingMinesLevel3 = this.canSourceIncrease(actualMainbarDetail.villageSources.sourcesDisplayed, technologySchema.level3.findingMinesLevel3.sources, availableWorkers, technologySchema.level3.findingMinesLevel3.workers, technologyLevel3.findingMinesLevel3);   
          technologyCanIncrease.finding9Fieilds = this.canSourceIncrease(actualMainbarDetail.villageSources.sourcesDisplayed, technologySchema.level3.finding9Fields.sources, availableWorkers, technologySchema.level3.finding9Fields.workers, technologyLevel3.finding9Fields);   
          technologyCanIncrease.railway = this.canSourceIncrease(actualMainbarDetail.villageSources.sourcesDisplayed, technologySchema.level3.railway.sources, availableWorkers, technologySchema.level3.railway.workers, technologyLevel3.railway);   
          technologyCanIncrease.tunel = this.canSourceIncrease(actualMainbarDetail.villageSources.sourcesDisplayed, technologySchema.level3.tunel.sources, availableWorkers, technologySchema.level3.tunel.workers, technologyLevel3.tunel);   
          technologyCanIncrease.miningShaft = this.canSourceIncrease(actualMainbarDetail.villageSources.sourcesDisplayed, technologySchema.level3.miningShaft.sources, availableWorkers, technologySchema.level3.miningShaft.workers, technologyLevel3.miningShaft);   
          technologyCanIncrease.horses = this.canSourceIncrease(actualMainbarDetail.villageSources.sourcesDisplayed, technologySchema.level3.horses.sources, availableWorkers, technologySchema.level3.horses.workers, technologyLevel3.horses);         
        }
        return technologyCanIncrease;
    }

    async checkTechnologyCanIncreaseLevel4(actualMainbarDetail: MainbarDetail, technologySchema: TechnologySchema, availableWorkers: number, technologyLevel4: TechnologyLevel4)
    {
        var technologyCanIncrease = new TechnologyCanIncreaseLevel4();
        if (actualMainbarDetail.villageSources != undefined)
        {
          technologyCanIncrease.gun = this.canSourceIncrease(actualMainbarDetail.villageSources.sourcesDisplayed, technologySchema.level4.gun.sources, availableWorkers, technologySchema.level4.gun.workers, technologyLevel4.gun);  
          technologyCanIncrease.marksman = this.canSourceIncrease(actualMainbarDetail.villageSources.sourcesDisplayed, technologySchema.level4.marksman.sources, availableWorkers, technologySchema.level4.marksman.workers, technologyLevel4.marksman); 
          technologyCanIncrease.musketeer = this.canSourceIncrease(actualMainbarDetail.villageSources.sourcesDisplayed, technologySchema.level4.musketeer.sources, availableWorkers, technologySchema.level4.musketeer.workers, technologyLevel4.musketeer); 
          technologyCanIncrease.rajtar = this.canSourceIncrease(actualMainbarDetail.villageSources.sourcesDisplayed, technologySchema.level4.rajtar.sources, availableWorkers, technologySchema.level4.rajtar.workers, technologyLevel4.rajtar); 
          technologyCanIncrease.balloons = this.canSourceIncrease(actualMainbarDetail.villageSources.sourcesDisplayed, technologySchema.level4.balloons.sources, availableWorkers, technologySchema.level4.balloons.workers, technologyLevel4.balloons); 
          technologyCanIncrease.mitrailleuse = this.canSourceIncrease(actualMainbarDetail.villageSources.sourcesDisplayed, technologySchema.level4.mitrailleuse.sources, availableWorkers, technologySchema.level4.mitrailleuse.workers, technologyLevel4.mitrailleuse); 
          technologyCanIncrease.sheepFarm = this.canSourceIncrease(actualMainbarDetail.villageSources.sourcesDisplayed, technologySchema.level4.sheepFarm.sources, availableWorkers, technologySchema.level4.sheepFarm.workers, technologyLevel4.sheepFarm); 
          technologyCanIncrease.goldMine = this.canSourceIncrease(actualMainbarDetail.villageSources.sourcesDisplayed, technologySchema.level4.goldMine.sources, availableWorkers, technologySchema.level4.goldMine.workers, technologyLevel4.goldMine);
          technologyCanIncrease.wagons = this.canSourceIncrease(actualMainbarDetail.villageSources.sourcesDisplayed, technologySchema.level4.wagons.sources, availableWorkers, technologySchema.level4.wagons.workers, technologyLevel4.wagons);             
        }
        return technologyCanIncrease;
    }

    canSourceIncrease(sourcesDisplayed: SourcesDemand, sources: SourcesDemand, workers: number, neededWorkers: number, technologyLevel: TechnologyLevel)
    {
      var sourcesCanIncrease = new SourcesCanIncrease();
      sourcesCanIncrease.wood = sourcesDisplayed.wood > sources.wood;
      sourcesCanIncrease.bricks = sourcesDisplayed.bricks > sources.bricks;
      sourcesCanIncrease.stone = sourcesDisplayed.stone > sources.stone;
      sourcesCanIncrease.grain = sourcesDisplayed.grain > sources.grain;
      sourcesCanIncrease.workers = workers >= neededWorkers;
      if (sourcesCanIncrease.wood && sourcesCanIncrease.bricks && sourcesCanIncrease.stone && sourcesCanIncrease.grain && sourcesCanIncrease.workers && !technologyLevel?.isCompleted)
      {
        sourcesCanIncrease.nextLevel = true;
      }
      return sourcesCanIncrease;
    }
}