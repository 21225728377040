import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { VillageDetail } from '../_models/village/villageDetail';
import { LevelSources } from '../_models/village/levelSources';
import { Captain, CommandRecruitment, IncreaseBuildingResponse, ProfileDetail, TechnologyLevel } from '@app/_models';
import { ImagesHelper } from '@app/_helpers/images.helper';
import { TroopDetail } from '@app/_models/village/troopDetail';
import { VillageTroopDetail } from '@app/_models/village/villageTroopDetail';
import { BuildingImages } from '@app/_models/images/buildingImages';
import { RecruitmentImages } from '@app/_models/images/recruitmentImages';
import { TechnologyImages } from '@app/_models/images/technologyImages';
import { MainbarDetail } from '@app/_models/village/mainbarDetail';
import { SourcesHelper } from '@app/_helpers/sources.helper';
import { environment } from '@environments/environment';
import { MapImages } from '@app/_models/images/mapImages';
import { CaptainSchema } from '@app/_models/village/captainSchema';
import { BuildImprovements } from '@app/_models/maps/buildImprovements';
import { SourcesDemand } from '@app/_models/village/sourcesDemand';
import { SourcesCanBuild } from '@app/_models/maps/sourcesCanBuild';
import { MapSchema } from '@app/_models/maps/mapSchema';
import { MapImprovements } from '@app/_models/maps/mapImprovements';
import { MapSources } from '@app/_models/maps/mapSources';
import { CountCommandTimeResponse } from '@app/_models/townHall/countCommandTimeResponse';
import { CountCommandProfileTimeResponse } from '@app/_models/townHall/countCommandProfileTimeResponse';


@Injectable({ providedIn: 'root' })
export class CommandService {
    constructor(
        private router: Router,
        private http: HttpClient,
        private imagesHelper: ImagesHelper,
        private sourcesHelper: SourcesHelper,
    ) {

    }

    getVillageDetails() {     
      const params = new HttpParams().append('x', '50').append('y', '50');  
      return this.http.get<VillageDetail>(`${environment.dataStorageUrl}/village/GetVillageDetailByCoords`, { params});
    }

    async countCommandTime(villageDetail: VillageDetail, mainbarDetail: MainbarDetail)
    {
        var response = new CountCommandTimeResponse();
        response.villageDetail = villageDetail;
        response.reload = false;
        if (villageDetail != undefined && mainbarDetail != undefined)
        {
           if (villageDetail.commandVillage != undefined && villageDetail.commandVillage[0] != null)
           {
                villageDetail.commandVillage.filter( x => x.isActive == true).forEach((commandItem) => {
                  commandItem.buildingImageUrl = this.getBuildingImageUrl(commandItem.buildingType)
                  commandItem.buildingTypeName = this.sourcesHelper.convertBuildingTypeName(commandItem.buildingType)
                }); 
                var command = villageDetail.commandVillage.filter( x => x.isActive == true)[0];
                var timeSpan = this.getTimeSpan(command.constructionStart);
                command.constructionTimeLeft = command.constructionTime - timeSpan;
                if (command.constructionTimeLeft < 300)
                {
                  command.canBeFinished = true;
                }
                else
                {
                  command.canBeFinished = false;
                }
                if (command.constructionTime <= timeSpan) 
                {
                  var buildingType = this.lowerFirstLetter(command.buildingType);
                  var building = villageDetail.buildings[buildingType] as LevelSources;
                  building.level = command.buildingLevel;
                  let index = villageDetail.commandVillage.findIndex(d => d.commandId === command.commandId);
                  villageDetail.commandVillage.splice(index, 1);
                  villageDetail.workersInUse -= command.nrOfWorkers;
                  response.villageDetail = villageDetail;
                  response.reload = true;
                  return response;
                }
           }
        }
        return response;
    }

    async countRecruitmentCommandTime(villageDetail: VillageDetail, buildingType: string)
    {
          var response = new CountCommandTimeResponse();
          response.villageDetail = villageDetail;
          response.reload = false;
           if (villageDetail != undefined && villageDetail.commandRecruitment != undefined && villageDetail.commandRecruitment[0] != null)
           {
                var commandTypeList = this.getCommandTypeList(buildingType);
                villageDetail.commandRecruitment = villageDetail.commandRecruitment.filter( x => x.isActive === true).filter(x=> commandTypeList.includes(x.recruitmentType))
                villageDetail.commandRecruitment.forEach((commandItem) => {
                  commandItem.recruitmentImageUrl = this.getRecruitmentImageUrl(commandItem.recruitmentType)
                  commandItem.recruitmentTypeName = this.convertRecruitmentTypeName(commandItem.recruitmentType)
                });
                var command = villageDetail.commandRecruitment.filter( x => x.isActive == true)[0];
                var timeSpan = this.getTimeSpan(command.recruitmentStart);
                command.recruitmentTimeLeft = command.recruitmentTime - timeSpan;
                if (command.recruitmentTime <= timeSpan) 
                {
                  if (command.recruitmentType == "Workers")
                  {
                    villageDetail.workers = villageDetail.workers + command.recruitmentQuantity;
                  }
                  else if (command.recruitmentType == "Explorer")
                  {
                    villageDetail.explorer = villageDetail.explorer + command.recruitmentQuantity;
                  }
                  else
                  {
                    var troopType = this.lowerFirstLetter(command.recruitmentType);
                    var troops = villageDetail.troops[troopType] as TroopDetail;
                    troops.troopsQuantity = troops.troopsQuantity + command.recruitmentQuantity;
                  }
                  let index = villageDetail.commandRecruitment.findIndex(d => d.commandId === command.commandId);
                  villageDetail.commandRecruitment.splice(index, 1);
                  response.villageDetail = villageDetail;
                  response.reload = true;
                  return response;
                }
           }
        return response;
    }
    
    async countTechnologyCommandTime(profileDetail: ProfileDetail)
    {
      var response = new CountCommandProfileTimeResponse();
      response.profileDetail = profileDetail;
      response.reload = false;
        if (profileDetail != undefined)
        {
           if (profileDetail.commandTechnology != undefined && profileDetail.commandTechnology[0] != null)
           {
                profileDetail.commandTechnology.filter( x => x.isActive == true).forEach((commandItem) => {
                  commandItem.technologyImageUrl = this.getTechnologyImageUrl(commandItem.technologyType)
                  commandItem.technologyTypeName = this.convertTechnologyTypeName(commandItem.technologyType)
                }); 
                var command = profileDetail.commandTechnology.filter( x => x.isActive == true)[0];
                var timeSpan = this.getTimeSpan(command.technologyStart);
                command.technologyTimeLeft = command.technologyTime - timeSpan;
                if (command.technologyTime <= timeSpan) 
                {
                  var technologyType = this.lowerFirstLetter(command.technologyType);
                  var technology = profileDetail.technology[technologyType] as TechnologyLevel;
                  technology.level = command.technologyLevel;
                  let index = profileDetail.commandTechnology.findIndex(d => d.commandId === command.commandId);
                  profileDetail.commandTechnology.splice(index, 1);
                  response.profileDetail = profileDetail;
                  response.reload = true;
                  return response;
                }
           }
        }
        return response;
    }

    async countSourcesCommandTime(villageDetail: VillageDetail)
    {
        var response = new CountCommandTimeResponse();
        response.villageDetail = villageDetail;
        response.reload = false;
        if (villageDetail != undefined)
        {
           if (villageDetail.commandSources != undefined && villageDetail.commandSources[0] != null)
           {
                var command = villageDetail.commandSources.filter( x => x.isActive == true)[0];
                var timeSpan = this.getTimeSpan(command.sourcesStart);
                command.sourcesTimeLeft = command.sourcesTime - timeSpan;
                if (command.sourcesTime <= timeSpan) 
                {
                  if (command.sourceType = 'Wood')
                  {
                    villageDetail.villageSources.sources.wood = villageDetail.villageSources.sources.wood + command.workers;
                  }
                  if (command.sourceType = 'Bricks')
                  {
                    villageDetail.villageSources.sources.bricks = villageDetail.villageSources.sources.bricks + command.workers;
                  }
                  if (command.sourceType = 'Stone')
                  {
                    villageDetail.villageSources.sources.stone = villageDetail.villageSources.sources.stone + command.workers;
                  }
                  if (command.sourceType = 'Grain')
                  {
                    villageDetail.villageSources.sources.grain = villageDetail.villageSources.sources.grain + command.workers;
                  }
                  let index = villageDetail.commandSources.findIndex(d => d.commandId === command.commandId);
                  villageDetail.commandSources.splice(index, 1);
                  response.villageDetail = villageDetail;
                  response.reload = true;
                  return response;
                }
           }
        }
        return response;
    }

    async countTroopsCommandTime(villageTroopDetail: VillageTroopDetail)
    {
        if (villageTroopDetail != undefined)
        {
           if (villageTroopDetail.commandTroops != undefined && villageTroopDetail.commandTroops[0] != null)
           {
                villageTroopDetail.commandTroops.filter( x => x.isActive == true).forEach((commandItem) => {
                  commandItem.sendTroopsImageUrl = this.getSendTroopsImageUrl(commandItem.sendTroopsType)
                  commandItem.sendTroopsTypeName = this.convertSendTroopsTypeName(commandItem.sendTroopsType)
                  if (commandItem != undefined)
                  {
                    var timeSpan = this.getTimeSpan(commandItem.sendStart);
                    commandItem.sendTimeLeft = commandItem.sendTime - timeSpan;
                    if (commandItem.sendTime <= timeSpan) 
                    {
                      let index = villageTroopDetail.commandTroops.findIndex(d => d.commandId === commandItem.commandId);
                      villageTroopDetail.commandTroops.splice(index, 1);
                      return villageTroopDetail;
                    }
                  } 
                }); 
           }
        }
        return villageTroopDetail;
    }

    async countCombinedCommandTime(villageDetail: VillageDetail)
    {
        var response = new CountCommandTimeResponse();
        response.villageDetail = villageDetail;
        response.reload = false;
        if (villageDetail != undefined)
        {
           if (villageDetail.commandCombined != undefined && villageDetail.commandCombined[0] != null)
           {
            villageDetail.commandCombined.filter( x => x.isActive == true).forEach((commandItem) => {
                  commandItem.commandCombinedImageUrl = this.getCommandCombinedImageUrl(commandItem.commandType);
                  commandItem.commandName = this.getCommandCombinedName(commandItem.commandType, commandItem.commandName);
                  commandItem.commandArrival = this.getCommandCombinedArrival(commandItem.sendWorkersStart, commandItem.commandTime);
                if (commandItem != undefined)
                {
                  var timeSpan = this.getTimeSpan(commandItem.sendWorkersStart);
                  commandItem.commandTimeLeft = commandItem.commandTime - timeSpan;
                  if (commandItem.commandTime <= timeSpan) 
                  {
                    let index = villageDetail.commandCombined.findIndex(d => d.commandId === commandItem.commandId);
                    villageDetail.commandCombined.splice(index, 1);
                    response.villageDetail = villageDetail;
                    response.reload = true;
                    return response;
                  }
                }
              }); 
           }
        }
        return response;
    }

    async countSwitchCommandTime(villageDetail: VillageDetail)
    {
        var response = new CountCommandTimeResponse();
        response.villageDetail = villageDetail;
        response.reload = false;
        if (villageDetail != undefined)
        {
           if (villageDetail.commandSwitchSources != undefined && villageDetail.commandSwitchSources[0] != null)
           {
                var command = villageDetail.commandSwitchSources.filter( x => x.isActive == true)[0];
                var timeSpan = this.getTimeSpan(command.switchSourcesStart);
                command.switchSourcesTimeLeft = command.switchSourcesTime - timeSpan;
                if (command.switchSourcesTime <= timeSpan) 
                {
                  let index = villageDetail.commandSwitchSources.findIndex(d => d.commandId === command.commandId);
                  villageDetail.commandSwitchSources.splice(index, 1);
                  response.villageDetail = villageDetail;
                  response.reload = true;
                  return response;
                }
           }
        }
        return response;
    }

    async countChangeCommandTime(villageDetail: VillageDetail)
    {
        var response = new CountCommandTimeResponse();
        response.villageDetail = villageDetail;
        response.reload = false;
        if (villageDetail != undefined)
        {
           if (villageDetail.commandChangeSources != undefined && villageDetail.commandChangeSources[0] != null)
           {
                var command = villageDetail.commandChangeSources.filter( x => x.isActive == true)[0];
                var timeSpan = this.getTimeSpan(command.changeSourcesStart);
                command.changeSourcesTimeLeft = command.changeSourcesTime - timeSpan;
                if (command.changeSourcesTime <= timeSpan) 
                {
                  let index = villageDetail.commandChangeSources.findIndex(d => d.commandId === command.commandId);
                  villageDetail.commandChangeSources.splice(index, 1);
                  response.villageDetail = villageDetail;
                  response.reload = true;
                  return response;
                }
           }
        }
        return response;
    }

    async countCaptainCommandTime(villageDetail: VillageDetail, captainSchema: CaptainSchema)
    {
        var response = new CountCommandTimeResponse();
        response.villageDetail = villageDetail;
        response.reload = false;
        if (villageDetail != undefined)
        {
           if (villageDetail.commandTrainCaptain != undefined && villageDetail.commandTrainCaptain[0] != null)
           {
                villageDetail.commandTrainCaptain.filter( x => x.isActive == true).forEach((commandItem) => {
                  commandItem.trainCaptainImageUrl = this.getCommandCombinedImageUrl(commandItem.captainNr)
                var timeSpan = this.getTimeSpan(commandItem.trainCaptainStart);
                commandItem.trainCaptainTimeLeft = commandItem.trainCaptainTime - timeSpan;
                if (commandItem.trainCaptainTime <= timeSpan) 
                {
                  var captainNr = this.lowerFirstLetter(commandItem.captainNr);
                  var captain = villageDetail.captains[captainNr] as Captain;
                  captain.status = 'InCastle';
                  if (commandItem.trainCaptainType == "Defence") captain.defence += 1;
                  if (commandItem.trainCaptainType == "Attack") captain.attack += 1;
                  if (commandItem.trainCaptainType == "Speed") captain.speed += 1;
                  if (commandItem.trainCaptainType == "Shooting") captain.shooting += 1;
                  captain.level += 1;
                  var sources = captainSchema.captainList[captain.level].sources;
                  if (commandItem.captainNr == 'Captain1') villageDetail.captains.captain1.sources = sources;
                  if (commandItem.captainNr == 'Captain2') villageDetail.captains.captain2.sources = sources;
                  if (commandItem.captainNr == 'Captain3') villageDetail.captains.captain3.sources = sources;
                  let index = villageDetail.commandTrainCaptain.findIndex(d => d.commandId === commandItem.commandId);
                  villageDetail.commandTrainCaptain.splice(index, 1);
                  response.villageDetail = villageDetail;
                  response.reload = true;
                  return response;
                }
              }); 
           }
        }
        return response;
    }

    async checkBuildImprovements(buildImprovements: BuildImprovements, mainbarDetail: MainbarDetail, villageDetail: VillageDetail, mapSchema: MapSchema)
    {
        if (buildImprovements != undefined && mainbarDetail != undefined && villageDetail != undefined && mapSchema != undefined)
        {
            var actualMainbarDetail = this.sourcesHelper.getActualMainbarSourcesDemand(mainbarDetail);
            var availableWorkers = villageDetail.workers - villageDetail.workersInUse;
            buildImprovements.technologyCanBuild.forEach(element => {
               var mapImprovementName = this.lowerFirstLetter(element.name);
               var mapImprovement =  mapSchema.mapImprovements[mapImprovementName] as MapSources;
               element.sourcesCanBuild = this.canSourceBuild(actualMainbarDetail.villageSources.sourcesDisplayed, mapImprovement.sources, availableWorkers, mapImprovement.workers);
            });
        }
        return buildImprovements;
    }

    async checkBattlesCanStart(profileDetail: ProfileDetail)
    {
      if (profileDetail != undefined && profileDetail.commandAttacks != undefined && profileDetail.commandAttacks[0] != null)
      {
        var command = profileDetail.commandAttacks.filter( x => x.isActive == true)[0];
        var timeSpan = this.getTimeSpan(command.sendStart);
        command.sendTimeLeft = command.sendTime - timeSpan;
        if (command.sendTimeLeft < 180)
        {
          command.canStartBattle = true;
        }
        else
        {
          command.canStartBattle = false;
        }
        if (command.sendTime <= timeSpan) 
        {
          let index = profileDetail.commandAttacks.findIndex(d => d.commandId === command.commandId);
          profileDetail.commandAttacks.splice(index, 1);
          return profileDetail;
        }
      }
      return profileDetail;
    }

    canSourceBuild(sourcesDisplayed: SourcesDemand, sources: SourcesDemand, workers: number, neededWorkers: number)
    {
      var sourcesCanIncrease = new SourcesCanBuild();
      sourcesCanIncrease.wood = sourcesDisplayed.wood > sources.wood;
      sourcesCanIncrease.bricks = sourcesDisplayed.bricks > sources.bricks;
      sourcesCanIncrease.stone = sourcesDisplayed.stone > sources.stone;
      sourcesCanIncrease.grain = sourcesDisplayed.grain > sources.grain;
      sourcesCanIncrease.workers = workers >= neededWorkers;
      if (sourcesCanIncrease.wood && sourcesCanIncrease.bricks && sourcesCanIncrease.stone && sourcesCanIncrease.grain && sourcesCanIncrease.workers)
      {
        sourcesCanIncrease.canBuild = true;
      }
      return sourcesCanIncrease;
    }

    lowerFirstLetter(string) {
      return string.charAt(0).toLowerCase() + string.slice(1);
    }

    // getTimeSpan(lastUpdateDate: Date)
    // {
    //   var currentDate = new Date();
    //   var currentDateUtc = new Date(currentDate.getUTCFullYear(),currentDate.getUTCMonth(),currentDate.getUTCDate(),currentDate.getUTCHours() + 1,currentDate.getUTCMinutes(),currentDate.getUTCSeconds());
    //   var dateTimeNow: number = currentDateUtc.valueOf()/1000;
    //   const formattedDate = new Date(Date.parse(lastUpdateDate.toString()));
    //   var lastUpdate: number = formattedDate.valueOf()/1000;
    //   var timeSpan = dateTimeNow - lastUpdate;
    //   return timeSpan;
    // }

    getTimeSpan(lastUpdateDate: Date)
    {
      return this.sourcesHelper.getTimeSpan(lastUpdateDate);
    }

    getCommandTypeList(buildingType: string)
    {
      let commandTypeList: Array<string>;
      if (buildingType === "Houses")
          commandTypeList = ['Workers','Explorer'];
      if (buildingType === "Barracks")
          commandTypeList = ['Axemen', 'BludgeonWarrior', 'Pikemen', 'Swordsman', 'Scythe', 'Musketeer' ];
      if (buildingType === "ArcheryTower")
          commandTypeList = ['Slingers', 'Archer', 'Crossbowman', 'Grenadier', 'Marksman' ];
      if (buildingType === "Stable")
          commandTypeList = ['Cataphract', 'Lancer', 'Scout', 'Knight', 'Hussars', 'Dragons', 'WingedHussars', 'Rajtar', 'Balloons' ];
      if (buildingType === "CatapultTower")
          commandTypeList = ['Catapult', 'Ballista', 'Cannon', 'Trebuchet', 'Gun', 'Mitrailleuse' ];
      
      return commandTypeList;
    }

    getBuildingImageUrl(buildingType: string) {
      var images = new BuildingImages();
      var buildingTypeLowerCase = buildingType.charAt(0).toLowerCase() + buildingType.slice(1)
      var imageUrl = images[buildingTypeLowerCase + "ImageUrl"] as string;
      return imageUrl;
    }

    getRecruitmentImageUrl(recruitmentType: string) {
      var images = new RecruitmentImages();
      var recruitmentTypeLowerCase = recruitmentType.charAt(0).toLowerCase() + recruitmentType.slice(1)
      var imageUrl = images[recruitmentTypeLowerCase + "ImageUrl"] as string;
      return imageUrl;
    }

    convertRecruitmentTypeName(recruitmentType: string)
    {
       if (recruitmentType == 'Workers')
          return 'Robotnicy';
        if (recruitmentType == 'Slingers')
          return 'Procarze';
        if (recruitmentType == 'Explorer')
          return 'Odkrywca';
        if (recruitmentType == 'Axemen')
          return 'Topornicy';
        if (recruitmentType == 'BludgeonWarrior')
          return 'Wojownik';
        if (recruitmentType == 'Cataphract')
          return 'Katafrakci';  
        if (recruitmentType == 'Lancer')
          return 'Lansjerzy';  
        if (recruitmentType == 'Scout')
          return 'Zwiadowcy';  
        if (recruitmentType == 'Catapult')
          return 'Katapulta';  
        if (recruitmentType == 'Archer')
          return 'Łucznik';  
        if (recruitmentType == 'Crossbowman')
          return 'Kusznik';  
        if (recruitmentType == 'Pikemen')
          return 'Pikinier';  
        if (recruitmentType == 'Swordsman')
          return 'Miecznik';  
        if (recruitmentType == 'Knight')
          return 'Rycerz'; 
        if (recruitmentType == 'Hussars')
          return 'Huzarzy'; 
        if (recruitmentType == 'Ballista')
          return 'Balista'; 
        if (recruitmentType == 'Cannon')
          return 'Armata'; 
        if (recruitmentType == 'Scythe')
          return 'Kosynier'; 
        if (recruitmentType == 'Grenadier')
          return 'Grenadier'; 
        if (recruitmentType == 'Dragons')
          return 'Dragoni'; 
        if (recruitmentType == 'WingedHussars')
          return 'Husaria'; 
        if (recruitmentType == 'Trebuchet')
          return 'Trebusz'; 
        if (recruitmentType == 'Gun')
          return 'Działo'; 
        if (recruitmentType == 'Marksman')
          return 'Arkebuzer'; 
        if (recruitmentType == 'Musketeer')
          return 'Muszkieter'; 
        if (recruitmentType == 'Rajtar')
          return 'Rajtarzy'; 
        if (recruitmentType == 'Balloons')
          return 'Balony';
        if (recruitmentType == 'Mitrailleuse')
          return 'Kartaczownica';
        if (recruitmentType == 'Baron')
          return 'Baron';
        if (recruitmentType == 'Settler')
          return 'Osadnik';
        return '';
    }

    getTechnologyImageUrl(technologyType: string) {
      var images = new TechnologyImages();
      var technologyTypeLowerCase = technologyType.charAt(0).toLowerCase() + technologyType.slice(1)
      var imageUrl = images[technologyTypeLowerCase + "ImageUrl"] as string;
      return imageUrl;
    }

    convertTechnologyTypeName(technologyType: string)
    {
        var recruitmentType = this.convertRecruitmentTypeName(technologyType);
        if (recruitmentType != '')
          return recruitmentType;
        if (technologyType == 'Path')
          return 'Ścieżka';  
        if (technologyType == 'WheatFarm')
          return 'Zboże';  
        if (technologyType == 'ClayMine')
          return 'Kopalnia gliny';  
        if (technologyType == 'Sawmill')
          return 'Tartak';  
        if (technologyType == 'Quarry')
          return 'Kamieniołom';  
        if (technologyType == 'FindingFarmsLevel1')
          return 'Odkrycia farm poziom 1'; 
        if (technologyType == 'FindingMinesLevel1')
          return 'Odkrycia kopalni poziom 1'; 
        if (technologyType == 'Finding2Fields')
          return 'Odkrycia na dwóch polach'; 
        if (technologyType == 'Fortress')
          return 'Forteca'; 
        if (technologyType == 'AtakOnWorkers')
          return 'Atak na robotników'; 
        if (technologyType == 'ChaseAwayWorkers')
          return 'Odstraszenie robotników'; 
        if (technologyType == 'Irrigation')
          return 'Irygacja'; 
        if (technologyType == 'Trolleys')
          return 'Transport - Wózki'; 
        if (technologyType == 'FindingFarmsLevel2')
          return 'Odkrycia farm poziom 2'; 
        if (technologyType == 'FindingMinesLevel2')
          return 'Odkrycia kopalni poziom 1'; 
        if (technologyType == 'Finding4Fields')
          return 'Odkrycia na czterech polach'; 
        if (technologyType == 'Road')
          return 'Droga'; 
        if (technologyType == 'Bridge')
          return 'Most'; 
        if (technologyType == 'CornFarm')
          return 'Farma kukurydzy';
        if (technologyType == 'CowsFarm')
          return 'Farma krów';
        if (technologyType == 'CoalMine')
          return 'Kopalnia węgla';
        if (technologyType == 'ShepherdsHut')
          return 'Bacówka';
        if (technologyType == 'Mules')
          return 'Transport - Muły';  
        if (technologyType == 'RiceFarm')
          return 'Farma ryżu';  
        if (technologyType == 'IronMine')
          return 'Kopalnia żelaza';  
        if (technologyType == 'SilverMine')
          return 'Kopalnia srebra';  
        if (technologyType == 'FindingFarmsLevel3')
          return 'Odkrycia farm poziom 3';  
        if (technologyType == 'FindingMinesLevel3')
          return 'Odkrycia kopalni poziom 3';  
        if (technologyType == 'Finding9Fieilds')
          return 'Odkrycia na dziewięciu polach';  
        if (technologyType == 'Railway')
          return 'Kolej';  
        if (technologyType == 'Tunel')
          return 'Tunel';  
        if (technologyType == 'MiningShaft')
          return 'Szyb górniczy';  
        if (technologyType == 'Horses')
          return 'Transport - Konie';  
        if (technologyType == 'SheepFarm')
          return 'Farma owiec';  
        if (technologyType == 'GoldMine')
          return 'Kopalnia złota';  
        if (technologyType == 'Wagons')
          return 'Transport - Wagony';  
    }

    getSendTroopsImageUrl(sendTroopsType: string) {
      var images = this.imagesHelper.getBuildingImages();
      var sendTroopsTypeLowerCase = sendTroopsType.charAt(0).toLowerCase() + sendTroopsType.slice(1)
      var imageUrl = images[sendTroopsTypeLowerCase + "ImageUrl"] as string;
      return imageUrl;
    }

    convertSendTroopsTypeName(sendTroopsType: string)
    {
       if (sendTroopsType == 'Attack')
          return 'Atak';
       if (sendTroopsType == 'Help')
          return 'Wsparcie';
    }

    getCommandCombinedImageUrl(commandType: string) {
      var images = new MapImages();
      var commandCombinedLowerCase = commandType.charAt(0).toLowerCase() + commandType.slice(1)
      var imageUrl = images[commandCombinedLowerCase + "ImageUrl"] as string;
      return imageUrl;
    }

    getCommandCombinedName(commandType: string, commandName: string) {
      if (commandType == 'SendWorkersCollect')
          return 'W drodze po surowce ';
      if (commandType == 'SendWorkersBackCollect')
          return 'Powrót ze zbierania surowców ';
      if (commandType == 'CollectSources')
          return 'Zbieranie surowców ';
      if (commandType == 'SendBuilders')
          return 'W drodze do budowania ';
      if (commandType == 'Building')
          return commandName;
    }

    getCommandCombinedArrival(commandStart: Date, commandTime: number) {
      const formattedDate = new Date(Date.parse(commandStart.toString()));
      var lastUpdate: number = formattedDate.valueOf()/1000;
      var commandArrival = lastUpdate + commandTime;
      return new Date(commandArrival);
    }
}