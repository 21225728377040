import { Component, OnInit, Input } from '@angular/core';
import { ModalDismissReasons, NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'modal-alert',
  templateUrl: './modal-alert.component.html',
  styleUrls: ['./modal-alert.component.css']
})
export class ModalAlertComponent implements OnInit {

  @Input() modal_alert_message: string;
  @Input() modalClass: string;
  closeResult = '';

  constructor(public modal: NgbModal,
	 public activeModal: NgbActiveModal) {}

  ngOnInit() {
    // Close the modal after 2 seconds
    setTimeout(() => {
        this.activeModal.close();
      }, 1000);
  }

  public submit() {
    //this.modalService.close(/* your result */);
  }

  open(content) {
		this.modal.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then(
			(result) => {
				this.closeResult = `Closed with: ${result}`;
			},
			(reason) => {
				this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			},
		);
	}

  dismiss()
  {
    this.modal.dismissAll();
  }

	private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return 'by pressing ESC';
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return 'by clicking on a backdrop';
		} else {
			return `with: ${reason}`;
		}
	}
}