import { SourcesCanIncrease } from "../townHall/sourcesCanIncrease";

export class TechnologyCanIncreaseLevel4 {
    gun: SourcesCanIncrease;
    marksman: SourcesCanIncrease;
    musketeer: SourcesCanIncrease;
    rajtar: SourcesCanIncrease;
    balloons: SourcesCanIncrease;
    mitrailleuse: SourcesCanIncrease;
    sheepFarm: SourcesCanIncrease;
    goldMine: SourcesCanIncrease;
    wagons: SourcesCanIncrease;
}