import { Injectable, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

import { Alert, AlertType } from '@app/_models';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ModalAlertComponent } from '@app/_modal/modal-alert.component';

@Injectable({ providedIn: 'root' })
export class AlertService implements OnInit {
    private subject = new Subject<Alert>();
    private defaultId = 'default-alert';
    modalRefBuild: NgbModalRef;
    backgroundColor: string = 'lightblue';

    constructor(private modalService: NgbModal) {}

    ngOnInit() {

    }

    // enable subscribing to alerts observable
    onAlert(id = this.defaultId): Observable<Alert> {
        return this.subject.asObservable().pipe(filter(x => x && x.id === id));
    }

    // convenience methods
    success(message: string, options?: any) {
        //this.alert(new Alert({ ...options, type: AlertType.Success, message }));
        this.modalRefBuild = this.modalService.open(ModalAlertComponent);
        this.modalRefBuild.componentInstance.modal_alert_message = message;
        this.modalRefBuild.componentInstance.modalClass = 'sucess';
    }

    error(message: string, options?: any) {
        //this.alert(new Alert({ ...options, type: AlertType.Error, message }));
        this.modalRefBuild = this.modalService.open(ModalAlertComponent);
        this.modalRefBuild.componentInstance.modal_alert_message = message;
        this.modalRefBuild.componentInstance.modalClass = 'error';
    }

    info(message: string, options?: any) {
        this.alert(new Alert({ ...options, type: AlertType.Info, message }));
    }

    warn(message: string, options?: any) {
        this.alert(new Alert({ ...options, type: AlertType.Warning, message }));
    }

    // main alert method    
    alert(alert: Alert) {
        alert.id = alert.id || this.defaultId;
        this.subject.next(alert);
    }

    // clear alerts
    clear(id = this.defaultId) {
        this.subject.next(new Alert({ id }));
    }
}