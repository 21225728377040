import { Component } from '@angular/core';

import { AccountService } from './_services';
import { User } from './_models/user';
import { MenuIconStatus } from './_models/info/menuIconStatus';
import { Subscription, timer } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { VillageService } from './_services/village.service';
import { Router } from '@angular/router';
import { MainbarDetail } from './_models/village/mainbarDetail';
import { TranslateService } from '@ngx-translate/core';

@Component({ selector: 'app', templateUrl: 'app.component.html' })
export class AppComponent {
    user: User;
    menuIconStatus: MenuIconStatus;
    subscription: Subscription;
    subscriptionMainbar: Subscription;
    highlight: boolean;
    highlightClass: string;
    mainbarDetail: MainbarDetail;
    highlightMap: boolean;

    constructor(private accountService: AccountService,
        private translate: TranslateService,
        private router: Router,
        private villageService: VillageService) {
        this.accountService.user.subscribe(x => this.user = x);
        this.menuIconStatus = new MenuIconStatus();
        this.highlight = false;
        this.highlightMap = false;
        this.translate.setDefaultLang('pl');
    }

    ngOnInit() {
        this.subscription = timer(400, 1000).pipe(
            switchMap(() => this.villageService.checkMenuIconStatus(this.router.url))
        ).subscribe(result => this.menuIconStatus = result);
        this.subscriptionMainbar = timer(400, 1000).pipe(
            switchMap(() => this.villageService.checkMainbarDetailSources(this.router.url))
        ).subscribe(result => { 
            this.mainbarDetail = result;
            if (!this.router.url.includes("maps/map") && result != undefined && result.tutorialDetail != undefined && result.tutorialDetail.inProgress == false && result.tutorialDetail.type == "MapZoom")
            {
                this.highlightMap = true;
            } else
            {
                this.highlightMap = false;
            }
        });
    }

    logout() {
        this.accountService.logout();
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
        this.subscriptionMainbar.unsubscribe();
     }
}