import {RecruitmentImages} from './recruitmentImages';

export class TechnologyImages extends RecruitmentImages {
    wheatFarmImageUrl: string = "./assets/technology/WheatFarm.png";
    clayMineImageUrl: string = "./assets/technology/ClayMine.png";
    sawmillImageUrl: string = "./assets/technology/Sawmill.png";
    quarryImageUrl: string = "./assets/technology/Quarry.png";
    pathImageUrl: string = "./assets/technology/Path.png";
    fortressImageUrl: string = "./assets/technology/Fortress.png";
    irrigationImageUrl: string;
    trolleysImageUrl: string = "./assets/technology/Trolleys.png";
    explorerTechnologyImageUrl: string = "./assets/technology/Explorer.png";
    scoutTechnologyImageUrl: string = "./assets/technology/Scout.jpg";
    findingFarmsLevel1ImageUrl: string = "./assets/technology/FindingFarms.png";
    findingMinesLevel1ImageUrl: string = "./assets/technology/FindingMines.png";
    finding2FieldsImageUrl: string;
    attackOnWorkersImageUrl: string = "./assets/technology/AttackOnWorkers.png";
    chaseAwayWorkersImageUrl: string = "./assets/technology/ChaseAwayWorkers.png";
    findingFarmsLevel2ImageUrl: string = "./assets/technology/FindingFarms.png";
    findingMinesLevel2ImageUrl: string = "./assets/technology/FindingMines.png";
    finding4FieldsImageUrl: string;
    roadImageUrl: string = "./assets/technology/Road.png";
    bridgeImageUrl: string = "./assets/technology/Bridge.png";
    cornFarmImageUrl: string = "./assets/technology/CornFarm.png";
    cowsFarmImageUrl: string = "./assets/technology/CowsFarm.png";
    coalMineImageUrl: string = "./assets/technology/CoalMine.png";
    shepherdsHutImageUrl: string = "./assets/technology/ShepherdsHut.png";
    mulesImageUrl: string = "./assets/technology/Mules.png";
    riceFarmImageUrl: string;
    ironMineImageUrl: string;
    silverMineImageUrl: string = "./assets/technology/SilverMine.png";
    findingFarmsLevel3ImageUrl: string = "./assets/technology/FindingFarms.png";
    findingMinesLevel3ImageUrl: string = "./assets/technology/FindingMines.png";
    finding9FieildsImageUrl: string;
    railwayImageUrl: string = "./assets/technology/Railway.png";
    tunelImageUrl: string;
    miningShaftImageUrl: string = "./assets/technology/MiningShaft.png";
    horsesImageUrl: string = "./assets/technology/Horses.png";
    sheepFarmImageUrl: string = "./assets/technology/SheepFarm.png";
    goldMineImageUrl: string = "./assets/technology/GoldMine.png";
    wagonsImageUrl: string = "./assets/technology/Wagons.png";
    workshopImageUrl: string = "./assets/village/VillageWorkshop.png";
}