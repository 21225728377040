import { RecruitmentSources } from "./recruitmentSources";

export class RecruitmentSchema {
    workers: RecruitmentSources;
    slingers: RecruitmentSources;
    explorer: RecruitmentSources;
    axemen: RecruitmentSources;
    bludgeonWarrior: RecruitmentSources;
    cataphract: RecruitmentSources;
    lancer: RecruitmentSources;
    scout: RecruitmentSources;
    catapult: RecruitmentSources;
    archer: RecruitmentSources;
    crossbowman: RecruitmentSources;
    pikemen: RecruitmentSources;
    swordsman: RecruitmentSources;
    knight: RecruitmentSources;
    hussars: RecruitmentSources;
    ballista: RecruitmentSources;
    cannon: RecruitmentSources;
    scythe: RecruitmentSources;
    grenadier: RecruitmentSources;
    dragons: RecruitmentSources;
    wingedHussars: RecruitmentSources;
    trebuchet: RecruitmentSources;
    gun: RecruitmentSources;
    marksman: RecruitmentSources;
    musketeer: RecruitmentSources;
    rajtar: RecruitmentSources;
    balloons: RecruitmentSources;
    mitrailleuse: RecruitmentSources;
    baron: RecruitmentSources;
    settler: RecruitmentSources;
}