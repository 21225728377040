export class SourcesImages {
    timeImageUrl: string = "./assets/sources/Time.png";
    woodImageUrl: string = "./assets/sources/Wood.png";
    brickImageUrl: string = "./assets/sources/Bricks.png";
    stoneImageUrl: string = "./assets/sources/Stone.png";
    grainImageUrl: string = "./assets/sources/Wheat.png";
    cornImageUrl: string = "./assets/sources/Corn.png";
    cowsImageUrl: string = "./assets/sources/Cows.png";
    riceImageUrl: string = "./assets/sources/Rice.png";
    sheepsImageUrl: string = "./assets/sources/Sheeps.png";
    coalImageUrl: string = "./assets/sources/Coal.png";
    ironImageUrl: string = "./assets/sources/Iron.png";
    silverImageUrl: string = "./assets/sources/Silver.png";
    goldImageUrl: string = "./assets/sources/Gold.png";
}