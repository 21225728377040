import { Component , Input, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { User } from '@app/_models';
import { JoinWorldModelRequest } from '@app/_models/profile/joinWorldModelRequest';
import { AccountService, AlertService } from '@app/_services';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { first } from 'rxjs/operators';

@Component({ templateUrl: 'home.component.html', encapsulation: ViewEncapsulation.None })
export class HomeComponent implements OnInit {
    user: User;
    form: FormGroup;
    loading = false;
    submitted = false;
    language: string;
    isZoomMap : boolean;
    modalRef: NgbModalRef;
    availableWorlds: string[];

    constructor(private accountService: AccountService,
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private alertService: AlertService) {
        this.user = this.accountService.userValue;
        this.language = 'Załóż nowe konto';
        this.isZoomMap = false;
    }

    ngOnInit() {
        this.form = this.formBuilder.group({
            username: ['', Validators.required],
            password: ['', Validators.required]
        });

        this.accountService.getAvailableWorlds().subscribe(result => this.availableWorlds = result);
    }

    register()
    {
        this.router.navigateByUrl("/account/register");
    }

    // convenience getter for easy access to form fields
    get f() { return this.form.controls; }

    loginToWorld(worldNumber: string)
    {    
        this.accountService.loginToWorld(worldNumber)
        .subscribe({
            next: (result) => {
                const returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/maps/map';
                this.router.navigateByUrl(returnUrl);
                this.loading = false;
            },
            error: error => {
                this.alertService.error(error);
                this.loading = false;
            }
        });     
    }

    joinToWorld(worldNumber: string)
    {    
        var request = new JoinWorldModelRequest();
        request.worldNumber = worldNumber;
        this.accountService.joinToWorld(request)
        .then(result =>{
            if (result.isSuccess)
            {
                const returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/maps/map';
                this.router.navigateByUrl(returnUrl);
                this.loading = false;
            }
            else
            {
                    this.alertService.error(result.error);
                    this.loading = false;
                }
            });
    }

    onSubmit() {
        this.submitted = true;

        // reset alerts on submit
        this.alertService.clear();

        // stop here if form is invalid
        if (this.form.invalid) {
            return;
        }

        this.loading = true;
        this.accountService.login(this.f.username.value, this.f.password.value)
            .pipe(first())
            .subscribe({
                next: () => {
                    // get return url from query parameters or default to home page
                    //const returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/maps/map';
                    //this.router.navigateByUrl(returnUrl);
                    this.user = this.accountService.userValue;
                    this.accountService.getAvailableWorlds().subscribe(result => this.availableWorlds = result);
                },
                error: error => {
                    this.alertService.error(error);
                    this.loading = false;
                }
            });
    }
}