import { SourcesImages } from "./sourcesImages";

export class RecruitmentImages extends SourcesImages {
    barracksImageUrl: string = "./assets/village/VillageBarracks.png";
    archeryTowerImageUrl: string = "./assets/village/VillageArcheryTower.png";
    stableImageUrl: string = "./assets/village/VillageStable.png";
    catapultTowerImageUrl: string = "./assets/village/VillageCatapultTower.png";
    housesImageUrl: string = "./assets/village/VillageHouses.png";
    placeImageUrl: string = "./assets/village/VillagePlace.png";
    castleImageUrl: string = "./assets/village/VillageCastle.png";

    workersImageUrl: string = "./assets/village/Worker.png";
    workersNormalImageUrl: string = "./assets/maps/WorkerNormal.png";
    explorerImageUrl: string = "./assets/troops/Explorer.png";
    slingersImageUrl: string = "./assets/troops/Slingers.png";
    axemenImageUrl: string = "./assets/troops/Axemen.png";
    bludgeonWarriorImageUrl: string = "./assets/troops/BludgeonWarrior.png";
    cataphractImageUrl: string = "./assets/troops/Cataphract.png";
    lancerImageUrl: string = "./assets/troops/Lancer.png";
    scoutImageUrl: string = "./assets/troops/Scout.png";
    catapultImageUrl: string = "./assets/troops/Catapult.png";
    archerImageUrl: string = "./assets/troops/Archer.png";
    crossbowmanImageUrl: string = "./assets/troops/Crossbowman.png";
    pikemenImageUrl: string = "./assets/troops/Pikemen.png";
    swordsmanImageUrl: string = "./assets/troops/Swordsman.png";
    knightImageUrl: string = "./assets/troops/Knight.png";
    hussarsImageUrl: string = "./assets/troops/Hussars.png";
    ballistaImageUrl: string = "./assets/troops/Ballista.png";
    cannonImageUrl: string = "./assets/troops/Cannon.png";
    scytheImageUrl: string = "./assets/troops/Scythe.png";
    grenadierImageUrl: string = "./assets/troops/Grenadier.png";
    dragonsImageUrl: string = "./assets/troops/Dragons.png";
    wingedHussarsImageUrl: string = "./assets/troops/WingedHussars.png";
    trebuchetImageUrl: string = "./assets/troops/Trebuchet.png";
    gunImageUrl: string = "./assets/troops/Gun.png";
    marksmanImageUrl: string = "./assets/troops/Marksman.png";
    musketeerImageUrl: string = "./assets/troops/Musketeer.png";
    rajtarImageUrl: string = "./assets/troops/Rajtar.png";
    balloonsImageUrl: string = "./assets/troops/Balloons.png";
    mitrailleuseImageUrl: string = "./assets/troops/Mitrailleuse.png";
    baronImageUrl: string = "./assets/troops/Baron.png";
    settlerImageUrl: string = "./assets/troops/Settler.png";
    captain1ImageUrl: string = "./assets/troops/Captain1.png";
    captain2ImageUrl: string = "./assets/troops/Captain2.png";
    captain3ImageUrl: string = "./assets/troops/Captain3.png";
    defenceImageUrl: string = "./assets/commands/SendSupport.png";
    attackImageUrl: string = "./assets/commands/SendAttack.png";
    shootingImageUrl: string = "./assets/commands/SendAttack.png";
    speedImageUrl: string = "./assets/commands/SendAttack.png";
}