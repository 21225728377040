import { SourcesImages } from "./sourcesImages";

export class MapImages extends SourcesImages {
    sendWorkersCollectImageUrl: string = "./assets/commands/SendWorkersCollect.png";
    sendWorkersBackCollectImageUrl: string = "./assets/commands/SendWorkersBackCollect.png";
    workersCollectImageUrl: string = "./assets/commands/WorkersCollect.png";
    collectSourcesImageUrl: string = "./assets/commands/WorkersCollect.png";
    sendBuildersImageUrl: string = "./assets/commands/SendBuilders.png";
    sendBuildersBackImageUrl: string = "./assets/commands/SendBuildersBack.png";
    sendAttackImageUrl: string = "./assets/commands/SendAttack.png";
    buildingImageUrl: string = "./assets/commands/Building.png";
}