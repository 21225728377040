import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent, OwhComponent, PrivacyPolicyComponent, StatuteComponent } from './home';
import { AuthGuard } from './_helpers';
import { LoginComponent } from './account/login.component';
import { MapsComponent } from './maps/maps.component';

const accountModule = () => import('./account/account.module').then(x => x.AccountModule);
const usersModule = () => import('./users/users.module').then(x => x.UsersModule);
const mapsModule = () => import('./maps/maps.module').then(x => x.MapsModule);
const villageModule = () => import('./village/village.module').then(x => x.VillageModule);
const buildingModule = () => import('./buildings/building.module').then(x => x.BuildingModule);
const battlesModule = () => import('./battles/battles.module').then(x => x.BattlesModule);
const infoModule = () => import('./info/info.module').then(x => x.InfoModule);

const routes: Routes = [
    { path: '', component: HomeComponent },
    { path: 'owh', component: OwhComponent },
    { path: 'statute', component: StatuteComponent },
    { path: 'privacyPolicy', component: PrivacyPolicyComponent },
    { path: 'maps/map', component: MapsComponent },
    { path: 'users', loadChildren: usersModule, canActivate: [AuthGuard] },
    { path: 'account', loadChildren: accountModule },
    { path: 'maps', loadChildren: mapsModule },
    { path: 'village', loadChildren: villageModule },
    { path: 'building', loadChildren: buildingModule },
    { path: 'battles', loadChildren: battlesModule },
    { path: 'info', loadChildren: infoModule },

    // otherwise redirect to home
    { path: '**', redirectTo: '' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }