import { SourcesImages } from "./sourcesImages";

export class BuildingImages extends SourcesImages {
    workersImageUrl: string = "./assets/village/Worker.png";
    townHallImageUrl: string = "./assets/village/VillageTownHall.png";
    castleImageUrl: string = "./assets/village/VillageCastle.png";
    wallSector1ImageUrl: string = "./assets/village/VillageWall.png";
    wallSector2ImageUrl: string = "./assets/village/VillageWall.png";
    placeImageUrl: string = "./assets/village/VillagePlace.png";
    marketplaceImageUrl: string = "./assets/village/VillageMarketplace.png";
    forestImageUrl: string = "./assets/village/VillageForest.png";
    brickYardImageUrl: string = "./assets/village/VillageBrickYard.png";
    quarryImageUrl: string = "./assets/village/VillageQuarry.png";
    fieldImageUrl: string = "./assets/village/VillageField.png";
    storageImageUrl: string = "./assets/village/VillageStorage.png";
    granaryImageUrl: string = "./assets/village/VillageGranary.png";
    heavyStorageImageUrl: string = "./assets/village/VillageHeavyStorage.png";
    barracksImageUrl: string = "./assets/village/VillageBarracks.png";
    archeryTowerImageUrl: string = "./assets/village/VillageArcheryTower.png";
    stableImageUrl: string = "./assets/village/VillageStable.png";
    catapultTowerImageUrl: string = "./assets/village/VillageCatapultTower.png";
    housesImageUrl: string = "./assets/village/VillageHouses.png";
    soldiersHouseImageUrl: string = "./assets/village/VillageSoldiersHouse.png";
    workshopImageUrl: string = "./assets/village/VillageWorkshop.png";
    alchemistImageUrl: string = "./assets/village/VillageAlchemist.png";
}