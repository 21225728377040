import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { VillageDetail } from '../_models/village/villageDetail';
import { MainbarDetail } from '@app/_models/village/mainbarDetail';
import { CancelAddTechnology, IncreaseBuildingResponse, ProfileDetail } from '@app/_models';
import { environment } from '@environments/environment';
import { Subscription } from 'rxjs';
// import { VillageService } from '../_services/village.service';
import { TechnologySchema } from '../_models/profile/technologySchema';
import { AddTechnology } from '../_models/profile/addTechnology';
import { AddTechnologyResponse } from '../_models/profile/addTechnologyResponse';
import { UnlockTechnologyResponse } from '../_models/profile/unlockTechnologyResponse';
import { TechnologyCanIncrease } from '../_models/profile/technologyCanIncrease';
import { TechnologyHelper } from '../_helpers/technology.helper';
import { SourcesHelper } from '../_helpers/sources.helper';
import { MapImages } from '@app/_models/images/mapImages';


@Injectable({ providedIn: 'root' })
export class ProfileService {
    profiles: ProfileDetail[];
    profileInfo: ProfileDetail;
    villageInfo: VillageDetail;
    mainbarDetail : MainbarDetail;
    technologySchema: TechnologySchema;
    subscription: Subscription;

    constructor(
        private router: Router,
        private http: HttpClient,
        private sourcesHelper: SourcesHelper,
        private technologyHelper: TechnologyHelper
    ) {
      //this.villageService.getMainbarDetailsToPromise().then(result => this.mainbarDetail = result);
      //villageService.getVillageDetails().subscribe(result => this.villageInfo = result);
      this.getTechnologySchema().subscribe(result => this.technologySchema = result);
    }


    getProfileDetails() {      
      return this.http.get<ProfileDetail>(`${environment.dataStorageUrl}/profile/GetProfile`);
    }

    addTechnology(addTechnology: AddTechnology) : Promise<AddTechnologyResponse> {
      return new Promise(resolve=>{
        this.http.post<AddTechnologyResponse>(`${environment.dataStorageUrl}/technology/addTechnology`, addTechnology)
         .subscribe(
            (data:any) => {
                this.sourcesHelper.updateMainbarDetailSources(data.mainbarDetail);
                this.mainbarDetail = data.mainbarDetail;
                resolve(data);
         })
      })
    }

    cancelAddTechnology(cancelAddTechnology: CancelAddTechnology) : Promise<AddTechnologyResponse>
    {
      return new Promise(resolve=>{
        this.http.post<AddTechnologyResponse>(`${environment.dataStorageUrl}/technology/cancelAddTechnology`, cancelAddTechnology )
         .subscribe(
            (data:any) => {
                this.sourcesHelper.updateMainbarDetailSources(data.mainbarDetail);
                this.mainbarDetail = data.mainbarDetail;
                resolve(data);
         })
      })
    }

    getTechnologySchema() {      
      return this.http.get<TechnologySchema>(`${environment.dataStorageUrl}/technology/GetTechnologySchema`);
    }

    unlockTechnologyLevel() {      
      return this.http.post<UnlockTechnologyResponse>(`${environment.dataStorageUrl}/technology/UnlockTechnologyLevel`, null);
    }

    async checkTechnologyCanIncrease(mainbarDetail: MainbarDetail, villageInfo: VillageDetail, technologySchema: TechnologySchema, profileDetail: ProfileDetail)
    {
        var technologyCanIncrease = new TechnologyCanIncrease();
        if (mainbarDetail?.villageSources != undefined && profileDetail != undefined)
        {
            var actualMainbarDetail = this.sourcesHelper.getActualMainbarSourcesDemand(mainbarDetail);
            var availableWorkers = villageInfo.workers - villageInfo.workersInUse;
            technologyCanIncrease.level1 = await this.technologyHelper.checkTechnologyCanIncreaseLevel1(actualMainbarDetail, technologySchema, availableWorkers, profileDetail.technology.level1);
            technologyCanIncrease.level2 = await this.technologyHelper.checkTechnologyCanIncreaseLevel2(actualMainbarDetail, technologySchema, availableWorkers, profileDetail.technology.level2);
            technologyCanIncrease.level3 = await this.technologyHelper.checkTechnologyCanIncreaseLevel3(actualMainbarDetail, technologySchema, availableWorkers, profileDetail.technology.level3);
            technologyCanIncrease.level4 = await this.technologyHelper.checkTechnologyCanIncreaseLevel4(actualMainbarDetail, technologySchema, availableWorkers, profileDetail.technology.level4);
        }
        return technologyCanIncrease;
    }

    async checkIncomingAttacks(profileDetail: ProfileDetail)
    {
        if (profileDetail != undefined && profileDetail.commandAttacks != undefined && profileDetail.commandAttacks.length > 0)
        {
          profileDetail.commandAttacks.filter( x => x.isActive == true).forEach((commandItem) => {
            commandItem.sendTroopsImageUrl = this.getAttackImageUrl()
          }); 
          var command = profileDetail.commandAttacks.filter( x => x.isActive == true)[0];
          var timeSpan = this.getTimeSpan(command.sendStart);
          command.sendTimeLeft = command.sendTime - timeSpan;
          command.arriveDate = new Date(command.sendStart);
          command.arriveDate.setMilliseconds(command.arriveDate.getMilliseconds() + command.sendTime);
          if (command.sendTimeLeft < 300)
          {
            command.canStartBattle = true;
          }
          else
          {
            command.canStartBattle = false;
          }
          if (command.sendTimeLeft <= timeSpan) 
          {
            let index = profileDetail.commandAttacks.findIndex(d => d.commandId === command.commandId);
            profileDetail.commandAttacks.splice(index, 1);
            return profileDetail;
        }
        return profileDetail;
    }
  }

  getAttackImageUrl() {
    var images = new MapImages();
    var imageName = "sendAttackImageUrl"
    var imageUrl = images[imageName] as string;
    return imageUrl;
  }

  getTimeSpan(lastUpdateDate: Date)
  {
    return this.sourcesHelper.getTimeSpan(lastUpdateDate);
  }
}