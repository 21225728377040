export enum RecruitmentTypeEnum {
    Workers = 'Workers',
    Explorer = 'Explorer',
    Slingers = 'Slingers',
    Axemen = 'Axemen',
    BludgeonWarrior = 'BludgeonWarrior',
    Cataphract = 'Cataphract',
    Lancer = 'Lancer',
    Scout = 'Scout',
    Catapult = 'Catapult',
    Archer = 'Archer',
    Crossbowman = 'Crossbowman',
    Pikemen = 'Pikemen',
    Swordsman = 'Swordsman',
    Knight = 'Knight',
    Hussars = 'Hussars',
    Ballista = 'Ballista',
    Cannon = 'Cannon',
    Scythe = 'Scythe',
    Grenadier = 'Grenadier',
    Dragons = 'Dragons',
    WingedHussars = 'WingedHussars',
    Trebuchet = 'Trebuchet',
    Gun = 'Gun',
    Marksman = 'Marksman',
    Musketeer = 'Musketeer',
    Rajtar = 'Rajtar',
    Balloons = 'Balloons',
    Mitrailleuse = 'Mitrailleuse',

}