import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpParams } from '@angular/common/http';
//import { BehaviorSubject, Observable } from 'rxjs';
//import { map } from 'rxjs/operators';
import { DetailCoords, VillagesList } from '@app/_models';
import { AddSources } from '@app/_models/maps/addSources';
import { environment } from '@environments/environment';
import { AddSourcesResponse } from '@app/_models/maps/addSourcesResponse';
import { SendWorkersResponse } from '@app/_models/maps/sendWorkersResponse';
import { VillageDetail } from '@app/_models/village/villageDetail';
import { CollectSources } from '@app/_models/maps/collectSources';
import { MapSchema } from '@app/_models/maps/mapSchema';
import { Observable } from 'rxjs';
import { UnitsSpeed } from '@app/_models/maps/unitsSpeed';
import { UnitsGathering } from '@app/_models/maps/unitsGathering';
import { SendWorkers } from '@app/_models/maps/sendWorkers';
import { MapInfoList } from '@app/_models/maps/mapInfoList';
import { BuildImprovements } from '@app/_models/maps/buildImprovements';
import { SendBuildersResponse } from '@app/_models/maps/sendBuildersResponse';
import { SendBuildersRequest } from '@app/_models/maps/sendBuildersRequest';

//import { environment } from '@environments/environment';

@Injectable({ providedIn: 'root' })
export class MapService {

    constructor(
        private router: Router,
        private http: HttpClient
    ) {

    }


    getUserVillages() {
        return this.http.get<VillagesList>(`${environment.dataStorageUrl}/map/getVillagesList`);   
    }

    getMapInfoList() {
        return this.http.get<MapInfoList>(`${environment.dataStorageUrl}/map/getMapInfoList`);   
    }

    getBuildImprovements(target_coordx, target_coordy) {
        const params = new HttpParams().append('x', target_coordx).append('y', target_coordy);  
        return this.http.get<BuildImprovements>(`${environment.dataStorageUrl}/map/getBuildImprovements` , { params});   
    }

    // getMapSchema() {
    //     var mapSchema = new MapSchema();
    //     mapSchema.unitsSpeed = new UnitsSpeed();
    //     mapSchema.unitsSpeed.workers = 15*60;
    //     mapSchema.unitsGathering = new UnitsGathering();
    //     mapSchema.unitsGathering.level1 = 1;
    //     return mapSchema;
    // }

    getMapSchema() {      
        return this.http.get<MapSchema>(`${environment.dataStorageUrl}/map/GetMapSchema`);
    }

    addSources(addSources: AddSources) {
      return this.http.post<AddSourcesResponse>(`${environment.dataStorageUrl}/map/sendWorkers`, addSources);
    }

    sendWorkers(sendWorkers: SendWorkers) {
        return this.http.post<SendWorkersResponse>(`${environment.dataStorageUrl}/map/sendWorkers`, sendWorkers);
    }

    sendBuilders(sendBuilders: SendBuildersRequest) {
        return this.http.post<SendBuildersResponse>(`${environment.dataStorageUrl}/map/sendBuilders`, sendBuilders);
    }

    async checkCollectSourceTime(villageDetail: VillageDetail, mapSchema: MapSchema, targetX: number, targetY: number) {
        var collectSources = new CollectSources();
        if (villageDetail != undefined && mapSchema != undefined)
        {
            var startX = villageDetail.coords.x;
            var startY = villageDetail.coords.y;
            var xDifference = Math.abs(Math.floor((startX - targetX)*10)/10);
            var yDifference = Math.abs(Math.floor((startY - targetY)*10)/10);
            var distance = 0;
            if (xDifference > yDifference)
            {
                distance = ((xDifference-yDifference)*mapSchema.unitsSpeed.workers + yDifference*mapSchema.unitsSpeed.workers*1.5)*1000;
            }
            if (yDifference > xDifference)
            {
                distance = ((yDifference-xDifference)*mapSchema.unitsSpeed.workers + xDifference*mapSchema.unitsSpeed.workers*1.5)*1000;
            }
            if (yDifference == xDifference)
            {
                distance = ((xDifference)*mapSchema.unitsSpeed.workers*1.5)*1000;
            }
            collectSources.distance = this.convertTime(distance);
        }
        collectSources.detailCoords = new DetailCoords();
        collectSources.detailCoords.x = targetX;
        collectSources.detailCoords.y = targetY;
        return collectSources;
    }

    getCollectTime(workers: number)
    {
        var collectTime = Math.abs(workers * 60 / 10);
        if (collectTime > 3600) collectTime = 3600;
        return this.convertTime(collectTime*1000);
    }

    convertTime(value: number)
    {
        if (value != null) 
        {   
          const totalSeconds = Math.floor(value/1000);
          if (totalSeconds < 60)
          {
              return "00:00:" + this.padTo2Digits(totalSeconds);
          }  
          else if (totalSeconds < 3600)
          {
            const minutes = Math.floor(totalSeconds / 60);
            const seconds = totalSeconds % 60;
            return "00:" + `${this.padTo2Digits(minutes)}:${this.padTo2Digits(seconds)}`;
          } 
          else
          {
            const totalMinutes = Math.floor(totalSeconds / 60);
    
            const seconds = totalSeconds % 60;
            const hours = Math.floor(totalMinutes / 60);
            const minutes = totalMinutes % 60;
            return `${this.padTo2Digits(hours)}:${this.padTo2Digits(minutes)}:${this.padTo2Digits(seconds)}`;
          }   
        }
    }

    padTo2Digits(num) {
        return num.toString().padStart(2, '0');
      }
}