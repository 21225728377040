import { LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { JwtInterceptor, ErrorInterceptor } from './_helpers';
import { AppComponent } from './app.component';
import { AlertComponent } from './_components';
import { HomeComponent, OwhComponent, PrivacyPolicyComponent, StatuteComponent } from './home';
import { MainbarComponent } from './mainbar/mainbar.component';
import { ModalBuildComponent } from './_modal/modal-build.component';
import { ModalAlertComponent } from './_modal/modal-alert.component';
//import { ConvertTimePipe } from './_pipes/convert-time.pipe';
//import { CoordValuePipe } from '@app/_pipes/coord-value.pipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgToggleModule } from 'ng-toggle-button';
import { NgbModule, NgbNavModule } from '@ng-bootstrap/ng-bootstrap'
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { LoginComponent } from './account/login.component';
import { RegisterComponent } from './account/register.component';
import { MapsComponent } from './maps/maps.component';

const config: SocketIoConfig = { url: 'http://www.worldofevo.pl:3000', options: {} };
//const config: SocketIoConfig = { url: 'http://localhost:3000', options: {} };

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
  }

@NgModule({
    imports: [
        AppRoutingModule,
        BrowserModule,
        HttpClientModule,
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        NgbModule,
        NgbNavModule,
        NgToggleModule,
        TranslateModule.forRoot({
            loader: {
              provide: TranslateLoader,
              useFactory: HttpLoaderFactory,
              deps: [HttpClient]
            }
          }),
        SocketIoModule.forRoot(config)
    ],
    declarations: [
        AppComponent,
        AlertComponent,
        HomeComponent,
        MainbarComponent,
        ModalBuildComponent,
        ModalAlertComponent,
        OwhComponent,
        StatuteComponent,
        PrivacyPolicyComponent,
        LoginComponent,
        RegisterComponent,
        MapsComponent
        //CoordValuePipe
        //ConvertTimePipe
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
        // { provide: LOCALE_ID, useValue: 'pl-PL' }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { };