import { CommandTechnology } from "../profile/commandTechnology";
import { TechnologyDetail } from "../profile/technologyDetail";
import { CommandTroops } from "../village/commandTroops";
import { Invitations } from "./invitations";
import { TutorialDetail } from "./tutorialDetail";

export class ProfileDetail {
    id: string;
    name: string;
    alliance: string;
    points: number;
    villagesNumber: number;
    invitations: Invitations[];
    technologyLevel: string;
    technologyProgress: number;
    technology: TechnologyDetail;
    commandTechnology: CommandTechnology[];
    commandAttacks: CommandTroops[];
    tutorialDetail: TutorialDetail;
    isBlocked: boolean;
}