import { SourcesCanIncrease } from "./sourcesCanIncrease";

export class BuildingCanIncrease {
    quarry: SourcesCanIncrease;
    brickYard: SourcesCanIncrease;
    field: SourcesCanIncrease;
    forest: SourcesCanIncrease;
    castle: SourcesCanIncrease;
    wallSector1: SourcesCanIncrease;
    wallSector2: SourcesCanIncrease;
    townHall: SourcesCanIncrease;
    granary: SourcesCanIncrease;
    storage: SourcesCanIncrease;
    heavyStorage: SourcesCanIncrease;
    barracks: SourcesCanIncrease;
    stable: SourcesCanIncrease;
    archeryTower: SourcesCanIncrease;
    soldiersHouse: SourcesCanIncrease;
    houses: SourcesCanIncrease;
    workshop: SourcesCanIncrease;
    place: SourcesCanIncrease;
    marketplace: SourcesCanIncrease;
    catapultTower: SourcesCanIncrease;
    alchemist: SourcesCanIncrease;
}