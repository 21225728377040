import { SourcesCanIncrease } from "../townHall/sourcesCanIncrease";

export class TechnologyCanIncreaseLevel2 {
    archer: SourcesCanIncrease;
    crossbowman: SourcesCanIncrease;
    pikemen: SourcesCanIncrease;
    swordsman: SourcesCanIncrease;
    knight: SourcesCanIncrease;
    hussars: SourcesCanIncrease;
    ballista: SourcesCanIncrease;
    findingFarmsLevel2: SourcesCanIncrease;
    findingMinesLevel2: SourcesCanIncrease;
    finding4Fields: SourcesCanIncrease;
    road: SourcesCanIncrease;
    bridge: SourcesCanIncrease;
    cornFarm: SourcesCanIncrease;
    cowsFarm: SourcesCanIncrease;
    coalMine: SourcesCanIncrease;
    shepherdsHut: SourcesCanIncrease;
    mules: SourcesCanIncrease;
}