export class SourcesCanRecruit {
    max: number;
    recruit: boolean;
    wood: boolean; //wood
    bricks: boolean; //bricks
    stone: boolean; //stone
    grain: boolean; //grain
    corn: boolean; //corn
    cows: boolean; //cows
    sheeps: boolean; //sheeps
    rice: boolean; //rice
    coal: boolean; //coal
    iron: boolean; //iron
    silver: boolean; //silver
    gold: boolean; //gold
    diamond: boolean; //diamond
}