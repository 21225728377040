import { Component, Injectable } from '@angular/core';
import { SourcesDemand } from '../_models/village/sourcesDemand';
import { BuildingCanIncrease, SourcesAreMax, IncreaseBuildingResponse, RecruitmentDetail, CancelBuildingIncrease, SendTroops, SendTroopsResponse, CancelSendTroops, ProfileDetail } from '../_models';
import { RecruitmentType } from '../_models/recruitment/recruitmentType';
import { SourcesCanRecruit } from '@app/_models/recruitment/sourcesCanRecruit';

@Injectable({ providedIn: 'root' })
export class VillageHelper {
    constructor(
        
    ) {}

    setSourcesSummaryHouses(recruitmentDetail: RecruitmentDetail, profileDetail: ProfileDetail)
    {
       var sourcesSummary = this.setSourceDemand()
       sourcesSummary = this.addSources(sourcesSummary, recruitmentDetail.workers)
       if (profileDetail?.technology.level1.explorer?.isCompleted) sourcesSummary = this.addSources(sourcesSummary, recruitmentDetail.explorer)
       return sourcesSummary;
    }

    setSourcesSummaryBarracks(recruitmentDetail: RecruitmentDetail, profileDetail: ProfileDetail)
    {
       var sourcesSummary = this.setSourceDemand()
       if (profileDetail?.technology.level1.axemen?.isCompleted) sourcesSummary = this.addSources(sourcesSummary, recruitmentDetail.axemen)
       if (profileDetail?.technology.level1.bludgeonWarrior?.isCompleted) sourcesSummary = this.addSources(sourcesSummary, recruitmentDetail.bludgeonWarrior)
       if (profileDetail?.technology.level2.pikemen?.isCompleted) sourcesSummary = this.addSources(sourcesSummary, recruitmentDetail.pikemen)
       if (profileDetail?.technology.level2.swordsman?.isCompleted) sourcesSummary = this.addSources(sourcesSummary, recruitmentDetail.swordsman)
       if (profileDetail?.technology.level3.scythe?.isCompleted) sourcesSummary = this.addSources(sourcesSummary, recruitmentDetail.scythe)
       if (profileDetail?.technology.level4.musketeer?.isCompleted) sourcesSummary = this.addSources(sourcesSummary, recruitmentDetail.musketeer)
       return sourcesSummary;
    }

    setSourcesSummaryStable(recruitmentDetail: RecruitmentDetail, profileDetail: ProfileDetail)
    {
       var sourcesSummary = this.setSourceDemand()
       if (profileDetail?.technology.level1.cataphract?.isCompleted) sourcesSummary = this.addSources(sourcesSummary, recruitmentDetail.cataphract)
       if (profileDetail?.technology.level1.lancer?.isCompleted) sourcesSummary = this.addSources(sourcesSummary, recruitmentDetail.lancer)
       if (profileDetail?.technology.level1.scout?.isCompleted) sourcesSummary = this.addSources(sourcesSummary, recruitmentDetail.scout)
       if (profileDetail?.technology.level2.knight?.isCompleted) sourcesSummary = this.addSources(sourcesSummary, recruitmentDetail.knight)
       if (profileDetail?.technology.level2.hussars?.isCompleted) sourcesSummary = this.addSources(sourcesSummary, recruitmentDetail.hussars)
       if (profileDetail?.technology.level3.dragons?.isCompleted) sourcesSummary = this.addSources(sourcesSummary, recruitmentDetail.dragons)
       if (profileDetail?.technology.level3.wingedHussars?.isCompleted) sourcesSummary = this.addSources(sourcesSummary, recruitmentDetail.wingedHussars)
       if (profileDetail?.technology.level4.rajtar?.isCompleted) sourcesSummary = this.addSources(sourcesSummary, recruitmentDetail.rajtar)
       if (profileDetail?.technology.level4.balloons?.isCompleted) sourcesSummary = this.addSources(sourcesSummary, recruitmentDetail.balloons)
       return sourcesSummary;
    }
    
    setSourcesSummaryArcheryTower(recruitmentDetail: RecruitmentDetail, profileDetail: ProfileDetail)
    {
       var sourcesSummary = this.setSourceDemand()
       if (profileDetail?.technology.level1.slingers?.isCompleted) sourcesSummary = this.addSources(sourcesSummary, recruitmentDetail.slingers)
       if (profileDetail?.technology.level2.archer?.isCompleted) sourcesSummary = this.addSources(sourcesSummary, recruitmentDetail.archer)
       if (profileDetail?.technology.level2.crossbowman?.isCompleted) sourcesSummary = this.addSources(sourcesSummary, recruitmentDetail.crossbowman)
       if (profileDetail?.technology.level3.grenadier?.isCompleted) sourcesSummary = this.addSources(sourcesSummary, recruitmentDetail.grenadier)
       if (profileDetail?.technology.level4.marksman?.isCompleted) sourcesSummary = this.addSources(sourcesSummary, recruitmentDetail.marksman)
       return sourcesSummary;
    }

    setSourcesSummaryCatapultTower(recruitmentDetail: RecruitmentDetail, profileDetail: ProfileDetail)
    {
       var sourcesSummary = this.setSourceDemand()
       if (profileDetail?.technology.level1.catapult?.isCompleted) sourcesSummary = this.addSources(sourcesSummary, recruitmentDetail.catapult)
       if (profileDetail?.technology.level2.ballista?.isCompleted) sourcesSummary = this.addSources(sourcesSummary, recruitmentDetail.ballista)
       if (profileDetail?.technology.level3.cannon?.isCompleted) sourcesSummary = this.addSources(sourcesSummary, recruitmentDetail.cannon)
       if (profileDetail?.technology.level3.trebuchet?.isCompleted) sourcesSummary = this.addSources(sourcesSummary, recruitmentDetail.trebuchet)
       if (profileDetail?.technology.level4.gun?.isCompleted) sourcesSummary = this.addSources(sourcesSummary, recruitmentDetail.gun)
       if (profileDetail?.technology.level4.mitrailleuse?.isCompleted) sourcesSummary = this.addSources(sourcesSummary, recruitmentDetail.mitrailleuse)
       return sourcesSummary;
    }

    canRecruitmentIncrease(actualSources: SourcesDemand, sourcesSummary: SourcesDemand, sources: SourcesDemand, sourcesDisplayed: SourcesDemand,)
    {
      var actualSourcesDisplayed = this.removeSources(actualSources, sourcesSummary);
      var sourcesCanRecruit = new SourcesCanRecruit();
      sourcesCanRecruit.wood = (actualSourcesDisplayed.wood + sourcesDisplayed.wood) > sourcesDisplayed.wood;
      sourcesCanRecruit.bricks = (actualSourcesDisplayed.bricks + sourcesDisplayed.bricks) > sourcesDisplayed.bricks;
      sourcesCanRecruit.stone = (actualSourcesDisplayed.stone + sourcesDisplayed.stone) > sourcesDisplayed.stone;
      sourcesCanRecruit.grain = (actualSourcesDisplayed.grain + sourcesDisplayed.grain) > sourcesDisplayed.grain;
      if (sources.corn > 0) sourcesCanRecruit.corn = (actualSourcesDisplayed.corn + sourcesDisplayed.corn) > sourcesDisplayed.corn;
      if (sources.cows > 0) sourcesCanRecruit.cows = (actualSourcesDisplayed.cows + sourcesDisplayed.cows) > sourcesDisplayed.cows;
      if (sources.rice > 0) sourcesCanRecruit.rice = (actualSourcesDisplayed.rice + sourcesDisplayed.rice) > sourcesDisplayed.rice;
      if (sources.sheeps > 0) sourcesCanRecruit.sheeps = (actualSourcesDisplayed.sheeps + sourcesDisplayed.sheeps) > sourcesDisplayed.sheeps;
      if (sources.coal > 0) sourcesCanRecruit.coal = (actualSourcesDisplayed.coal + sourcesDisplayed.coal) > sourcesDisplayed.coal;
      if (sources.iron > 0) sourcesCanRecruit.iron = (actualSourcesDisplayed.iron + sourcesDisplayed.iron) > sourcesDisplayed.iron;
      if (sources.silver > 0) sourcesCanRecruit.silver = (actualSourcesDisplayed.silver + sourcesDisplayed.silver) > sourcesDisplayed.silver;
      if (sources.gold > 0) sourcesCanRecruit.gold = (actualSourcesDisplayed.gold + sourcesDisplayed.gold) > sourcesDisplayed.gold;

      if (sourcesCanRecruit.wood && sourcesCanRecruit.bricks && sourcesCanRecruit.stone && sourcesCanRecruit.grain)
      {
        sourcesCanRecruit.recruit = true;
      }
      if (sources.corn > 0 && !sourcesCanRecruit.corn) sourcesCanRecruit.recruit = false;
      if (sources.cows > 0 && !sourcesCanRecruit.cows) sourcesCanRecruit.recruit = false;
      if (sources.rice > 0 && !sourcesCanRecruit.rice) sourcesCanRecruit.recruit = false;
      if (sources.sheeps > 0 && !sourcesCanRecruit.sheeps) sourcesCanRecruit.recruit = false;
      if (sources.coal > 0 && !sourcesCanRecruit.coal) sourcesCanRecruit.recruit = false;
      if (sources.iron > 0 && !sourcesCanRecruit.iron) sourcesCanRecruit.recruit = false;
      if (sources.silver > 0 && !sourcesCanRecruit.silver) sourcesCanRecruit.recruit = false;
      if (sources.gold > 0 && !sourcesCanRecruit.gold) sourcesCanRecruit.recruit = false;

        var maxWood = actualSourcesDisplayed.wood / sources.wood;
        if (maxWood > 0)
          sourcesCanRecruit.max = Math.floor(maxWood);
        if (maxWood <= 0)
        {
          sourcesCanRecruit.max = 0;
          return sourcesCanRecruit;
        }

        var maxBricks = actualSourcesDisplayed.bricks / sources.bricks;
        if (maxBricks > 0 && maxBricks < maxWood)
          sourcesCanRecruit.max = Math.floor(maxBricks);
        if (maxBricks <= 0)
        {
          sourcesCanRecruit.max = 0;
          return sourcesCanRecruit;
        }

        var maxStone = actualSourcesDisplayed.stone / sources.stone;
        if (maxStone > 0 && (maxStone < maxWood && maxStone < maxBricks))
          sourcesCanRecruit.max = Math.floor(maxStone);
        if (maxStone <= 0)
        {
          sourcesCanRecruit.max = 0;
          return sourcesCanRecruit;
        }

        var maxGrain = actualSourcesDisplayed.grain / sources.grain;
        if (maxGrain > 0 && (maxGrain < maxWood && maxGrain < maxBricks && maxGrain < maxStone))
          sourcesCanRecruit.max = Math.floor(maxGrain);
        if (maxGrain <= 0)
        {
          sourcesCanRecruit.max = 0;
          return sourcesCanRecruit;
        }

        var maxCorn = 0
        if (sources.corn > 0)
        {
          maxCorn = actualSourcesDisplayed.corn / sources.corn;
          if (maxCorn > 0 && (maxCorn < maxWood && maxCorn < maxBricks && maxCorn < maxStone && maxCorn < maxGrain))
            sourcesCanRecruit.max = Math.floor(maxCorn);
          if (maxCorn <= 0)
          {
            sourcesCanRecruit.max = 0;
            return sourcesCanRecruit;
          }
        }

        var maxCows = 0
        if (sources.cows > 0)
        {
          maxCows = actualSourcesDisplayed.cows / sources.cows;
          if (maxCows > 0 && (maxCows < maxWood && maxCows < maxBricks && maxCows < maxStone && maxCows < maxGrain && (maxCorn == 0 || maxCows < maxCorn)))
            sourcesCanRecruit.max = Math.floor(maxCows);
          if (maxCows <= 0)
          {
            sourcesCanRecruit.max = 0;
            return sourcesCanRecruit;
          }
        }

        var maxRice = 0
        if (sources.rice > 0)
        {
          maxRice = actualSourcesDisplayed.rice / sources.rice;
          if (maxRice > 0 && (maxRice < maxWood && maxRice < maxBricks && maxRice < maxStone && maxRice < maxGrain && (maxCorn == 0 || maxRice < maxCorn) && (maxCows == 0 || maxRice < maxCows)))
            sourcesCanRecruit.max = Math.floor(maxRice);
          if (maxRice <= 0)
          {
            sourcesCanRecruit.max = 0;
            return sourcesCanRecruit;
          }
        }

        var maxSheeps = 0
        if (sources.sheeps > 0)
        {
          maxSheeps = actualSourcesDisplayed.sheeps / sources.sheeps;
          if (maxSheeps > 0 && (maxSheeps < maxWood && maxSheeps < maxBricks && maxSheeps < maxStone && maxSheeps < maxGrain && (maxCorn == 0 || maxSheeps < maxCorn) && (maxCows == 0 || maxSheeps < maxCows) && (maxRice == 0 || maxSheeps < maxRice)))
            sourcesCanRecruit.max = Math.floor(maxSheeps);
          if (maxSheeps <= 0)
          {
            sourcesCanRecruit.max = 0;
            return sourcesCanRecruit;
          }
        }

        var maxCoal = 0
        if (sources.coal > 0)
        {
          maxCoal = actualSourcesDisplayed.coal / sources.coal;
          if (maxCoal > 0 && (maxCoal < maxWood && maxCoal < maxBricks && maxCoal < maxStone && maxCoal < maxGrain && (maxCorn == 0 || maxCoal < maxCorn) && (maxCows == 0 || maxCoal < maxCows) && (maxRice == 0 || maxCoal < maxRice) && (maxSheeps == 0 || maxCoal < maxSheeps)))
            sourcesCanRecruit.max = Math.floor(maxCoal);
          if (maxCoal <= 0)
          {
            sourcesCanRecruit.max = 0;
            return sourcesCanRecruit;
          }
        }

        var maxIron = 0
        if (sources.iron > 0)
        {
          maxIron = actualSourcesDisplayed.iron / sources.iron;
          if (maxIron > 0 && (maxIron < maxWood && maxIron < maxBricks && maxIron < maxStone && maxIron < maxGrain && (maxCorn == 0 || maxIron < maxCorn) && (maxCows == 0 || maxIron < maxCows) && 
          (maxRice == 0 || maxIron < maxRice) && (maxSheeps == 0 || maxIron < maxSheeps) && (maxCoal == 0 || maxIron < maxCoal)))
            sourcesCanRecruit.max = Math.floor(maxIron);
          if (maxIron <= 0)
          {
            sourcesCanRecruit.max = 0;
            return sourcesCanRecruit;
          }
        }

        var maxSilver = 0
        if (sources.silver > 0)
        {
          maxSilver = actualSourcesDisplayed.silver / sources.silver;
          if (maxSilver > 0 && (maxSilver < maxWood && maxSilver < maxBricks && maxSilver < maxStone && maxSilver < maxGrain && (maxCorn == 0 || maxSilver < maxCorn) && (maxCows == 0 || maxSilver < maxCows) && 
          (maxRice == 0 || maxSilver < maxRice) && (maxSheeps == 0 || maxSilver < maxSheeps) && (maxCoal == 0 || maxSilver < maxCoal) && (maxIron == 0 || maxSilver < maxIron)))
            sourcesCanRecruit.max = Math.floor(maxSilver);
          if (maxSilver <= 0)
          {
            sourcesCanRecruit.max = 0;
            return sourcesCanRecruit;
          }
        }

        var maxGold = 0
        if (sources.gold > 0)
        {
          maxGold = actualSourcesDisplayed.gold / sources.gold;
          if (maxGold > 0 && (maxGold < maxWood && maxGold < maxBricks && maxGold < maxStone && maxGold < maxGrain && (maxCorn == 0 || maxGold < maxCorn) && (maxCows == 0 || maxGold < maxCows) && 
          (maxRice == 0 || maxGold < maxRice) && (maxSheeps == 0 || maxGold < maxSheeps) && (maxCoal == 0 || maxGold < maxCoal) && (maxIron == 0 || maxGold < maxIron) && (maxSilver == 0 || maxGold < maxSilver)))
            sourcesCanRecruit.max = Math.floor(maxGold);
          if (maxGold <= 0)
          {
            sourcesCanRecruit.max = 0;
            return sourcesCanRecruit;
          }
        }

        if (sourcesCanRecruit.max == undefined)
          sourcesCanRecruit.max = 0;

      return sourcesCanRecruit;
    }

    canTrainCaptain(actualSources: SourcesDemand, sources: SourcesDemand)
    {
      var sourcesCanRecruit = new SourcesCanRecruit();
      sourcesCanRecruit.wood = actualSources.wood > sources.wood;
      sourcesCanRecruit.bricks = actualSources.bricks > sources.bricks;
      sourcesCanRecruit.stone = actualSources.stone > sources.stone;
      sourcesCanRecruit.grain = actualSources.grain > sources.grain;
      if (sources.corn > 0) sourcesCanRecruit.corn = actualSources.corn > sources.corn;
      if (sources.cows > 0) sourcesCanRecruit.cows = actualSources.cows > sources.cows;
      if (sources.rice > 0) sourcesCanRecruit.rice = actualSources.rice > sources.rice;
      if (sources.sheeps > 0) sourcesCanRecruit.sheeps = actualSources.sheeps > sources.sheeps;
      if (sources.coal > 0) sourcesCanRecruit.coal = actualSources.coal > sources.coal;
      if (sources.iron > 0) sourcesCanRecruit.iron = actualSources.iron > sources.iron;
      if (sources.silver > 0) sourcesCanRecruit.silver = actualSources.silver > sources.silver;
      if (sources.gold > 0) sourcesCanRecruit.gold = actualSources.gold > sources.gold;

      if (sourcesCanRecruit.wood && sourcesCanRecruit.bricks && sourcesCanRecruit.stone && sourcesCanRecruit.grain)
      {
        sourcesCanRecruit.recruit = true;
      }
      if (sources.corn > 0 && !sourcesCanRecruit.corn) sourcesCanRecruit.recruit = false;
      if (sources.cows > 0 && !sourcesCanRecruit.cows) sourcesCanRecruit.recruit = false;
      if (sources.rice > 0 && !sourcesCanRecruit.rice) sourcesCanRecruit.recruit = false;
      if (sources.sheeps > 0 && !sourcesCanRecruit.sheeps) sourcesCanRecruit.recruit = false;
      if (sources.coal > 0 && !sourcesCanRecruit.coal) sourcesCanRecruit.recruit = false;
      if (sources.iron > 0 && !sourcesCanRecruit.iron) sourcesCanRecruit.recruit = false;
      if (sources.silver > 0 && !sourcesCanRecruit.silver) sourcesCanRecruit.recruit = false;
      if (sources.gold > 0 && !sourcesCanRecruit.gold) sourcesCanRecruit.recruit = false;
      return sourcesCanRecruit;
    }

    setSourceDemand()
    {
      var sourcesDemand = new SourcesDemand(); sourcesDemand.wood = 0; sourcesDemand.bricks = 0; sourcesDemand.stone = 0; sourcesDemand.grain = 0;
      sourcesDemand.corn = 0; sourcesDemand.cows = 0; sourcesDemand.rice = 0; sourcesDemand.sheeps = 0;
      sourcesDemand.coal = 0; sourcesDemand.iron = 0; sourcesDemand.silver = 0; sourcesDemand.gold = 0;
      return sourcesDemand;
    }

    addSources(sources: SourcesDemand, recruitmentType: RecruitmentType)
    {
      var sourcesDisplayed = this.setRecruitmentSources(recruitmentType.sources, recruitmentType.quantity)
      sources.wood += sourcesDisplayed.wood;
      sources.bricks += sourcesDisplayed.bricks;
      sources.stone += sourcesDisplayed.stone;
      sources.grain += sourcesDisplayed.grain;
      if (sources.corn > 0) sources.corn += sourcesDisplayed.corn;
      if (sources.cows > 0) sources.cows += sourcesDisplayed.cows;
      if (sources.rice > 0) sources.rice += sourcesDisplayed.rice;
      if (sources.sheeps > 0) sources.sheeps += sourcesDisplayed.sheeps;
      if (sources.coal > 0) sources.coal += sourcesDisplayed.coal;
      if (sources.iron > 0) sources.iron += sourcesDisplayed.iron;
      if (sources.silver > 0) sources.silver += sourcesDisplayed.silver;
      if (sources.gold > 0) sources.gold += sourcesDisplayed.gold;
      return sources;
    }

    removeSources(actualSources: SourcesDemand, sourcesDisplayed: SourcesDemand)
    {
      var sources = this.setSourceDemand();
      sources.wood = actualSources.wood - sourcesDisplayed.wood;
      sources.bricks = actualSources.bricks - sourcesDisplayed.bricks;
      sources.stone = actualSources.stone - sourcesDisplayed.stone;
      sources.grain = actualSources.grain - sourcesDisplayed.grain;
      if (actualSources.corn > 0) sources.corn = actualSources.corn - sourcesDisplayed.corn;
      if (actualSources.cows > 0) sources.cows = actualSources.cows - sourcesDisplayed.cows;
      if (actualSources.rice > 0) sources.rice = actualSources.rice - sourcesDisplayed.rice;
      if (actualSources.sheeps > 0) sources.sheeps = actualSources.sheeps - sourcesDisplayed.sheeps;
      if (actualSources.coal > 0) sources.coal = actualSources.coal - sourcesDisplayed.coal;
      if (actualSources.iron > 0) sources.iron = actualSources.iron - sourcesDisplayed.iron;
      if (actualSources.silver > 0) sources.silver = actualSources.silver - sourcesDisplayed.silver;
      if (actualSources.gold > 0) sources.gold = actualSources.gold - sourcesDisplayed.gold;
      return sources;
    }

    setRecruitmentSourcesDisplayed(sources: SourcesDemand, workersQuantity: number)
    {
      if (workersQuantity == 0) workersQuantity = 1;
      return this.setRecruitmentSources(sources, workersQuantity);
    }

    setRecruitmentSources(sources: SourcesDemand, workersQuantity: number)
    {
      var sourcesDisplayed = new SourcesDemand();
      sourcesDisplayed.wood = sources.wood * workersQuantity;
      sourcesDisplayed.bricks = sources.bricks * workersQuantity;
      sourcesDisplayed.stone = sources.stone * workersQuantity;
      sourcesDisplayed.grain = sources.grain * workersQuantity;
      if (sources.corn > 0) sourcesDisplayed.corn = sources.corn * workersQuantity;
      if (sources.cows > 0) sourcesDisplayed.cows = sources.cows * workersQuantity;
      if (sources.rice > 0) sourcesDisplayed.rice = sources.rice * workersQuantity;
      if (sources.sheeps > 0) sourcesDisplayed.sheeps = sources.sheeps * workersQuantity;
      if (sources.coal > 0) sourcesDisplayed.coal = sources.coal * workersQuantity;
      if (sources.iron > 0) sourcesDisplayed.iron = sources.iron * workersQuantity;
      if (sources.silver > 0) sourcesDisplayed.silver = sources.silver * workersQuantity;
      if (sources.gold > 0) sourcesDisplayed.gold = sources.gold * workersQuantity;
      return sourcesDisplayed;
    }
}