import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BuildingsSources } from '@app/_models/village/buildingsSources';
import { environment } from '@environments/environment';
import { SourcesHelper } from '@app/_helpers/sources.helper';
import { AddOffer } from '@app/_models/marketplace/addOffer';
import { AddOfferResponse } from '@app/_models/marketplace/addOfferResponse';
import { RemoveOffer } from '@app/_models/marketplace/removeOffer';
import { MarketplaceOffer } from '@app/_models/marketplace/marketplaceOffer';
import { AcceptOffer } from '@app/_models/marketplace/acceptOffer';
import { AcceptOfferResponse } from '@app/_models/marketplace/acceptOfferResponse';
import { ChangeSources } from '@app/_models/alchemist/changeSources';
import { ChangeSourcesResponse } from '@app/_models/alchemist/changeSourcesResponse';
//import { BehaviorSubject, Observable } from 'rxjs';
//import { map } from 'rxjs/operators';

//import { environment } from '@environments/environment';

@Injectable({ providedIn: 'root' })
export class BuildingService {
    buildingSources: BuildingsSources;
    constructor(
        private router: Router,
        private http: HttpClient,
        private sourcesHelper: SourcesHelper
    ) {
     
    }


    getBuildingProduction(buildingType: string, level: number) {               
        const params = new HttpParams().append('buildingType', buildingType).append('level', level.toString());
        return this.http.get<number>(`${environment.dataStorageUrl}/building/GetBuildingProduction`, { params})
    }

    getBuildingCapacity(buildingType: string, level: number) {                       
        const params = new HttpParams().append('buildingType', buildingType).append('level', level.toString());
        return this.http.get<number>(`${environment.dataStorageUrl}/building/GetBuildingCapacity`, { params})
    }

    addMarketplaceOffer(request: AddOffer) : Promise<AddOfferResponse>  {
        return new Promise(resolve=>{
          this.http.post<AddOfferResponse>(`${environment.dataStorageUrl}/building/addMarketplaceOffer`, request)
           .subscribe(
              (data:any) => {
                  this.sourcesHelper.updateMainbarDetailSources(data.mainbarDetail);
                  resolve(data);
           })
        })
      }

      acceptMarketplaceOffer(request: AcceptOffer) : Promise<AcceptOfferResponse>  {
        return new Promise(resolve=>{
          this.http.post<AcceptOfferResponse>(`${environment.dataStorageUrl}/building/acceptMarketplaceOffer`, request)
           .subscribe(
              (data:any) => {
                  this.sourcesHelper.updateMainbarDetailSources(data.mainbarDetail);
                  resolve(data);
           })
        })
      }

      addChangeSources(request: ChangeSources) : Promise<ChangeSourcesResponse>  {
        return new Promise(resolve=>{
          this.http.post<ChangeSourcesResponse>(`${environment.dataStorageUrl}/building/addChangeSources`, request)
           .subscribe(
              (data:any) => {
                  this.sourcesHelper.updateMainbarDetailSources(data.mainbarDetail);
                  resolve(data);
           })
        })
      }

      removeOffer(request: RemoveOffer) : Promise<AddOfferResponse>  {
        return new Promise(resolve=>{
          this.http.post<AddOfferResponse>(`${environment.dataStorageUrl}/building/removeMarketplaceOffer`, request)
           .subscribe(
              (data:any) => {
                  this.sourcesHelper.updateMainbarDetailSources(data.mainbarDetail);
                  resolve(data);
           })
        })
      }

      getMarketplaceOfferByCoords() {     
        const params = new HttpParams().append('x', '50').append('y', '50');  
        return this.http.get<AddOffer[]>(`${environment.dataStorageUrl}/building/GetMarketplaceOfferByCoords`, { params});
      }

      getMarketplaceOfferList() {     
        const params = new HttpParams().append('x', '50').append('y', '50');  
        return this.http.get<MarketplaceOffer[]>(`${environment.dataStorageUrl}/building/GetMarketplaceOfferList`, { params});
      }
}