import { Component, OnInit, Input, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { Coords, DetailCoords } from '@app/_models';
import { RecruitmentImages } from '@app/_models/images/recruitmentImages';
import { CollectSources } from '@app/_models/maps/collectSources';
import { SendWorkers } from '@app/_models/maps/sendWorkers';
import { AddSourcesModel } from '@app/_models/maps/addSourcesModel';
import { VillageDetail } from '@app/_models/village/villageDetail';
import { AlertService, MapService, ProfileService } from '@app/_services';
import { VillageService } from '@app/_services/village.service';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription, timer } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';
import { MapSchema } from '@app/_models/maps/mapSchema';
import { SourcesDetail } from '@app/_models/maps/sourcesDetail';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'modal-send-workers',
  templateUrl: './modal-send-workers.component.html',
  styleUrls: ['./modal-send-workers.component.css']
})
export class ModalSendWorkersComponent implements OnInit  {
  @ViewChild('inputNameRef') inputNameRef: ElementRef;
  @Input() target_coordx;
  @Input() target_coordy;
  closeResult = '';
  model: AddSourcesModel;
  villageDetail: VillageDetail;
  images: RecruitmentImages;
  subscription: Subscription;
  collectSources: CollectSources;
  mapSchema: MapSchema;
  collectTime: string;
  highlightSendWorkers: string = '';
  defaultWorkers: number;
  activeModal: any;

  constructor(public modal: NgbModal,
    private mapService: MapService,
    private villageService: VillageService,
    private alertService: AlertService,
    private profileService: ProfileService,
    private elementRef: ElementRef,
    private router: Router) {

    }

  ngOnInit() {
    this.villageService.getVillageDetails().subscribe(result => this.villageDetail = result);
    this.mapService.getMapSchema().subscribe(result => this.mapSchema = result);

    this.images = new RecruitmentImages();
    this.model = new AddSourcesModel();
    this.model.wood = new SourcesDetail();
    this.model.stone = new SourcesDetail();
    this.model.bricks = new SourcesDetail();
    this.model.grain = new SourcesDetail();

    this.profileService.getProfileDetails().subscribe(result => {
      if (result.tutorialDetail.inProgress == false && result.tutorialDetail.type == "MapZoom" && result.tutorialDetail.name == "SendWorkers")
      {
          this.highlightSendWorkers = 'highlight';
          const inputElement = this.elementRef.nativeElement.querySelector('[name="inputWood"]');
          inputElement.value = 10;
          this.model.wood.workers = 10;
      } else
      {
          this.highlightSendWorkers = '';
      }
    });

    this.subscription = timer(500, 1000).pipe(
      switchMap(() => this.mapService.checkCollectSourceTime(this.villageDetail, this.mapSchema, Math.floor(this.target_coordx*10)/10, Math.floor(this.target_coordy*10)/10))
    ).subscribe(result => this.collectSources = result);
  }

  public submit() {
    //this.modal.close(/* your result */);
  }

  open(content, options) {
		this.activeModal = this.modal.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then(
			(result) => {
				this.closeResult = `Closed with: ${result}`;
			},
			(reason) => {
				this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			},
		);
	}

  sendWorkers(sourceType: string, workers: number)
  {      
       var coords = new Coords();
       coords.x = this.villageDetail?.coords.x;
       coords.y = this.villageDetail?.coords.y;
       var detailCoords = new Coords();
       detailCoords.x = Math.floor(this.target_coordx*10)/10;
       detailCoords.y = Math.floor(this.target_coordy*10)/10;
       var sendWorkersRequest = new SendWorkers();
       sendWorkersRequest.id = '0';
       sendWorkersRequest.detailCoords = detailCoords;
       sendWorkersRequest.villageCoords = coords;
       sendWorkersRequest.sourceType = sourceType;
       sendWorkersRequest.workers = +workers;
       this.mapService.sendWorkers(sendWorkersRequest)
           .pipe(first())
           .subscribe({
               next: (result) => {
                   this.villageDetail = result.villageDetail;
                   this.modal.dismissAll();
                   this.alertService.success('Dodano robotników do zbierania surowców');
                   this.router.navigate(["/village/details", this.villageDetail?.coords.x, this.villageDetail?.coords.y]);
                   //this.loading = false;
               },
               error: error => {
                  this.alertService.error(error);
                  //  this.loading = false;
               }
           });
  }

  onValueChange(e, sourceType: string)
  {       
      if (sourceType == "wood")
      {
          this.model.wood.workers = <number>e.target.value;
      }
      if (sourceType == "bricks")
      {
          this.model.bricks.workers = <number>e.target.value;
      }
      if (sourceType == "stone")
      {
          this.model.stone.workers = <number>e.target.value;
      }
      if (sourceType == "grain")
      {
          this.model.grain.workers = <number>e.target.value;
      }
      this.collectTime = this.mapService.getCollectTime(<number>e.target.value);
  }

  dismiss()
  {
    this.modal.dismissAll();
  }

	private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return 'by pressing ESC';
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return 'by clicking on a backdrop';
		} else {
			return `with: ${reason}`;
		}
	}

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}