import { SourcesDemand } from "../village/sourcesDemand";
import { Time } from "../village/time";

export class TechnologyLevel {
    level: number;
    levelInBuild: number;
    sources: SourcesDemand;
    findingTime: Time;
    workers: number;
    isCompleted: boolean;
    levelToBuild: number;
}