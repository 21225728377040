import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

import { environment } from '@environments/environment';
import { AccountService } from '@app/_services';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private accountService: AccountService, 
                private router: Router) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add auth header with jwt if user is logged in and request is to the api url
        const user = this.accountService.userValue;
        const isLoggedIn = user && user.token;
        const isApiUrl = request.url.startsWith(environment.apiUrl);
        const isDataStorageUrl = request.url.startsWith(environment.dataStorageUrl);
        if (isLoggedIn && (isApiUrl || isDataStorageUrl)) {
            const isTokenExpired = this.isTokenExpired(user.token);

            if (isTokenExpired) {
                this.accountService.logout();
                this.router.navigate(['/account/login']);
                return throwError('Token has expired');
            }

            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${user.token}`
                }
            });
        }

        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
                if (error.status === 401) {
                    this.accountService.logout();
                    this.router.navigate(['/account/login']);
                }
                return throwError(error);
            })
        );

        //return next.handle(request);
    }

    private isTokenExpired(token: string): boolean {
        const expiry = (JSON.parse(atob(token.split('.')[1]))).exp;
        return (Math.floor((new Date).getTime() / 1000)) >= expiry;
    }
}