import { Component , Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({ templateUrl: 'owh.component.html', encapsulation: ViewEncapsulation.None })
export class OwhComponent implements OnInit {

    constructor() {

    }

    ngOnInit() {

    }
}