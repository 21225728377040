import { RecruitmentType } from "./recruitmentType";

export class RecruitmentDetail {
    workers: RecruitmentType;
    slingers: RecruitmentType;
    explorer: RecruitmentType;
    axemen: RecruitmentType;
    bludgeonWarrior: RecruitmentType;
    cataphract: RecruitmentType;
    lancer: RecruitmentType;
    scout: RecruitmentType;
    catapult: RecruitmentType;
    archer: RecruitmentType;
    crossbowman: RecruitmentType;
    pikemen: RecruitmentType;
    swordsman: RecruitmentType;
    knight: RecruitmentType;
    hussars: RecruitmentType;
    ballista: RecruitmentType;
    cannon: RecruitmentType;
    scythe: RecruitmentType;
    grenadier: RecruitmentType;
    dragons: RecruitmentType;
    wingedHussars: RecruitmentType;
    trebuchet: RecruitmentType;
    gun: RecruitmentType;
    marksman: RecruitmentType;
    musketeer: RecruitmentType;
    rajtar: RecruitmentType;
    balloons: RecruitmentType;
    mitrailleuse: RecruitmentType;
    baron: RecruitmentType;
    settler: RecruitmentType;
}