import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Coords, DetailCoords } from '@app/_models';
import { TechnologyImages } from '@app/_models/images/technologyImages';
import { BuildImprovements } from '@app/_models/maps/buildImprovements';
import { SendWorkers } from '@app/_models/maps/sendWorkers';
import { VillageDetail } from '@app/_models/village/villageDetail';
import { MapService } from '@app/_services';
import { VillageService } from '@app/_services/village.service';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription, timer } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';
import { MapSchema } from '@app/_models/maps/mapSchema';
import { CommandService } from '@app/_services/command.service';
import { MainbarDetail } from '@app/_models/village/mainbarDetail';
import { SendBuildersRequest } from '@app/_models/maps/sendBuildersRequest';

@Component({
  selector: 'modal-build',
  templateUrl: './modal-build.component.html',
  styleUrls: ['./modal-build.component.css']
})
export class ModalBuildComponent implements OnInit {

  @Input() target_coordx;
  @Input() target_coordy;
  closeResult = '';
  villageDetail: VillageDetail;
  images: TechnologyImages;
  subscription: Subscription;
  buildImprovements: BuildImprovements;
  mapSchema: MapSchema;
  mainbarDetail: MainbarDetail;
  collectTime: string;

  constructor(public modal: NgbModal,
    private route: ActivatedRoute,
    private router: Router,
    private mapService: MapService,
    private commandService: CommandService,
    private villageService: VillageService) {}

  ngOnInit() {
    this.villageService.getMainbarDetailsToPromise().then(result => this.mainbarDetail = result);
    this.villageService.getVillageDetails().subscribe(result => this.villageDetail = result);
    this.mapService.getBuildImprovements(this.target_coordx, this.target_coordy).subscribe(result => this.buildImprovements = result);
    this.mapService.getMapSchema().subscribe(result => this.mapSchema = result);

    this.images = new TechnologyImages();

    this.subscription = timer(500, 1000).pipe(
      switchMap(() => this.commandService.checkBuildImprovements(this.buildImprovements, this.mainbarDetail, this.villageDetail, this.mapSchema))
    ).subscribe(result => this.buildImprovements = result);
  }

  public submit() {
    //this.modal.close(/* your result */);
  }

  open(content) {
		this.modal.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then(
			(result) => {
				this.closeResult = `Closed with: ${result}`;
			},
			(reason) => {
				this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			},
		);
	}

  buildImprovement(improvementName: string, workers: number)
  {      
       var coords = new Coords();
       coords.x = this.villageDetail?.coords.x;
       coords.y = this.villageDetail?.coords.y;
       var detailCoords = new Coords();
       detailCoords.x = Math.floor(this.target_coordx*10)/10;
       detailCoords.y = Math.floor(this.target_coordy*10)/10;
       var sendBuildersRequest = new SendBuildersRequest();
       sendBuildersRequest.detailCoords = detailCoords;
       sendBuildersRequest.improvementType = improvementName;
       sendBuildersRequest.workers = workers;

       this.mapService.sendBuilders(sendBuildersRequest)
           .pipe(first())
           .subscribe({
               next: (result) => {
                   this.villageDetail = result.villageDetail
                  //  this.alertService.success('Dodano robotników do zbierania surowców');
                  //  this.loading = false;
               },
               error: error => {
                  //  this.alertService.error(error);
                  //  this.loading = false;
               }
           });
  }

  onValueChange(e, sourceType: string)
  {       

  }

  dismiss()
  {
    this.modal.dismissAll();
  }

	private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return 'by pressing ESC';
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return 'by clicking on a backdrop';
		} else {
			return `with: ${reason}`;
		}
	}

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}