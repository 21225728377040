import { Coords } from "../maps/coords";
import { SourcesDemand } from "../village/sourcesDemand";
import { DetailCoords } from "./detailCoords";

export class SendWorkers {
    id: string;
    detailCoords: Coords;
    villageCoords: Coords;
    sourceType: string; 
    workers: number; 
}