import { Injectable } from '@angular/core';
import { RecruitmentImages } from '@app/_models/images/recruitmentImages';
import { BuildingImages } from '@app/_models/images/buildingImages';
import { MapImages } from '@app/_models/images/mapImages';



@Injectable({ providedIn: 'root' })
export class ImagesHelper {
    constructor(
        
    ) {}

    getBuildingImages()
    {
        var images = new BuildingImages();
        images.workersImageUrl = "./assets/village/VillageForest.png";
        images.castleImageUrl = "./assets/village/VillageCastle.png";
        images.wallSector1ImageUrl = "./assets/village/VillageWall.png";
        images.wallSector2ImageUrl = "./assets/village/VillageWall.png";
        images.placeImageUrl = "./assets/village/VillagePlace.png";
        images.marketplaceImageUrl = "./assets/village/VillageMarketplace.png";
        images.forestImageUrl = "./assets/village/VillageForest.png";
        images.brickYardImageUrl = "./assets/village/VillageBrickYard.png";
        images.quarryImageUrl = "./assets/village/VillageQuarry.png";
        images.fieldImageUrl = "./assets/village/VillageField.png";
        images.storageImageUrl = "./assets/village/VillageStorage.png";
        images.granaryImageUrl = "./assets/village/VillageGranary.png";
        images.heavyStorageImageUrl = "./assets/village/VillageHeavyStorage.png";
        images.barracksImageUrl = "./assets/village/VillageBarracks.png";
        images.archeryTowerImageUrl = "./assets/village/VillageArcheryTower.png";
        images.stableImageUrl = "./assets/village/VillageStable.png";
        images.catapultTowerImageUrl = "./assets/village/VillageCatapultTower.png";
        images.housesImageUrl = "./assets/village/VillageHouses.png";
        images.soldiersHouseImageUrl = "./assets/village/VillageSoldiersHouse.png";
        images.workshopImageUrl = "./assets/village/VillageWorkshop.png";
        images.alchemistImageUrl = "./assets/village/VillageAlchemist.png";
        return images;
    }

    getRecruitmentImages()
    {
        var images = new RecruitmentImages();
        images.barracksImageUrl = "./assets/village/VillageBarracks.png";
        images.archeryTowerImageUrl = "./assets/village/VillageArcheryTower.png";
        images.stableImageUrl = "./assets/village/VillageStable.png";
        images.catapultTowerImageUrl = "./assets/village/VillageCatapultTower.png";
        images.timeImageUrl = "./assets/village/VillageForest.png";
        images.woodImageUrl = "./assets/sources/Wood.png";
        images.brickImageUrl = "./assets/sources/Bricks.png";
        images.stoneImageUrl = "./assets/sources/Stone.png";
        images.grainImageUrl = "./assets/sources/Wheat.png";
        images.cornImageUrl = "./assets/sources/Corn.png";
        images.cowsImageUrl = "./assets/sources/Cows.png";
        images.coalImageUrl = "./assets/sources/Coal.png";
        images.workersImageUrl = "./assets/village/VillageForest.png";
        images.explorerImageUrl = "./assets/village/VillageCastle.png";
        images.slingersImageUrl = "./assets/village/VillageWall.png";
        images.axemenImageUrl = "./assets/village/VillageWall.png";
        images.bludgeonWarriorImageUrl = "./assets/village/VillagePlace.png";
        images.cataphractImageUrl = "./assets/village/VillageMarketplace.png";
        images.lancerImageUrl = "./assets/village/VillageForest.png";
        images.scoutImageUrl = "./assets/village/VillageBrickYard.png";
        images.catapultImageUrl = "./assets/village/VillageQuarry.png";
        images.archerImageUrl = "./assets/village/VillageField.png";
        images.crossbowmanImageUrl = "./assets/village/VillageStorage.png";
        images.pikemenImageUrl = "./assets/village/VillageGranary.png";
        images.swordsmanImageUrl = "./assets/village/VillageGranary.png";
        images.knightImageUrl = "./assets/village/VillageBarracks.png";
        images.hussarsImageUrl = "./assets/village/VillageArcheryTower.png";
        images.ballistaImageUrl = "./assets/village/VillageStable.png";
        images.cannonImageUrl = "./assets/village/VillageCatapultTower.png";
        images.scytheImageUrl = "./assets/village/VillageHouses.png";
        images.grenadierImageUrl = "./assets/village/VillageSoldiersHouse.png";
        images.dragonsImageUrl = "./assets/village/VillageWorkshop.png";
        images.wingedHussarsImageUrl = "./assets/village/VillageAlchemist.png";
        images.trebuchetImageUrl = "./assets/village/VillageAlchemist.png";
        images.gunImageUrl = "./assets/village/VillageAlchemist.png";
        images.marksmanImageUrl = "./assets/village/VillageAlchemist.png";
        images.musketeerImageUrl = "./assets/village/VillageAlchemist.png";
        images.rajtarImageUrl = "./assets/village/VillageAlchemist.png";
        images.balloonsImageUrl = "./assets/village/VillageAlchemist.png";
        images.mitrailleuseImageUrl = "./assets/village/VillageAlchemist.png";
        images.baronImageUrl = "./assets/village/VillageAlchemist.png";
        images.settlerImageUrl = "./assets/village/VillageAlchemist.png";
        images.placeImageUrl = "./assets/village/VillagePlace.png";
        return images;
    }

    getMapImages()
    {
        var images = new MapImages();
        images.sendWorkersCollectImageUrl = "./assets/village/VillageForest.png";
        images.sendWorkersBackCollectImageUrl = "./assets/village/VillageCastle.png";
        images.workersCollectImageUrl = "./assets/village/VillageWall.png";
        return images;
    }
    
}